import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
// @mui
import { Stack, Button, Skeleton, Grid } from '@mui/material';
import { CityReqTable } from '../sections/@dashboard/interfaces';
import SearchComponet from '../components/search/Search';
import ModalComponent from '../components/modal/modal';
import InterfaceForm from '../sections/@dashboard/interfaces/InterfaceForm';
import { getInterfacesList, deleteInterface } from '../network/apis';

export default function Cities() {
  const { t, i18n } = useTranslation();

  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [clientReqList, setClientReqList] = useState([]);
  const [search, setSearch] = useState('');

  const clientRequests = (page, search) => {
    setLoading(true);
    getInterfacesList(page, search)
      .then((res) => {
        setClientReqList(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    clientRequests(page, search);
  }, [page, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = (id) => {
    deleteInterface(id)
      .then((res) => {
        if (res.data.STATUS === 'FAILED' || res.data.STATUS === '0') {
          toast.error(res.data.MESSAGE);
        } else toast.success(res.data.MESSAGE);
        // Reload the cities list
        clientRequests(page, search);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.MESSAGE);
      });
  };
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    clientRequests(page, search);
  };
  return (
    <>
      <Helmet>
        <title> {t('interface.interface')} </title>
      </Helmet>
      <ModalComponent
        style={{ position: 'relative' }}
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        title={t('interface.addInterface')}
        content=""
      >
        <InterfaceForm handleClose={handleCloseModal} />
      </ModalComponent>
      <>
        <Grid container spacing={2} alignItems="center">
          <Grid justifyContent="start" display="flex" item xs={2} sm={4} md={6}>
            <SearchComponet onSearch={setSearch} />
          </Grid>
          <Grid justifyContent="end" display="flex" item xs={2} sm={4} md={6}>
            <Button
              onClick={handleOpenModal}
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '10px',
                padding: '21px 24px',
                width: '164px',
                height: '56px',
                marginBottom: '15px',
              }}
            >
              {' '}
              + {t('interface.addInterface')}
            </Button>
          </Grid>
        </Grid>
        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <>
              <CityReqTable
                handleChangePage={handleChangePage}
                page={page}
                requests={clientReqList}
                onDelete={handleDelete}
              />
            </>
          </>
        )}
      </>
    </>
  );
}
