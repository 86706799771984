import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

// @mui
import { Stack, IconButton, InputAdornment, TextField, Typography, Box, Checkbox } from '@mui/material';

import { LoadingButton } from '@mui/lab';
// components
import ForgetPasswordDialog from './forgetPasswordDialog';
import Iconify from '../../../components/iconify';
import { axiosInstance } from '../../../network/config';
import { setLoggedIn, setSideMenu } from '../../../Store/features/Login/loginSlice';

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [isRemeber, setIsRemeber] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleForgetPassword = () => {
    setOpen(true);
  };

  const validationSchema = yup.object({
    email: yup.string().email(t('Login.wrongEmail')).required(t('Email is required')),
    password: yup.string().required(t('Login.enterPassword')),
  });

  const isRtl = i18n.language === 'ar';

  useEffect(() => {
    document.body.dir = isRtl ? 'rtl' : 'ltr';
  }, [isRtl]);

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setLoading(true);
        axiosInstance
          .post('/authenticate/superAdminLogin', { ...values })
          .then((res) => {
            const { token, sideMenu, user } = res.data;

            if (token && sideMenu) {
              if (isRemeber) {
                localStorage.setItem('userToken', JSON.stringify(token));
                localStorage.setItem('userId', JSON.stringify(user.userId));
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('isManager', JSON.stringify(user.isManager || ''));
                localStorage.setItem('dashboardSideMenu', JSON.stringify(sideMenu));
              } else {
                sessionStorage.setItem('userToken', JSON.stringify(token));
                sessionStorage.setItem('userId', JSON.stringify(user.userId));
                sessionStorage.setItem('user', JSON.stringify(user));
                sessionStorage.setItem('isManager', JSON.stringify(user.isManager || ''));
                sessionStorage.setItem('dashboardSideMenu', JSON.stringify(sideMenu));
              }

              dispatch(setLoggedIn(true));
              dispatch(setSideMenu(sideMenu));
              setLoading(false);

              if (localStorage.getItem('referrer_link')) {
                window.location.href = localStorage.getItem('referrer_link');
              } else {
                navigate('/dashboard', { replace: true });
              }
            } else {
              throw new Error('Invalid response data');
            }
          })
          .catch((err) => {
            console.error('Login error', err);
            setLoading(false);
            toast.error(err?.response?.data?.MESSAGE);
          });
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit} style={{ textAlign: isRtl ? 'right' : 'left' }}>
          <Stack sx={{ zIndex: 2 }} spacing={3} mb={4}>
            <Typography sx={{ lineHeight: '0', margin: '0' }} variant="body1" gutterBottom>
              {t('Login.email')}
            </Typography>
            <TextField
              name="email"
              required
              placeholder="Enter your email"
              value={values.email}
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              InputProps={{ dir: isRtl ? 'rtl' : 'ltr' }}
              inputProps={{ dir: isRtl ? 'rtl' : 'ltr' }}
              FormHelperTextProps={{ style: { textAlign: isRtl ? 'right' : 'left' } }}
            />
            <Typography sx={{ lineHeight: '0', margin: '0' }} variant="body1" gutterBottom>
              {t('Login.password')}
            </Typography>
            <TextField
              name="password"
              variant="outlined"
              placeholder={t('Login.enterPassword')}
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
                dir: isRtl ? 'rtl' : 'ltr',
              }}
              inputProps={{ dir: isRtl ? 'rtl' : 'ltr' }}
              FormHelperTextProps={{ style: { textAlign: isRtl ? 'right' : 'left' } }}
            />
          </Stack>

          <LoadingButton
            loading={loading}
            fullWidth
            size="large"
            type="submit"
            sx={{
              color: 'white',
              backgroundColor: 'black',
              '&:hover': {
                backgroundColor: 'black',
              },
            }}
            disabled={!values.email || !values.password}
            variant="contained"
          >
            {t('Login.login')}
          </LoadingButton>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Box display={'flex'} alignItems={'center'}>
              <Checkbox
                sx={{ color: 'black', padding: '0', margin: '0' }}
                checked={isRemeber}
                onChange={() => setIsRemeber(!isRemeber)}
              />
              <Typography variant="body2"> {t('Login.remeberMe')}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Link
                style={{
                  marginTop: '1rem',
                  marginBottom: '1rem',
                  color: 'blue',
                  textDecoration: 'unset',
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: '22.4px',
                }}
                component="button"
                variant="body2"
                onClick={handleForgetPassword}
              >
                {t('Login.forgetPassword')}
              </Link>

              <ForgetPasswordDialog open={open} setOpen={setOpen} />
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
}
