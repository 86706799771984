import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
// @mui
import { Stack, Button, Typography, Skeleton, Grid, Select, MenuItem, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CityReqTable } from '../sections/@dashboard/plansList';
import Layout from '../components/Layout/Layout';
import SearchComponet from '../components/search/Search';
import { hasPermission } from '../utils/permissions';
import { getPlansList, deleteRegion } from '../network/apis';
import { ReactComponent as DropdownIcon } from '../theme/images/deopdownIcon.svg';
import { resetFormData } from '../Store/features/Plans/plansSlice';

export default function Plans() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [clientReqList, setClientReqList] = useState([]);
  const [search, setSearch] = useState('');
  const [status, setChangeStatus] = useState('');
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';

  const handleChange = (e) => {
    setChangeStatus(e.target.value);
  };

  const clientRequests = (page, search, status) => {
    setLoading(true);
    getPlansList(page, search, status)
      .then((res) => {
        setClientReqList(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    clientRequests(page, search, status);
  }, [page, search, status]);
  useEffect(() => {
    dispatch(resetFormData());
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleDelete = (id) => {
    deleteRegion(id)
      .then((res) => {
        if (res.data.STATUS === 'FAILED' || res.data.STATUS === '0') {
          toast.error(res.data.MESSAGE);
        } else toast.success(res.data.MESSAGE);
        // Reload the cities list
        clientRequests(page, search);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.MESSAGE);
      });
  };

  const handleOpenModal = () => {
    navigate('/dashboard/plans/add');
  };

  const sideMenu = useSelector((state) => state.login?.sideMenu || []);
  const hasSearchPermission = hasPermission(sideMenu, '/dashboard/plans', 'Search For Plan');
  const hasAddPermission = hasPermission(sideMenu, '/dashboard/plans', 'Add Plan');
  const hasEditPermission = hasPermission(sideMenu, '/dashboard/plans', 'EditPlan');

  return (
    <>
      <Helmet>
        <title> {t('PLANS.plans')} </title>
      </Helmet>

      <Layout>
        <Grid display="flex" alignItems={'center'} marginBottom={1} justifyContent="space-between" md={6} xs={12}>
          <Typography sx={{ fontSize: '24px', fontWeight: '700', lineHeight: '32px', color: '#111827' }}>
            {t('PLANS.plans')}
          </Typography>
          {hasAddPermission && (
            <Button
              onClick={handleOpenModal}
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '10px',
                padding: '21px 24px',
                width: '164px',
                height: '56px',
              }}
            >
              <Typography sx={{ display: 'flex', gap: 1 }}>
                <span> + </span> <span>{t('PLANS.addPlan')}</span>
              </Typography>
            </Button>
          )}
        </Grid>
        <Grid mb={2} rowSpacing={1} container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {hasSearchPermission && (
            <Grid item xs={2} sm={4} md={4}>
              <SearchComponet onSearch={setSearch} style={{ width: '100%' }} />
            </Grid>
          )}
          <Grid item xs={2} sm={4} md={4}>
            <Grid>
              <FormControl fullWidth>
                <Select
                  displayEmpty
                  value={status}
                  sx={{
                    paddingLeft: isRtl ? '10px' : '0px',
                    paddingRight: isRtl ? '0px' : '10px',

                    width: '100%',
                    borderRadius: '10px',
                  }}
                  renderValue={(selected) => {
                    if (selected === '') {
                      return <span>{t('General.AllStatus')}</span>;
                    }
                    if (selected === 'TRUE') return <span>{t('General.Active')}</span>;
                    if (selected === 'FALSE') return <span>{t('General.InActive')}</span>;
                    return <span style={{ color: '#9e9e9e' }}> {t('General.select')} </span>;
                  }}
                  IconComponent={() => <DropdownIcon style={{ width: '15px', height: '15px' }} />}
                  labelId="Status"
                  id="status"
                  onChange={handleChange}
                >
                  <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="">
                    {t('General.AllStatus')}
                  </MenuItem>
                  <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="TRUE">
                    {t('General.Active')}
                  </MenuItem>
                  <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="FALSE">
                    {t('General.InActive')}{' '}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <>
              <CityReqTable
                handleChangePage={handleChangePage}
                page={page}
                requests={clientReqList}
                onDelete={handleDelete}
                hasEditPermission={hasEditPermission}
              />
            </>
          </>
        )}
      </Layout>
    </>
  );
}
