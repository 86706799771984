/* eslint-disable react/prop-types */
import React, { useState } from "react";

// localiztion

import { useTranslation } from "react-i18next";

// @mui
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableRow,
  TableBody,
  Button,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Pagination,
} from "@mui/material";
// icons imports

// import { ReactComponent as EditIcon } from "../../theme/images/editTableIcon.svg";
import { ReactComponent as ShowIcon } from "../../../theme/images/showTableIcon.svg";

// components
import Scrollbar from "../../../components/scrollbar/Scrollbar";

// sections
import ClientHeadTable from "../../../components/clientHeadTable/ClientHeadTable";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function RealStateTable({ requests, handleChangePage, page }) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  const TABLE_HEAD = [
    {
      id: "requestId",
      label: t("realStateRequests.requestId"),
      alignRight: isRtl && true,
    },
    {
      id: "fullName",
      label: t("realStateRequests.full_name"),
      alignRight: isRtl && true,
    },
    {
      id: "phone",
      label: t("realStateRequests.phone"),
      alignRight: isRtl && true,
    },
    {
      id: "nationalId",
      label: t("realStateRequests.nationalId"),
      alignRight: isRtl && true,
    },

    {
      id: "request_date",
      label: t("realStateRequests.request_date"),
      alignRight: isRtl && true,
    },
    {
      id: "action_date",
      label: t("realStateRequests.action_date"),
      alignRight: isRtl && true,
    },
    {
      id: "status",
      label: t("realStateRequests.status"),
      alignRight: isRtl && true,
    },

    { id: "action", label: t("General.Action"), alignRight: isRtl && true },
  ];
  const [rowsPerPage] = useState(1);
  const navigate = useNavigate();
  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ClientHeadTable headLabel={TABLE_HEAD} />
            <TableBody>
              {requests.length === 0 && (
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2" noWrap>
                    No Data
                  </Typography>
                </TableCell>
              )}
              {requests?.content?.map((row) => {
                const {
                  id,
                  requestNumber,
                  user,
                  creationDate,
                  actionDate,
                  status,
                } = row;
                const displayActionDate = actionDate
                  ? new Date(actionDate).toLocaleDateString()
                  : "-------------";
                const displayCreationDate = creationDate
                  ? new Date(creationDate).toLocaleDateString()
                  : "------------";

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {requestNumber || "----------"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          maxWidth: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {" "}
                        {user?.fullName || "----------"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          maxWidth: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {" "}
                        {user?.phone || "--------"}
                      </Typography>
                    </TableCell>{" "}
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          maxWidth: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {" "}
                        {user?.nationalId || "---------"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {" "}
                        {displayCreationDate}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {displayActionDate}
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                    >
                      {status === "COMPLETED" ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "4px 16px",
                            backgroundColor: "#E7F7EF",
                            borderRadius: "8px",
                            width: "88px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#0CAF60",
                              fontWeight: "700",
                              fontSize: "10px",
                              lineHeight: "16px",
                            }}
                            variant="subtitle2"
                            textAlign={"center"}
                            noWrap
                          >
                            {t("realStateRequests.completed")}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "4px 16px",
                            backgroundColor: "#FFEDEC",
                            borderRadius: "8px",
                            width: "88px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#E03137",
                              fontWeight: "700",
                              fontSize: "10px",
                              lineHeight: "16px",
                            }}
                            variant="subtitle2"
                            textAlign={"center"}
                            noWrap
                          >
                            {t("realStateRequests.pending")}
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? "right" : "left" }}>
                      <Button
                        onClick={() => {
                          navigate(`/dashboard/real-state-requests/${id}`);
                        }}
                      >
                        <ShowIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {requests?.totalElements === 0 && (
        <Box textAlign="center">
          <h5>No data found</h5>
        </Box>
      )}
      {requests?.totalPages > 0 && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            marginTop: "5rem",
          }}
        >
          <Pagination
            count={requests.totalPages}
            page={page + 1}
            onChange={handleChangePage}
            color="primary"
          />
          <Box sx={{ display: "block" }}>
            {t("General.showing")} {page * rowsPerPage + 1} {t("General.of")}{" "}
            {Math.min((page + 1) * rowsPerPage, requests.totalElements)}{" "}
            {t("General.to")} {requests.totalElements} {t("General.entries")}
          </Box>
        </Box>
      )}
    </>
  );
}
