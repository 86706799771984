import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import Layout from '../../../components/Layout/Layout';
import { addBuilding, findAllProjectsList, editBuilding } from '../../../network/apis';

export default function AdminForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const location = useLocation();
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const editData = location.state ? location.state.editData : null;
  const updateProject = location.state ? location.state.updateProject : null;

  const currentPath = location.pathname;
  const [viewMode, setIsViewMode] = useState(false);

  useEffect(() => {
    if (currentPath.includes('view')) {
      setIsViewMode(true);
    }
  }, [currentPath]);

  useEffect(() => {
    findAllProjectsList()
      .then((res) => {
        if (res && res?.data?.length > 0) {
          setProjects(res.data);
        } else {
          setProjects([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching projects:', error);
        setProjects([]);
      });
  }, []);

  const validationSchema = yup.object({
    nameEn: yup.string().required(t('Validation.englishReq')),
    nameAr: yup.string().max(30, t('Validation.max30Char')),
    // buildingCode: yup.string().required(t('Validation.requiredField')),
    levelCount: yup
      .number()
      .typeError(t('Validation.wrongNumberFormat'))
      .required(t('Validation.requiredField'))
      .min(0, 'Must be more than 0')
      .positive('Must be a positive number')
      .integer('Must be an integer'),
    unitCount: yup
      .number()
      .typeError(t('Validation.wrongNumberFormat'))
      .required(t('Validation.requiredField'))
      .min(0, 'Must be more than 0')
      .positive('Must be a positive number')
      .integer('Must be an integer'),
    projectId: yup.string().required(t('Validation.requiredField')),
  });

  const handleReset = (resetForm) => {
    resetForm({
      values: {
        nameEn: '',
        nameAr: '',
        buildingCode: '',
        unitCount: '',
        levelCount: '',
        projectId: '',
      },
    });
    navigate(`/dashboard/buildings`);
  };

  const CustomArrowIcon = ({ isRtl }) => (
    <SvgIcon style={{ transform: 'rotate(-360deg)', color: '#637381' }}>
      <path d="M7 10l5 5 5-5z" />
    </SvgIcon>
  );
  const handleSubmit = async (values) => {
    setLoading(true);
    let res;

    try {
      if (id) {
        res = await editBuilding({ id, ...values });
      } else if (updateProject?.from === 'manage-buildings-with-project-id-add') {
        res = await addBuilding({ ...values, projectId: updateProject?.projectId });
      } else {
        res = await addBuilding(values);
      }
      if (res?.data?.error) {
        toast.error(res?.data?.error);
      } else if (res.data.STATUS === 'FAILED' || res.data.STATUS === '0') {
        toast.error(res?.data?.MESSAGE);
      } else {
        toast.success(res?.data?.MESSAGE);
        if (updateProject?.from === 'manage-buildings-with-project-id-add') {
          navigate(`/dashboard/project/edit/${updateProject?.projectId}`, {
            state: { from: 'add-building-with-project', projectId: updateProject?.projectId },
          });
        } else {
          navigate(`/dashboard/buildings`);
        }
      }
    } catch (error) {
      const errorMessages = {
        500: 'Server Error',
        400: 'Bad Request',
        401: 'Unauthorized',
        403: 'Forbidden',
        404: 'Not Found',
      };

      if (axios.isAxiosError(error)) {
        const statusCode = error.response?.status;
        const errorMessage = errorMessages[statusCode] || 'An error occurred';
        toast.error(errorMessage);
      } else {
        toast.error('An error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      {!viewMode && <h3>{editData ? t('Building.edit') : t('Building.newBuilding')}</h3>}
      {viewMode && <h3>View Building</h3>}

      <Formik
        initialValues={{
          nameEn: editData ? editData?.nameEn : '',
          nameAr: editData ? editData?.nameAr : '',
          buildingCode: editData ? editData?.buildingCode : '',
          unitCount: editData ? editData?.unitCount : '',
          levelCount: editData ? editData?.levelCount : '',
          projectId: editData?.projectId ?? updateProject?.projectId ?? '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              sx={{ direction: isRtl ? 'rtl' : 'ltr' }}
              rowSpacing={2}
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Building.buildingNameEn')}
                </Typography>
                <TextField
                  name="nameEn"
                  disabled={viewMode}
                  required
                  fullWidth
                  value={values.nameEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nameEn && Boolean(errors.nameEn)}
                  helperText={touched.nameEn && errors.nameEn}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Building.buildingNameAr')}
                </Typography>
                <TextField
                  name="nameAr"
                  required
                  disabled={viewMode}
                  fullWidth
                  value={values.nameAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nameAr && Boolean(errors.nameAr)}
                  helperText={touched.nameAr && errors.nameAr}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Building.selectProject')}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    id="projectId"
                    name="projectId"
                    value={values.projectId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    displayEmpty
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                    error={touched.projectId && Boolean(errors.projectId)}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span style={{ color: '#aaa' }}> {t('ManageProject.project')}</span>;
                      }
                      const selectedProject = projects.find((project) => project.id.toString() === selected.toString());
                      return isRtl ? selectedProject?.nameAr : selectedProject?.nameEn;
                    }}
                    sx={{
                      '& .MuiSelect-select': {
                        color: values.projectId ? 'inherit' : '#aaa',
                      },
                    }}
                  >
                    {projects && projects.length > 0 ? (
                      projects.map((project) => (
                        <MenuItem
                          sx={{ direction: isRtl ? 'rtl' : 'ltr' }}
                          key={project.id}
                          value={project.id.toString()}
                        >
                          {isRtl ? project.nameAr : project.nameEn}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>{t('ManageProject.noProjects')}</MenuItem>
                    )}
                  </Select>
                  {touched.projectId && errors.projectId && <FormHelperText error>{errors.projectId}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Building.unitCount')}
                </Typography>
                <TextField
                  name="unitCount"
                  required
                  fullWidth
                  value={values.unitCount}
                  disabled={viewMode}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.unitCount && Boolean(errors.unitCount)}
                  helperText={touched.unitCount && errors.unitCount}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Building.buildingCode')}
                </Typography>
                <TextField
                  name="buildingCode"
                  fullWidth
                  disabled={viewMode}
                  value={values.buildingCode}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.buildingCode && Boolean(errors.buildingCode)}
                  helperText={touched.buildingCode && errors.buildingCode}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Building.levelCount')}
                </Typography>
                <TextField
                  name="levelCount"
                  required
                  fullWidth
                  disabled={viewMode}
                  value={values.levelCount}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.levelCount && Boolean(errors.levelCount)}
                  helperText={touched.levelCount && errors.levelCount}
                />
              </Grid>
              <Stack
                sx={{ width: '100%', mt: 4 }}
                gap={2}
                direction="row"
                justifyContent="end"
                alignItems="end"
                spacing={2}
              >
                <Button
                  sx={{ mt: 4, border: '1px solid #111827' }}
                  size="large"
                  onClick={() => handleReset(resetForm)}
                  type="button"
                  variant=""
                >
                  {t('Units.cancel')}
                </Button>
                {viewMode && (
                  <LoadingButton
                    sx={{ mt: 4 }}
                    onClick={() => navigate(`/dashboard/buildings`)}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t('Units.ok')}
                  </LoadingButton>
                )}
                {!viewMode && (
                  <LoadingButton sx={{ mt: 4 }} loading={loading} size="large" type="submit" variant="contained">
                    {t('Units.save')}
                  </LoadingButton>
                )}
              </Stack>
            </Grid>
          </form>
        )}
      </Formik>
    </Layout>
  );
}
