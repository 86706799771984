import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Stack, TextField, Typography, Chip, Box } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from '../../../components/loading-overlay/LoadingOverlay';
import Layout from '../../../components/Layout/Layout';
import UploadFileOnChange from '../../../components/UploadFileOnChange.jsx/UploadFileOnChange';
import ControlledCheckbox from '../../../components/check-box/useCheckBox';
import { updateAdminProfile, getUserDetails } from '../../../network/apis';
import ResetPasswordModal from './ResetPasswordModal';
import PreviewFile from '../../../components/previewFile/PreviewFile';

export default function ProfileForm() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const isRtl = i18n.language === 'ar';
  const [loading, setLoading] = useState(false);

  const userId = JSON.parse(localStorage.getItem('userId') || sessionStorage.getItem('userId'));

  const [userDetails, setUserDetails] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [uploadedFiles, setUploadedFiles] = React.useState({
    profileImage: [],
  });

  const [initialValues, setInitialValues] = useState({
    job: '',
    fullNameEn: '',
    fullNameAr: '',
    firstNameEn: '',
    firstNameAr: '',
    lastNameEn: '',
    lastNameAr: '',
    nationalId: '',
    birthDate: '',
    phone: '',
    email: '',
    status: '',
    managerId: '',
    isManager: '',
  });
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  useEffect(() => {
    if (userId) {
      getUserDetails(userId).then((res) => {
        const user = res.data.DATA;

        setInitialValues({
          job: isRtl ? user?.job?.nameAr : user?.job?.nameEn,
          fullNameEn: user.fullNameEn,
          fullNameAr: user.fullNameAr,
          firstNameEn: user.firstNameEn,
          firstNameAr: user.firstNameAr,
          lastNameEn: user.lastNameEn,
          lastNameAr: user.lastNameAr,
          nationalId: user.nationalId,
          birthDate: user.birthDate,
          phone: user.phone,
          email: user.email,

          status: user.status === '0' ? t('General.InActive') : t('General.Active'),
          managerId: user.managerId,
          managerName: user.managerName,
          profilePhoto: user.profilePicture,
          isManager: user.isManager === '1',
        });
        setUserDetails(user);
        setProfilePicture(user.profilePicture);
      });
    }
  }, [userId, t]);
  // Add Item Function
  const addItem = (fieldName, items) => {
    if (!uploadedFiles[fieldName]) {
      uploadedFiles[fieldName] = [];
    }
    items.forEach((item) => {
      if (!uploadedFiles[fieldName].includes(item)) {
        uploadedFiles[fieldName].push(item);
      }
    });
  };

  // Delete Item Function
  const deleteItem = (fieldName, item) => {
    if (uploadedFiles[fieldName]) {
      uploadedFiles[fieldName] = uploadedFiles[fieldName].filter((i) => i !== item);
    }
  };
  const addItemCallback = React.useCallback((fieldName, items) => {
    addItem(fieldName, items);
  }, []);

  const deleteItemCallback = React.useCallback((fieldName, item) => {
    deleteItem(fieldName, item);
  }, []);
  if (!userDetails) {
    return (
      <div>
        <LoadingOverlay />
      </div>
    );
  }

  const handleResetPasswordOpen = () => {
    setResetPasswordModalOpen(true);
  };

  const handleResetPasswordClose = () => {
    setResetPasswordModalOpen(false);
  };

  const validationSchema = yup.object({
    firstNameEn: yup
      .string()
      .required(t('Validation.englishReq'))
      .max(30, t('Validation.max30Char'))
      .test('is-english', t('Validation.englishReq'), (value) => {
        const englishRegex = /^[A-Za-z0-9_\s]*$/;
        return englishRegex.test(value);
      }),

    firstNameAr: yup
      .string()
      .max(30, t('Validation.max30Char'))
      .required(t('Validation.arabicReq'))
      .test('is-arabic', t('Validation.arabicReq'), (value) => {
        const arabicRegex = /[\u0600-\u06FF]/;
        const englishRegex = /[A-Za-z0-9]/;
        return arabicRegex.test(value) && !englishRegex.test(value);
      }),

    lastNameEn: yup
      .string()
      .required(t('Validation.englishReq'))
      .max(30, t('Validation.max30Char'))
      .test('is-english', t('Validation.englishReq'), (value) => {
        const englishRegex = /^[A-Za-z0-9_\s]*$/;
        return englishRegex.test(value);
      }),

    lastNameAr: yup
      .string()
      .max(30, t('Validation.max30Char'))
      .required(t('Validation.arabicReq'))
      .test('is-arabic', t('Validation.arabicReq'), (value) => {
        const arabicRegex = /[\u0600-\u06FF]/;
        const englishRegex = /[A-Za-z0-9]/;
        return arabicRegex.test(value) && !englishRegex.test(value);
      }),

    birthDate: yup
      .string()
      .required(t('Validation.enterBirthDate'))
      .test('is-18-years-or-above', t('Validation.mustAbove18'), (value) => {
        const birthDate = moment(value, 'YYYY-MM-DD');
        const age = moment().diff(birthDate, 'years');
        return age >= 18;
      }),
    phone: yup
      .string()
      .required('Phone number is required')
      .matches(/^\+?\d{9}$/, 'Phone number must be in 9 digits'),
  });

  return (
    <Layout>
      <h3>{t('profile.title')}</h3>
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={(values) => {
          const adminData = {
            // birthDate: values.birthDate,
            // email: values.email,
            // firstNameAr: values.firstNameAr,
            // firstNameEn: values.firstNameEn,
            // lastNameAr: values.lastNameAr,
            // lastNameEn: values.lastNameEn,
            // nationalId: values.nationalId,
            // phone: values.phone,
            // userId,
            profilePicture: uploadedFiles.profileImage.map((item) => item.id),
          };

          setLoading(true);
          const actionPromise = updateAdminProfile({ ...adminData });

          actionPromise
            .then((res) => {
              setLoading(false);
              if (res.data.STATUS === 'FAILED' || res.data.STATUS === '0') {
                toast.error(res.data.MESSAGE);
              } else {
                toast.success(res.data.MESSAGE);
                // getUserDetails(userId);
              }
            })
            .catch((error) => {
              setLoading(false);
              const errorMessages = {
                500: 'Server Error',
                400: 'Bad Request',
                401: 'Unauthorized',
                403: 'Forbidden',
                404: 'Not Found',
              };
              if (axios.isAxiosError(error)) {
                const statusCode = error.response?.status;
                const errorMessage = errorMessages[statusCode] || 'An error occurred';
                toast.error(errorMessage);
              } else {
                toast.error('An error occurred');
              }
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm }) => (
          <form onSubmit={handleSubmit}>
            <Grid rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={12} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('profile.jobTitle')}
                </Typography>
                <TextField
                  name="job"
                  disabled
                  fullWidth
                  value={values.job}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('profile.firstNameEn')}
                </Typography>
                <TextField
                  name="firstNameEn"
                  fullWidth
                  value={values.firstNameEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.firstNameEn && Boolean(errors.firstNameEn)}
                  helperText={touched.firstNameEn && errors.firstNameEn}
                  readOnly
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('profile.firstNameAr')}
                </Typography>
                <TextField
                  name="firstNameAr"
                  fullWidth
                  value={values.firstNameAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.firstNameAr && Boolean(errors.firstNameAr)}
                  helperText={touched.firstNameAr && errors.firstNameAr}
                  readOnly
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('profile.lastNameEn')}
                </Typography>
                <TextField
                  name="lastNameEn"
                  fullWidth
                  value={values.lastNameEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.lastNameEn && Boolean(errors.lastNameEn)}
                  helperText={touched.lastNameEn && errors.lastNameEn}
                  readOnly
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('profile.lastNameAr')}
                </Typography>
                <TextField
                  name="lastNameAr"
                  fullWidth
                  value={values.lastNameAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.lastNameAr && Boolean(errors.lastNameAr)}
                  helperText={
                    touched.lastNameAr &&
                    errors.lastNameAr && (
                      <span dangerouslySetInnerHTML={{ __html: errors.lastNameAr.replace('\n', '<br />') }} />
                    )
                  }
                  readOnly
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('profile.nationalId')}
                </Typography>
                <TextField
                  name="nationalId"
                  disabled
                  fullWidth
                  value={values.nationalId}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nationalId && Boolean(errors.nationalId)}
                  helperText={touched.nationalId && errors.nationalId}
                  readOnly
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('profile.birthDate')}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '100%' }}
                    label={t('profile.birthDate')}
                    value={values.birthDate ? dayjs(values.birthDate) : null}
                    onChange={(newValue) => {
                      setFieldValue('birthDate', newValue ? newValue.format('YYYY-MM-DD') : '');
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    readOnly
                  />
                </LocalizationProvider>
                {errors.birthDate && <p style={{ color: '#FF4842', fontSize: '0.75rem' }}>{errors.birthDate}</p>}
              </Grid>

              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('profile.phoneNumber')}
                </Typography>
                <TextField
                  name="phone"
                  fullWidth
                  value={values.phone}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                  readOnly
                />
              </Grid>

              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('profile.email')}
                </Typography>
                <TextField
                  name="email"
                  disabled
                  fullWidth
                  value={values.email}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  readOnly
                />
              </Grid>

              <Grid item xs={2} sm={4} md={6}>
                <Stack>
                  <Typography variant="body1" gutterBottom>
                    {t('profile.status')}
                  </Typography>
                  <TextField name="status" disabled fullWidth value={values.status} variant="outlined" />
                </Stack>
              </Grid>

              <Grid item xs={2} sm={4} md={6}>
                <ControlledCheckbox
                  label={t('profile.isManager')}
                  notChecked
                  checked={values.isManager}
                  onChange={(event) => {
                    handleChange(event);
                    setFieldValue('isManager', event.target.checked);
                  }}
                  readOnly
                />
              </Grid>

              <Grid item xs={12} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('profile.managerName')}
                </Typography>
                <TextField
                  name="managerName"
                  disabled
                  fullWidth
                  value={values.managerName}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('profile.profilePicture')}
                </Typography>
                <UploadFileOnChange
                  type="PHOTO"
                  category="ADMIN"
                  uploadedFilesUploaded={uploadedFiles}
                  label={t('profile.profilePicture')}
                  fieldName="profileImage"
                  acceptedTypes="image/*"
                  setFieldValue={setFieldValue}
                  addItem={addItemCallback}
                  deleteItem={deleteItemCallback}
                />
                {profilePicture && uploadedFiles?.profileImage?.length === 0 && (
                  <PreviewFile
                    file={{
                      path: profilePicture,
                      displayName: t('profile.profilePicture'),
                    }}
                  />
                )}
                <input type="hidden" name="updateUi" />
              </Grid>
              <Grid item xs={2} sm={4} md={12}>
                <Typography variant="body1" gutterBottom>
                  {t('profile.roles')}
                </Typography>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                  {userDetails?.userRoles.map((role, index) => (
                    <Chip
                      key={index}
                      sx={{
                        border: 'none',
                        borderRadius: '10px',
                        backgroundColor: '#0caf60',
                        color: 'white',

                        '& .MuiChip-deleteIcon': {
                          color: 'wheat !important',
                        },
                      }}
                      label={isRtl ? role.nameAr : role.nameEn}
                    />
                  ))}
                </Box>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ width: '100%', mt: 4 }}
                direction="row"
                justifyContent="space-between"
                alignItems="end"
              >
                <Grid item>
                  <Button
                    sx={{ mt: 4, ml: 4, border: '1px solid #111827' }}
                    size="large"
                    type="button"
                    variant="outlined"
                    onClick={handleResetPasswordOpen}
                  >
                    {t('profile.resetPasswordButton')}
                  </Button>
                </Grid>
                <Grid item>
                  <Stack direction={isRtl ? 'row-reverse' : 'row'} spacing={3}>
                    <Button
                      style={{ marginRight: isRtl ? '10px' : '0px' }}
                      sx={{ mt: 4, border: '1px solid #111827' }}
                      size="large"
                      type="button"
                      variant=""
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      {t('General.back')}
                    </Button>
                    <LoadingButton
                      sx={{ mt: 4 }}
                      loading={loading}
                      disabled={!uploadedFiles.profileImage.some((item) => item.id)}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {t('profile.save')}
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <ResetPasswordModal open={isResetPasswordModalOpen} handleClose={handleResetPasswordClose} />
    </Layout>
  );
}
