import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
// @mui
import { Stack, Typography, Skeleton, Grid, Select, MenuItem, FormControl, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import SearchComponet from '../components/search/Search';
import { hasPermission } from '../utils/permissions';
import { getTechnicalSupportList, deleteOurService } from '../network/apis';
import TechnicalSupportTable from '../components/TechnicalSupport/TechnicalSupportTable';
import Reply from '../components/TechnicalSupport/TechnicalSupportReply';
import { ReactComponent as DropdownIcon } from '../theme/images/deopdownIcon.svg';

export default function TechnicalSupport() {
  const [editOrView, setEdit] = useState({
    id: '',
    open: false,
    viewMode: false,
  });
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({ status: '' });
  const [loading, setLoading] = useState(false);
  const [technicalSupportList, setTechnicalSupportList] = useState([]);

  const technicalSupportListApi = (page, search, status) => {
    setLoading(true);
    getTechnicalSupportList(page, search, status)
      .then((res) => {
        setTechnicalSupportList(res.data.content);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    technicalSupportListApi(page, search, filters.status);
  }, [page, search, filters]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const sideMenu = useSelector((state) => state.login?.sideMenu || []);
  //   const hasSearchPermission = hasPermission(
  //     sideMenu,
  //     "/dashboard/plans",
  //     "Search For our services"
  //   );
  //   const hasAddPermission = hasPermission(
  //     sideMenu,
  //     "/dashboard/plans",
  //     "Add our services"
  //   );
  //   const hasEditPermission = hasPermission(
  //     sideMenu,
  //     "/dashboard/plans",
  //     "edit our services"
  //   );
  const hasSearchPermission = true;
  const hasEditPermission = true;

  return (
    <>
      <Helmet>
        <title>   {t('technicalSupport.technical_support')} </title>
      </Helmet>

      <Layout>
        <Box p={2}>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '32px',
              color: '#111827',
            }}
          >
            {t('technicalSupport.technical_support')}
          </Typography>
        </Box>

        <Grid container spacing={2} width={'50%'} columns={{ xs: 4, sm: 8, md: 12 }} mb={2}>
          {' '}
          <Grid item xs={2} sm={4} md={8}>
            {hasSearchPermission && <SearchComponet onSearch={setSearch} style={{ width: '100%' }} />}
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <FormControl fullWidth>
              <Select
                displayEmpty
                value={filters.status}
                sx={{
                  paddingX: '10px',
                  borderRadius: '10px',
                  flexDirection: 'row',
                  justifyContent: 'start',
                }}
                renderValue={(selected) => {
                  if (selected === '1') return t('technicalSupport.technical_support');
                  if (selected === '2') return t('technicalSupport.complain');
                  if (selected === '3') return t('technicalSupport.others');
                  if (selected === '') return t('General.all');
                  return t('General.all');
                }}
                IconComponent={() => <DropdownIcon style={{ width: '15px', height: '15px' }} />}
                labelId="Status"
                id="status"
                onChange={(e) => setFilters({ ...filters, status: e.target.value })}
              >
                <MenuItem value="">{t('General.all')}</MenuItem>
                <MenuItem value="1">{t('technicalSupport.technical_support')}</MenuItem>
                <MenuItem value="2">{t('technicalSupport.complain')}</MenuItem>
                <MenuItem value="3">{t('technicalSupport.others')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <>
              <TechnicalSupportTable
                handleChangePage={handleChangePage}
                page={page}
                requests={technicalSupportList}
                hasEditPermission={hasEditPermission}
                setEdit={setEdit}
              />
            </>
          </>
        )}
        <Reply
          open={editOrView.open}
          isViewMode={editOrView.viewMode}
          handleClose={() => setEdit((prev) => ({ ...prev, open: false }))}
          data={technicalSupportList.find((item) => item.id.toString() === editOrView.id.toString())}
        />
      </Layout>
    </>
  );
}
