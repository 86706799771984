import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

// @mui
import { Stack, Skeleton, Grid, ButtonBase, Typography } from '@mui/material';
import { CityReqTable } from '../sections/@dashboard/ReservatioRequests';
import Layout from '../components/Layout/Layout';
import SearchComponet from '../components/search/Search';
import { getReservationRequestssList, getInvoiceDetails, updateReservationRequestStatus } from '../network/apis'; // Import the API

export default function ReservationsRequests() {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [clientReqList, setClientReqList] = useState([]);
  const rawReservationInvoice = localStorage.getItem('reservationInvoice') || '';
  const reservationInvoice = rawReservationInvoice.trim();
  const clientRequests = (page, search) => {
    setLoading(true);
    getReservationRequestssList(page, search)
      .then((res) => {
        setClientReqList(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching admin list:', error);
        toast.error(error.response.data.MESSAGE);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (reservationInvoice) {
      setSearch(reservationInvoice);
    }
    clientRequests(page, search);
  }, [page, search, reservationInvoice]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const updateDate = (id, status) => {
    const obj = { ID: id, REQUEST_STATUS: status };
    updateReservationRequestStatus(obj)
      .then((res) => {
        if (res.data.STATUS === 'FAILED' || res.data.STATUS === '0') toast.error(res.data.MESSAGE);
        else toast.success(res.data.MESSAGE);
        clientRequests(page, search);
      })
      .catch((error) => {
        toast.error(error.response.data.MESSAGE);
        clientRequests(page, search);
      });
  };

  const handleDownload = (id) => {
    getInvoiceDetails(id)
      .then((res) => {
        const contentDisposition = res.headers['content-disposition'];
        let fileName = 'invoice.pdf';

        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }

        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
        toast.error(error.response.data.MESSAGE);
      });
  };

  return (
    <>
      <Helmet>
        <title> {t('reversationRequest.reservationsTitle')}</title>
      </Helmet>

      <Layout>
        <Grid marginBottom={1} display="flex" alignItems={'center'} justifyContent="space-between" md={6} xs={12}>
          <Typography sx={{ fontSize: '24px', fontWeight: '700', lineHeight: '32px', color: '#111827' }}>
            {t('reversationRequest.reservationsTitle')}
          </Typography>
        </Grid>
        <Grid mb={2} rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={4}>
            <SearchComponet onSearch={setSearch} style={{ width: '100%' }} />
          </Grid>
        </Grid>

        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <CityReqTable
              handleChangePage={handleChangePage}
              page={page}
              requests={clientReqList}
              onDownload={handleDownload}
              updateRequestStatus={updateDate} // Pass the download handler
            />
          </>
        )}
      </Layout>
    </>
  );
}
