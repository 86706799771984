import * as React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import {
  Grid,
  Typography,
  Button,
  Stack,
  CircularProgress,
  MenuItem,
  FormControl,
  Select,
  Box,
} from "@mui/material";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/Modal";
import { updateAskTemplate, addAskTemplate } from "../../../network/apis";
import { ReactComponent as DropdownIcon } from "../../../theme/images/deopdownIcon.svg";

export default function AddOrEditModal({
  open,
  handleClose,
  data,
  refreshApi,
  mode = "edit",
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  console.log(mode);
  const handleSubmit = async (values) => {
    setLoading(true);
    let res;
    if (mode === "edit") {
      res = await updateAskTemplate({
        id: data?.id,
        nameEn: values.nameEn,
        nameAr: values.nameAr,
      });
    } else {
      res = await addAskTemplate({
        nameEn: values.nameEn,
        nameAr: values.nameAr,
      });
    }
    if (res?.data?.STATUS === "SUCCESS") {
      toast.success(res?.data?.MESSAGE);
      refreshApi();
      handleClose();
      setLoading(false);
    } else {
      toast.error(res?.data?.MESSAGE);
      setLoading(false);
    }
  };
  return (
    <Modal
      handleClose={handleClose}
      open={open}
      title={t("response_templates.response_templates")}
      width={"40%"}
    >
      <Formik
        initialValues={{
          nameEn: data?.nameEn || "",
          nameAr: data?.nameAr || "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
        }) => {
          return (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Grid
                  padding={"20px"}
                  container
                  spacing={{ xs: 2, md: 2 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{
                    height: "100%",
                    padding: "20px",
                    borderBottom: "1px solid #E5E5E5",
                  }}
                >
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t("response_templates.name_en")}
                    </Typography>
                    <TextField
                      name="nameEn"
                      required
                      fullWidth
                      value={values.nameEn}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.nameEn && Boolean(errors.nameEn)}
                      helperText={touched.nameEn && errors.nameEn}
                    />
                  </Grid>{" "}
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t("response_templates.name_ar")}
                    </Typography>
                    <TextField
                      name="nameAr"
                      required
                      fullWidth
                      value={values.nameAr}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.nameAr && Boolean(errors.nameAr)}
                      helperText={touched.nameAr && errors.nameAr}
                    />
                  </Grid>
                </Grid>
                <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  justifyContent="end"
                  alignItems="end"
                  gap={2}
                >
                  <Button
                    sx={{ mt: 4, border: "1px solid #111827" }}
                    size="large"
                    type="button"
                    onClick={handleClose}
                  >
                    {" "}
                    {t("General.cancel")}
                  </Button>
                  <Button
                    sx={{ mt: 4, border: "1px solid #111827" }}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {" "}
                    {loading ? <CircularProgress /> : t("General.submit")}
                    {}
                  </Button>
                </Stack>
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
}
