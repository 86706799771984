import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { ReactComponent as ReportIcon } from '../theme/images/reportsIcon.svg';
import LoadingOverlay from '../components/loading-overlay/LoadingOverlay';
import { getReportsAllList, generateDashboardReport } from '../network/apis';
import DateFilterDialog from '../sections/@dashboard/Reports/ReportsFilter';

const ReportCard = ({ title, onClick }) => (
  <Card sx={{ minWidth: 300, borderRadius: '14px', width: '500px', cursor: 'pointer' }} onClick={onClick}>
    <CardContent
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        gap: 2,
        p: 1,
        paddingBottom: '10px !important',
      }}
    >
      <ReportIcon />
      <Typography variant="body1" component="div" sx={{ textAlign: 'right', fontWeight: 'bold' }}>
        {title}
      </Typography>
    </CardContent>
  </Card>
);

const ReportsDashboard = () => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';

  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await getReportsAllList();
        setReports(response.data);
      } catch (error) {
        console.error('Error fetching reports:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  const handleCardClick = (reportName) => {
    setSelectedReport(reportName);
    setOpenFilterDialog(true);
  };

  const handleSave = async () => {
    if (startDate && endDate && selectedReport) {
      const data = {
        reportName: selectedReport,
        lang: 'EN',
        parameters: {
          P_START_DATE: moment(startDate).format('YYYY-MM-DD'),
          P_END_DATE: moment(endDate).format('YYYY-MM-DD'),
        },
      };
      const reportName = await generateDashboardReport(data);
      window.open(`${reportName.data}`, '_blank');
    }

    setOpenFilterDialog(false);
  };

  return (
    <>
      <Helmet>
        <title> {t('reports.reports')}</title>
      </Helmet>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          {t('reports.reports')}
        </Typography>

        {loading ? (
          <LoadingOverlay />
        ) : (
          <Grid container spacing={3}>
            {reports.map((report, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <ReportCard
                  title={isRtl ? report.reportNameAr : report.reportNameEn}
                  onClick={() => handleCardClick(report.fileName)}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <DateFilterDialog
          open={openFilterDialog}
          onClose={() => setOpenFilterDialog(false)}
          onSave={handleSave}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </Box>
    </>
  );
};

export default ReportsDashboard;
