import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { getRealStateRequest } from "../network/apis";

import LoadingOverlay from "../components/loading-overlay/LoadingOverlay";
import Layout from "../components/Layout/Layout";
import ReplyModal from "../sections/@dashboard/realStateRequests/ReblyModal"; // Ensure this is imported

const ViewRealStateRequests = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openReplyModal, setOpenReplyModal] = useState(false);
  const [data, setData] = useState({});

  // Handle closing the modal
  const handleCloseReplyModal = () => {
    setOpenReplyModal(false);
  };

  // Fetch data function
  const getData = async () => {
    setLoading(true);
    try {
      const response = await getRealStateRequest(id);
      setData(response?.data?.DATA);
    } catch (error) {
      console.error("Error fetching real state request:", error);
      // Optional: Add user feedback for error
      alert(t("realStateRequests.errorFetchingData"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <Layout>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <h3>{t("realStateRequests.realStateRequet")}</h3>
      </Box>

      {loading ? (
        <LoadingOverlay />
      ) : (
        <Formik
          initialValues={{
            requestNumber: data?.requestNumber || "--------",
            type: data?.type || "--------",
            fullName: data?.user?.fullName || "--------",
            phone: data?.user?.phone || "--------",
            requestType: data?.requestType || "--------",
            nationalId: data?.user?.nationalId || "--------",
            region: data?.region || "--------",
            city: data?.city || "--------",
            district: data?.district || "--------",
            areaFrom: data?.areaFrom || "--------",
            areaTo: data?.areaTo || "--------",
            budgetFrom: data?.budgetFrom || "--------",
            budgetTo: data?.budgetTo || "--------",
            notes: data?.notes || "--------",
            status: data?.status || "--------",
            comment: data?.comment || "--------",
            actionDate: data?.actionDate
              ? new Date(data?.actionDate).toLocaleDateString()
              : "---------",
            creationDate: data?.creationDate
              ? new Date(data?.creationDate).toLocaleDateString()
              : "---------",
            contactedByObj: data?.contactedByObj,
            contactedBy: data?.contactedBy,

          }}
        >
          {({ values }) => (
            <>
              <Paper
                sx={{
                  mb: 3,
                  p: 2,
                  borderRadius: "12px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {t("realStateRequests.requesterInfo")}
                </Typography>
                <Grid
                  container
                  spacing={3}
                  justifyContent="center"
                  columns={12}
                >
                  {" "}
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.full_name")}
                    </Typography>
                    <TextField
                      value={values?.fullName}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.phone")}
                    </Typography>
                    <TextField
                      value={values?.phone || "-------"}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>{" "}
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.nationalId")}
                    </Typography>
                    <TextField
                      value={values?.nationalId || "-------"}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                sx={{
                  mb: 3,
                  p: 2,
                  borderRadius: "12px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {t("realStateRequests.requestInfo")}
                </Typography>
                <Grid
                  container
                  spacing={3}
                  justifyContent="center"
                  columns={12}
                >
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.requestId")}
                    </Typography>
                    <TextField
                      value={values?.requestNumber || "-------"}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.type")}
                    </Typography>
                    <TextField
                      value={values?.requestType?.name || "-------"}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.region")}
                    </Typography>
                    <TextField
                      value={values?.region?.name || "-------"}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.city")}
                    </Typography>
                    <TextField
                      value={values?.city?.name || "-------"}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>{" "}
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.district")}
                    </Typography>
                    <TextField
                      value={values?.district?.name || "-------"}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={3}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.area_from")}
                    </Typography>
                    <TextField
                      value={values?.areaFrom || "-------"}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={3}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.area_to")}
                    </Typography>
                    <TextField
                      value={values?.areaTo || "-------"}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={3}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.budget_from")}
                    </Typography>
                    <TextField
                      value={values?.budgetFrom || "-------"}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={3}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.budget_to")}
                    </Typography>
                    <TextField
                      value={values?.budgetTo || "-------"}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.request_date")}
                    </Typography>
                    <TextField
                      value={values?.creationDate}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.action_date")}
                    </Typography>
                    <TextField
                      value={values?.actionDate}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>{" "}
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.contacted_by")}
                    </Typography>
                    <TextField
                      value={values?.contactedByObj?.name ? values?.contactedByObj?.name : values?.contactedBy === '-1' ? t("realStateRequests.others") : "-------"}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>{" "}
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.comment")}
                    </Typography>
                    <TextField
                      value={values?.comment || "-------"}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>{" "}
                  <Grid item xs={2} sm={4} md={6}>
                    {values?.status === "COMPLETED" ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "4px 16px",
                          backgroundColor: "#E7F7EF",
                          borderRadius: "8px",
                          width: "40%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#0CAF60",
                            fontWeight: "700",
                            fontSize: "16px",
                            lineHeight: "24px",
                          }}
                          variant="subtitle2"
                          textAlign={"center"}
                          noWrap
                        >
                          {t("realStateRequests.completed")}
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "4px 16px",
                          backgroundColor: "#E7F7EF",
                          borderRadius: "8px",
                          width: "40%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#E03137",
                            fontWeight: "700",
                            fontSize: "16px",
                            lineHeight: "24px",
                          }}
                          variant="subtitle2"
                          textAlign={"center"}
                          noWrap
                        >
                          {t("realStateRequests.pending")}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={6} />
                  <Grid
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    item
                    xs={2}
                    sm={4}
                    md={12}
                  >
                    <Button
                      sx={{ mt: 4, border: "1px solid #111827", mr: 4 }}
                      size="large"
                      type="button"
                      variant=""
                      onClick={() => navigate(-1)}
                    >
                      {t("realStateRequests.back")}
                    </Button>
                    {data?.status === "PENDING" && (
                      <Button
                        onClick={() => setOpenReplyModal(true)}
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          borderRadius: "10px",
                          padding: "21px 24px",
                          width: "164px",
                          height: "56px",
                          display: "flex",
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          <span>{t("realStateRequests.reply")}</span>
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                  {/* Other fields here */}
                </Grid>
              </Paper>
            </>
          )}
        </Formik>
      )}

      {openReplyModal && (
        <ReplyModal
          open={openReplyModal}
          handleClose={handleCloseReplyModal}
          realStateRequestApi={getData}
          id={data?.id}
        />
      )}
    </Layout>
  );
};

export default ViewRealStateRequests;
