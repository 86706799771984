// src/RolesList.js
import { useEffect, useState } from 'react';
// localization

import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, FormControl, InputLabel, MenuItem, Select, Skeleton, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { getRolesList } from '../../../network/apis';
import RolesTable from '../RolesTable';
import SearchComponent from '../../../components/search/Search';
import Layout from '../../../components/Layout/Layout';
import { ReactComponent as DropdownIcon } from '../../../theme/images/deopdownIcon.svg';

const RolesList = () => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [clientReqList, setClientReqList] = useState({ content: [], totalPages: 0, totalElements: 0 });
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [status, setChangeStatus] = useState('');
  const handleChange = (e) => {
    setChangeStatus(e.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const clientRequests = (search, page, rowsPerPage, status) => {
    setLoading(true);
    getRolesList(page, search, rowsPerPage, status)
      .then((res) => {
        setClientReqList(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching owners list:', error);
        toast.error(error.response.data.MESSAGE);
        setLoading(false);
      });
  };

  useEffect(() => {
    clientRequests(search, page, rowsPerPage, status);
  }, [page, search, rowsPerPage, status]);

  return (
    <>
      <Helmet>
        <title>  {t('Roles.roles')}</title>
      </Helmet>

      <Layout>
        <Grid marginBottom={1} display="flex" alignItems={'center'} justifyContent="space-between" md={6} xs={12}>
          <Typography sx={{ fontSize: '24px', fontWeight: '700', lineHeight: '32px', color: '#111827' }}>
            {t('Roles.roles')}
          </Typography>
          <Button
            style={{
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '10px',
              padding: '21px 24px',
              width: '164px',
              height: '56px',
            }}
            onClick={() => navigate(`/dashboard/add-role`)}
          >
            {' '}
            + {t('Roles.addRole')}
          </Button>
        </Grid>
        <Grid mb={2} rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={4}>
            <SearchComponent onSearch={setSearch} style={{ width: '100%' }} />
          </Grid>

          <Grid item xs={2} sm={4} md={4}>
            <FormControl fullWidth>
              <Select
                displayEmpty
                value={status}
                sx={{
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  borderRadius: '10px',
                }}
                renderValue={(selected) => {
                  if (selected === '') {
                    return <span> {t('Units.all')} </span>;
                  }
                  if (selected === 'FREE') return <span>{t('Units.available')}</span>;
                  if (selected === 'RESERVED') return <span>{t('Units.reserved')}</span>;
                  if (selected === 'SOLD') return <span>{t('Units.sold')}</span>;
                  return <span style={{ color: '#9e9e9e' }}> {t('General.AllStatus')} </span>;
                }}
                IconComponent={() => <DropdownIcon style={{ width: '15px', height: '15px' }} />}
                labelId="Status"
                id="status"
                onChange={handleChange}
              >
                <MenuItem value="">{t('General.AllStatus')}</MenuItem>
                  <MenuItem value="TRUE">{t('General.Active')}</MenuItem>
                  <MenuItem value="FALSE">{t('General.InActive')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <RolesTable
              handleChangePage={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              requests={clientReqList}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        )}
      </Layout>
    </>
  );
};

export default RolesList;
