import * as React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import {
  Grid,
  Typography,
  Button,
  Stack,
  CircularProgress,
  MenuItem,
  FormControl,
  Select,
  Box,
} from "@mui/material";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/Modal";
import { updateAskRequests, addAskRequests } from "../../../network/apis";
import { ReactComponent as DropdownIcon } from "../../../theme/images/deopdownIcon.svg";

export default function AddOrEditModal({
  open,
  handleClose,
  data,
  refreshApi,
  mode = "edit",
}) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = async (values) => {
    setLoading(true);
    let res;
    if (mode === "edit") {
      res = await updateAskRequests({
        id: data?.id,
        nameEn: values.nameEn,
        nameAr: values.nameAr,
        category: values.category,
      });
    } else {
      res = await addAskRequests({
        nameEn: values.nameEn,
        nameAr: values.nameAr,
        category: values.category,
      });
    }
    if (res?.data?.STATUS === "SUCCESS") {
      toast.success(res?.data?.MESSAGE);
      refreshApi();
      handleClose();
      setLoading(false);
    } else {
      toast.error(res?.data?.MESSAGE);
      setLoading(false);
    }
  };
  const list = [
    {
      id: 1,
      labelAr: "مشروع",
      labelEn: "Project",
      value: "PROJECT",
    },
    {
      id: 2,
      labelAr: "مخطط",
      labelEn: "Plan",
      value: "PALN",
    },
  ];
  return (
    <Modal
      handleClose={handleClose}
      open={open}
      title={t("response_templates.ask_templates")}
      width={"40%"}
    >
      <Formik
        initialValues={{
          nameEn: data?.nameEn || "",
          nameAr: data?.nameAr || "",
          category: data?.category || "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          touched,
        }) => {
          return (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Grid
                  padding={"20px"}
                  container
                  spacing={{ xs: 2, md: 2 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{
                    height: "100%",
                    padding: "20px",
                    borderBottom: "1px solid #E5E5E5",
                  }}
                >
                  <Grid item xs={2} sm={4} md={12}>
                    <Typography variant="body1" gutterBottom>
                      {t("response_templates.add_ask_requests_type")}
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        value={values.category}
                        sx={{
                          paddingX: "10px",
                          borderRadius: "10px",
                          flexDirection: "row",
                          justifyContent: "start",
                        }}
                        renderValue={(selected) => {
                          const item = list?.find(
                            (item) => item.value === selected
                          );
                          return (
                            <Box display={"flex"} alignItems={"center"} gap={2}>
                              <Typography
                                margin={"0"}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                {isRtl
                                  ? item?.labelAr ||
                                    t("realStateRequests.choose")
                                  : item?.labelEn ||
                                    t("realStateRequests.choose")}
                              </Typography>
                            </Box>
                          );
                        }}
                        IconComponent={() => (
                          <DropdownIcon
                            style={{ width: "15px", height: "15px" }}
                          />
                        )}
                        labelId="Status"
                        id="status"
                        onChange={(e) =>
                          setFieldValue("category", e.target.value)
                        }
                      >
                        {list?.map((item, index) => (
                          <MenuItem key={index} value={item?.value}>
                            <Typography
                              margin={"0"}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              {isRtl ? item?.labelAr : item?.labelEn}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>{" "}
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t("response_templates.name_en")}
                    </Typography>
                    <TextField
                      name="nameEn"
                      required
                      fullWidth
                      value={values.nameEn}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.nameEn && Boolean(errors.nameEn)}
                      helperText={touched.nameEn && errors.nameEn}
                    />
                  </Grid>{" "}
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t("response_templates.name_ar")}
                    </Typography>
                    <TextField
                      name="nameAr"
                      required
                      fullWidth
                      value={values.nameAr}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.nameAr && Boolean(errors.nameAr)}
                      helperText={touched.nameAr && errors.nameAr}
                    />
                  </Grid>
                </Grid>
                <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  justifyContent="end"
                  alignItems="end"
                  gap={2}
                >
                  <Button
                    sx={{ mt: 4, border: "1px solid #111827" }}
                    size="large"
                    type="button"
                    onClick={handleClose}
                  >
                    {" "}
                    {t("General.cancel")}
                  </Button>
                  <Button
                    sx={{ mt: 4, border: "1px solid #111827" }}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {" "}
                    {loading ? <CircularProgress /> : t("General.submit")}
                    {}
                  </Button>
                </Stack>
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
}
