import { Helmet } from "react-helmet-async";

import { useState, useEffect } from "react";

import { Formik } from "formik";

// @mui
import {
  Stack,
  Typography,
  Skeleton,
  Grid,
  SvgIcon,
  InputLabel,
  Autocomplete,
  Select,
  TextField,
  Box,
  MenuItem,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useTranslation } from "react-i18next";
import Layout from "../components/Layout/Layout";
import SearchComponet from "../components/search/Search";

import {
  getSearchResultList,
  getRegionList,
  getCitiesListWithRegion,
  getDistrictListWithCity,
  getFacilitiesList,
  getOwnersData,
} from "../network/apis";
import { ReactComponent as DropdownIcon } from "../theme/images/deopdownIcon.svg";

import RequestsAndSupportTable from "../components/RequestsAndSupport/DataTable";
import FilterComponent from "../components/filter/Filter";

export default function ManageRealStateRequests() {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  const [search, setSearch] = useState("");
  const [regionId, setRegionId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [filters, setFilters] = useState({
    regionFilter: "",
    cityFilter: "",
    districtFilter: "",
    developerFilter: "",
    facilityFilter: "",
  });
  const [filtersDate, setFiltersDate] = useState({
    type: "month",
    value: "1",
    start: "",
    end: "",
  });

  const [regionOptions, setRegionOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [developersOptions, setDevelopersOptions] = useState([]);
  const [facilitiesOptions, setFacilitiesOptions] = useState([]);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [realStateRequests, setRealStateRequests] = useState([]);
  const getAllRegions = (page, search) => {
    getRegionList(page, 100, search).then((res) => {
      setRegionOptions(res.data.content);
    });
  };
  const getAllFacilities = () => {
    getFacilitiesList(0, "").then((res) => {
      setFacilitiesOptions(res.data.content);
    });
  };
  const getAllDevelopers = () => {
    getOwnersData().then((res) => {
      setDevelopersOptions(res.data);
    });
  };
  useEffect(() => {
    getAllRegions(0, filters?.regionFilter);
  }, [filters?.regionFilter]);
  useEffect(() => {
    const getCities = () => {
      getCitiesListWithRegion(0, 100, regionId, filters?.cityFilter).then(
        (res) => {
          setCityOptions(res.data.content);
        }
      );
    };
    if (regionId) {
      getCities();
    }
  }, [filters?.cityFilter]);
  useEffect(() => {
    const getDistricts = () => {
      getDistrictListWithCity(0, 100, cityId, filters?.districtFilter).then(
        (res) => {
          setDistrictOptions(res.data.content);
        }
      );
    };
    if (regionId) {
      getDistricts();
    }
  }, [filters?.districtFilter]);
  useEffect(() => {
    getAllDevelopers();
    getAllFacilities();
  }, []);

  const getSearchResultListFunc = (page) => {
    setLoading(true);
    getSearchResultList(page)
      .then((res) => {
        setRealStateRequests(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    getSearchResultListFunc(page, search);
  }, [page, search]);

  const handleChangePage = (__, newPage) => {
    setPage(newPage - 1);
  };
  const listOperator = [
    {
      labelEn: "Greater than",
      labelAr: "اكبر من",
      value: ">",
    },
    {
      labelEn: "Less than",
      labelAr: "اقل من",
      value: "<",
    },
  ];
  const handleApply = (newFilters) => {
    setFiltersDate(newFilters);
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  return (
    <>
      <Helmet>
        <title> {t("requestsAndSupport.requestsAndSupport")} </title>
      </Helmet>

      <Layout>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "32px",
            color: "#111827",
          }}
        >
          {t("requestsAndSupport.requestsAndSupport")}{" "}
        </Typography>

        <Formik
          initialValues={{
            region: null,
            city: null,
            district: null,
            area: "",
            opertatorArea: "",
            budget: "",
            opertatorBudget: "",
            developer: "",
            facility: "",
          }}
          onSubmit={(values) => {
            getSearchResultList(
              page,
              filtersDate.type,
              filtersDate.value,
              filtersDate.start,
              filtersDate.end,
              values?.region?.id,
              values?.city?.id,
              values?.district?.id,
              values?.developer?.id,
              values?.facility?.id,
              values?.area,
              values?.opertatorArea,
              values?.budget,
              values?.opertatorBudget
            ).then((res) => {
              setRealStateRequests(res.data);
            });
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit, handleReset
          }) => {
            console.log(values);
            return (
              <form onSubmit={handleSubmit}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    sx={{ height: "auto" }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography variant="h6">
                      {t("requestsAndSupport.filters")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid
                      container
                      spacing={2}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      <Grid item xs={12} sm={12} md={6}>
                        <FilterComponent onApply={handleApply} />
                      </Grid>
                      <Grid item xs={6} display="flex" justifyContent="end">
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          onClick={() => {
                            getSearchResultList(
                              page,
                              filtersDate.type,
                              filtersDate.value,
                              filtersDate.start,
                              filtersDate.end,
                              values?.region?.id,
                              values?.city?.id,
                              values?.district?.id,
                              values?.developer?.id,
                              values?.facility?.id,
                              values?.area,
                              values?.opertatorArea,
                              values?.budget,
                              values?.opertatorBudget,
                              true
                            );
                          }}

                        >
                          {t("requestsAndSupport.export")}{" "}
                        </Button>
                      </Grid>
                      {/* Region Filter */}
                      <Grid item xs={12} sm={12} md={4}>
                        <Stack gap={1}>
                          <InputLabel id="region">
                            {t("requestsAndSupport.region")}
                          </InputLabel>
                          <Autocomplete
                            disablePortal
                            id="region-autocomplete"
                            value={values?.region || null}
                            onChange={(__, newValue) => {
                              setFieldValue("region", newValue);
                              setRegionId(newValue?.id);
                              getCitiesListWithRegion(
                                0,
                                100,
                                newValue?.id,
                                ""
                              ).then((res) => {
                                setCityOptions(res.data.content);
                              });
                              if (!newValue) {
                                setFieldValue("city", null);
                                setFieldValue("district", null);
                              }
                            }}
                            options={regionOptions?.map((option) => ({
                              id: option.id,
                              nameEn: option.nameEn,
                              nameAr: option.nameAr,
                            }))}
                            getOptionLabel={(option) =>
                              isRtl ? option.nameAr : option.nameEn
                            }
                            sx={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                placeholder={t(
                                  "requestsAndSupport.enter_region"
                                )}
                                name="regionFilter"
                                value={filters.regionFilter}
                                onChange={handleFilterChange}
                                {...params}
                              />
                            )}
                            IconComponent={() => (
                              <SvgIcon
                                style={{
                                  transform: "rotate(-360deg)",
                                  color: "#637381",
                                }}
                              >
                                <path d="M7 10l5 5 5-5z" />
                              </SvgIcon>
                            )}
                          />
                        </Stack>
                      </Grid>

                      {/* City Filter */}
                      <Grid item xs={12} sm={12} md={4}>
                        <Stack gap={1}>
                          <InputLabel id="city">
                            {t("requestsAndSupport.city")}
                          </InputLabel>
                          <Autocomplete
                            disablePortal
                            id="city-autocomplete"
                            value={values?.city || null}
                            disabled={!values?.region}
                            onChange={(__, newValue) => {
                              setFieldValue("city", newValue);
                              setCityId(newValue?.id);
                              getDistrictListWithCity(
                                0,
                                100,
                                newValue?.id
                              ).then((res) => {
                                setDistrictOptions(res.data.content);
                              });
                              if (!newValue) {
                                setFieldValue("district", null);
                              }
                            }}
                            options={cityOptions?.map((option) => ({
                              id: option.id,
                              nameEn: option.nameEn,
                              nameAr: option.nameAr,
                            }))}
                            getOptionLabel={(option) =>
                              isRtl ? option.nameAr : option.nameEn
                            }
                            sx={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                placeholder={t("requestsAndSupport.enter_city")}
                                name="cityFilter"
                                value={filters.cityFilter}
                                onChange={handleFilterChange}
                                {...params}
                              />
                            )}
                            IconComponent={() => (
                              <SvgIcon
                                style={{
                                  transform: "rotate(-360deg)",
                                  color: "#637381",
                                }}
                              >
                                <path d="M7 10l5 5 5-5z" />
                              </SvgIcon>
                            )}
                          />
                        </Stack>
                      </Grid>

                      {/* District Filter */}
                      <Grid item xs={12} sm={12} md={4}>
                        <Stack gap={1}>
                          <InputLabel id="district">
                            {t("requestsAndSupport.district")}
                          </InputLabel>
                          <Autocomplete
                            disablePortal
                            id="district-autocomplete"
                            value={values?.district || null}
                            disabled={!values?.city}
                            onChange={(__, newValue) => {
                              setFieldValue("district", newValue);
                            }}
                            options={districtOptions?.map((option) => ({
                              id: option.id,
                              nameEn: option.nameEn,
                              nameAr: option.nameAr,
                            }))}
                            getOptionLabel={(option) =>
                              isRtl ? option.nameAr : option.nameEn
                            }
                            sx={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                placeholder={t(
                                  "requestsAndSupport.enter_district"
                                )}
                                name="districtFilter"
                                value={filters.districtFilter}
                                onChange={handleFilterChange}
                                {...params}
                              />
                            )}
                            IconComponent={() => (
                              <SvgIcon
                                style={{
                                  transform: "rotate(-360deg)",
                                  color: "#637381",
                                }}
                              >
                                <path d="M7 10l5 5 5-5z" />
                              </SvgIcon>
                            )}
                          />
                        </Stack>
                      </Grid>

                      {/* Area Filter */}
                      <Grid item xs={12} sm={12} md={3}>
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            overflow: "hidden",
                            width: "100%",
                            padding: "5px",
                          }}
                        >
                          {/* Select Operator */}
                          <Select
                            value={values.opertatorArea}
                            onChange={(e) => {
                              console.log("opertatorArea", e.target.value);
                              setFieldValue("opertatorArea", e.target.value);
                            }}
                            renderValue={(selected) => {
                              const item = listOperator?.find(
                                (item) => item.value === selected
                              );
                              return (
                                <Typography fontSize={12}>
                                  {isRtl
                                    ? item?.labelAr ||
                                    t("requestsAndSupport.choose")
                                    : item?.labelEn ||
                                    t("requestsAndSupport.choose")}
                                </Typography>
                              );
                            }}
                            IconComponent={() => (
                              <SvgIcon
                                sx={{
                                  width: "15px",
                                  height: "15px",
                                  color: "#00796b",
                                }}
                              />
                            )}
                            sx={{
                              width: "40%", // Allocate 40% space to the Select
                              border: "none", // Remove border from the Select component
                              fontWeight: 500, // Use medium font weight for the text
                              "& .MuiSelect-select": {
                                padding: "12px", // Padding for Select input area
                                border: "none", // Remove the internal border from the Select
                              },
                              "& .MuiSelect-icon": {
                                color: "#00796b", // Icon color
                              },
                            }}
                          >
                            {listOperator?.map((item, index) => (
                              <MenuItem key={index} value={item?.value}>
                                <Typography variant="body2">
                                  {isRtl ? item?.labelAr : item?.labelEn}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>

                          <Box
                            sx={{
                              width: "1px",
                              backgroundColor: "#cccccc86",
                              height: "100%",
                            }}
                          />
                          <TextField
                            name="area"
                            value={values.area}
                            variant="outlined"
                            placeholder={t("requestsAndSupport.enter_area")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{ disableUnderline: true }}
                            sx={{
                              width: "60%", // Allocate 60% space to the input
                              // paddingX: "10px", // Padding inside the TextField
                              border: "none", // Remove the border from the TextField itself
                              "& .MuiOutlinedInput-root": {
                                border: "none", // Remove internal borders from TextField
                              },

                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-input": {
                                padding: "12px", // Padding inside the input for better spacing
                              },
                            }}
                          />
                        </Box>
                      </Grid>

                      {/* Budget Filter */}
                      <Grid item xs={12} sm={12} md={3}>
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{
                            border: "1px solid #ccc", // Subtle border around the container
                            borderRadius: "8px", // Rounded corners
                            overflow: "hidden", // Prevent overflow
                            width: "100%", // Full width of the grid item
                            padding: "5px", // Inner padding for spacing
                          }}
                        >
                          {/* Select Operator */}
                          <Select
                            value={values.opertatorBudget}
                            onChange={(e) =>
                              setFieldValue("opertatorBudget", e.target.value)
                            }
                            renderValue={(selected) => {
                              const item = listOperator?.find(
                                (item) => item.value === selected
                              );
                              return (
                                <Typography fontSize={12}>
                                  {isRtl
                                    ? item?.labelAr ||
                                    t("requestsAndSupport.choose")
                                    : item?.labelEn ||
                                    t("requestsAndSupport.choose")}
                                </Typography>
                              );
                            }}
                            IconComponent={() => (
                              <SvgIcon
                                sx={{
                                  width: "15px",
                                  height: "15px",
                                  color: "#00796b",
                                }}
                              />
                            )}
                            sx={{
                              width: "40%", // Allocate 40% space to the Select
                              border: "none", // Remove border from the Select component
                              fontWeight: 500, // Use medium font weight for the text
                              "& .MuiSelect-select": {
                                padding: "12px", // Padding for Select input area
                                border: "none", // Remove the internal border from the Select
                              },
                              "& .MuiSelect-icon": {
                                color: "#00796b", // Icon color
                              },
                            }}
                          >
                            {listOperator?.map((item, index) => (
                              <MenuItem key={index} value={item?.value}>
                                <Typography variant="body2">
                                  {isRtl ? item?.labelAr : item?.labelEn}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                          {/* Divider */}
                          <Box
                            sx={{
                              width: "1px", // Divider width
                              backgroundColor: "#ccc", // Divider color
                              height: "40px", // Divider height
                              marginX: "8px", // Space between TextField and Select
                            }}
                          />
                          {/* Budget Input */}
                          <TextField
                            name="budget"
                            value={values.budget}
                            variant="outlined"
                            placeholder={t("requestsAndSupport.enter_budget")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              disableUnderline: true, // Remove underline for cleaner look
                            }}
                            sx={{
                              width: "60%", // Allocate 60% space to the input
                              // paddingX: "10px", // Padding inside the TextField
                              border: "none", // Remove the border from the TextField itself
                              "& .MuiOutlinedInput-root": {
                                border: "none", // Remove internal borders from TextField
                              },
                              "& .MuiInputBase-input": {
                                padding: "12px", // Padding inside the input for better spacing
                              },

                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={4} md={3}>
                        <Stack>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            value={values?.developer ? values?.developer : null}
                            onChange={(__, newValue) => {
                              // Set the new region value
                              setFieldValue("developer", newValue);
                            }}
                            options={developersOptions?.map((option) => ({
                              id: option.id,
                              nameEn: option?.nameEn,
                              nameAr: option?.nameAr,
                            }))}
                            getOptionLabel={(option) =>
                              isRtl
                                ? option.nameAr
                                : option.nameEn || option.nameEn
                            }
                            sx={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                placeholder={t(
                                  "requestsAndSupport.enter_developers"
                                )}
                                name="developerFilter"
                                onChange={handleFilterChange}
                                value={filters.developerFilter}
                                {...params}
                              />
                            )}
                            IconComponent={() => (
                              <SvgIcon
                                style={{
                                  transform: "rotate(-360deg)",
                                  color: "#637381",
                                }}
                              >
                                <path d="M7 10l5 5 5-5z" />
                              </SvgIcon>
                            )}
                          />
                        </Stack>{" "}
                      </Grid>
                      <Grid item xs={2} sm={4} md={3}>
                        <Stack>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            value={values?.facility ? values?.facility : null}
                            onChange={(__, newValue) => {
                              // Set the new region value
                              setFieldValue("facility", newValue);
                            }}
                            options={facilitiesOptions?.map((option) => ({
                              id: option.id,
                              nameEn: option.nameEn,
                              nameAr: option.nameAr,
                            }))}
                            getOptionLabel={(option) =>
                              isRtl
                                ? option.nameAr
                                : option.nameEn || option.nameEn
                            }
                            sx={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                placeholder={t(
                                  "requestsAndSupport.enter_facility"
                                )}
                                name="facilityFilter"
                                onChange={handleFilterChange}
                                value={filters.facilityFilter}
                                {...params}
                              />
                            )}
                            IconComponent={() => (
                              <SvgIcon
                                style={{
                                  transform: "rotate(-360deg)",
                                  color: "#637381",
                                }}
                              >
                                <path d="M7 10l5 5 5-5z" />
                              </SvgIcon>
                            )}
                          />
                        </Stack>{" "}
                      </Grid>
                      {/* Apply Button */}
                      <Grid item xs={12}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"flex-end"}
                          gap={2}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={() => {
                              handleReset()
                              getSearchResultList(
                                page,
                                'month',
                                '',
                                '',
                                '',
                                '',
                                '',
                                '',
                                '',
                                '',
                                '',
                                '',
                                '',
                              ).then((res) => {
                                setRealStateRequests(res.data);
                              })
                            }
                            }
                          >
                            {t("requestsAndSupport.reset")}{" "}
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            {t("requestsAndSupport.applyFilters")}{" "}
                          </Button>

                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </form>
            );
          }}
        </Formik>

        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <RequestsAndSupportTable
              handleChangePage={handleChangePage}
              page={page}
              requests={realStateRequests}
            />
          </>
        )}
      </Layout >
    </>
  );
}
