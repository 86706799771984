import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableRow,
  TableBody,
  Button,
  Typography,
  TableContainer,
  TableCell,
  Paper,
  Pagination,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import Scrollbar from '../../../components/scrollbar';
import ClientReqHead from '../../../components/clientHeadTable/ClientHeadTable';

import { ReactComponent as EditIcon } from '../../../theme/images/editTableIcon.svg';

export default function ClientReqTable({ requests, handleChangePage, page, hasEditProjectPermission }) {
  const [rowsPerPage] = useState(1);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';

  const baseTableHead = [
    { id: 'employeeName', label: t('Employees.employeeName'), alignRight: isRtl && true },
    { id: 'jobTitle', label: t('Employees.jobTitle'), alignRight: isRtl && true },
    { id: 'directManager', label: t('Employees.directManager'), alignRight: isRtl && true },
    { id: 'userNumber', label: t('Employees.nationalId'), alignRight: isRtl && true },
    { id: 'birth', label: t('Employees.dateBirth'), alignRight: isRtl && true },
    { id: 'phone', label: t('Employees.phone'), alignRight: isRtl && true },
    { id: 'status', label: t('General.Status'), alignRight: isRtl && true },
    { id: 'action', label: t('General.Action'), alignRight: isRtl && true },
  ];

  const TABLE_HEAD = [...baseTableHead];
  return (
    <>
      <Scrollbar>
        <TableContainer component={Paper} sx={{ minWidth: 800 }}>
          <Table sx={{ direction: isRtl ? 'rtl' : 'ltr' }}>
            <ClientReqHead headLabel={TABLE_HEAD} />
            <TableBody>
              {requests?.content?.map((row) => {
                const {
                  phone,
                  id,
                  lastNameEn,
                  firstNameEn,
                  firstNameAr,
                  lastNameAr,
                  birthDate,
                  manager,
                  jobTitle,
                  status,
                  nationalId,
                } = row;

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {isRtl ? `${firstNameAr} ${lastNameAr}` : `${firstNameEn} ${lastNameEn}`}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {isRtl ? jobTitle?.nameAr : jobTitle?.nameEn || ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {isRtl
                          ? `${manager?.firstNameAr || '-------'} ${manager?.lastNameAr || '-------'}`
                          : `${manager?.firstNameEn || '-------'} ${manager?.lastNameEn || '-------'}`}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {nationalId}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {birthDate || ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {phone || ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {status === '1' ? (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '4px 16px',
                              backgroundColor: '#E7F7EF',
                              borderRadius: '8px',
                              width: '88px',
                            }}
                          >
                            <Typography
                              sx={{ color: '#0CAF60', fontWeight: '700', fontSize: '10px', lineHeight: '16px' }}
                              variant="subtitle2"
                              textAlign={'center'}
                              noWrap
                            >
                              {t('General.Active')}
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '4px 16px',
                              backgroundColor: '#FFEDEC',
                              borderRadius: '8px',
                              width: '88px',
                            }}
                          >
                            <Typography
                              sx={{ color: '#E03137', fontWeight: '700', fontSize: '10px', lineHeight: '16px' }}
                              variant="subtitle2"
                              textAlign={'center'}
                              noWrap
                            >
                              {t('General.InActive')}
                            </Typography>
                          </Box>
                        )}{' '}
                      </Typography>
                    </TableCell>
                    {hasEditProjectPermission && (
                      <TableCell align="left">
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                          <Button
                            onClick={() =>
                              navigate(id ? `/dashboard/admin/edit/${id}` : '/dashboard/admin/add', {
                                state: { editData: row },
                              })
                            }
                          >
                            <EditIcon />
                          </Button>
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {requests?.totalPages > 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            marginTop: '5rem',
          }}
        >
          <Pagination count={requests.totalPages} page={page + 1} onChange={handleChangePage} color="primary" />
          <Box sx={{ display: 'block' }}>
            {t('General.showing')} {page * rowsPerPage + 1} {t('General.of')}{' '}
            {Math.min((page + 1) * rowsPerPage, requests.totalElements)} {t('General.to')} {requests.totalElements}{' '}
            {t('General.entries')}
          </Box>
        </Box>
      )}
    </>
  );
}
