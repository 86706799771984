import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, List, ListItem, Typography, Box, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import { useTranslation } from 'react-i18next';
import { useNotification } from '../context/NotificationsContext';
import { getSeenNotification } from '../network/apis';

const NotificationList = ({ anchorEl, open, onClose }) => {
  const { t, i18n } = useTranslation();
  const { notifications, fetchNotifications, managerId, loading, handleSeeMore, numbersOfNotifications } =
    useNotification();
  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem('userId') || sessionStorage.getItem('userId'));
  const isRtl = i18n.language === 'ar'; // Check if the current language is Arabic

  const handleItemClick = async (notification) => {
    onClose();

    // Mark notification as seen
    if (notification?.seen === '0') {
      try {
        await getSeenNotification(notification.id);
        fetchNotifications();
      } catch (error) {
        toast.error(t('notification.failedMarkNotification'), error);
      }
    }

    // Navigate based on user role and notification type
    if (managerId !== userId) {
      // Admin case
      if (notification.flagType === 'UNIT') {
        if (notification.action === 'APPROVED' || notification.action === 'PENDING') {
          navigate(`/dashboard/units/view/${notification.flagId}`, { state: { from: 'review-products' } });
        } else if (notification.action === 'REJECTED') {
          navigate(`/dashboard/units/edit/${notification.flagId}`, {
            state: { from: 'notifications', requestId: notification.requestId },
          });
        }
      }

      if (
        (notification.action === 'APPROVED' || notification.action === 'PENDING') &&
        notification.flagType === 'LAND'
      ) {
        navigate(`/dashboard/land/view/${notification.flagId}`, { state: { from: 'review-products' } });
      }

      if (notification.action === 'REJECTED' && notification.flagType === 'LAND') {
        navigate(`/dashboard/land/edit/${notification.flagId}`, {
          state: { from: 'notifications', requestId: notification.requestId },
        });
      }
    } else {
      // Manager case
      if (notification.flagType === 'UNIT' && notification.action === 'PENDING') {
        navigate(`/dashboard/units/edit/${notification.flagId}`, {
          state: { from: 'review-products', requestId: notification.requestId },
        });
      }
      if (notification.flagType === 'LAND' && notification.action === 'PENDING') {
        navigate(`/dashboard/land/edit/${notification.flagId}`, {
          state: { from: 'review-products', requestId: notification.requestId },
        });
      }
      if (
        (notification.action === 'APPROVED' || notification.action === 'REJECTED') &&
        notification.flagType === 'UNIT'
      ) {
        navigate(`/dashboard/units/view/${notification.flagId}`, { state: { from: 'review-products' } });
      }
      if (
        (notification.action === 'APPROVED' || notification.action === 'REJECTED') &&
        notification.flagType === 'LAND'
      ) {
        navigate(`/dashboard/land/view/${notification.flagId}`, { state: { from: 'review-products' } });
      }
    }

    // Ownership Request
    if (notification.type === 'OWNER_REQUEST') {
      if (notification.flagType === 'LAND') {
        navigate(`/dashboard/ownership-requests/details/land/${notification.flagId}`, {
          state: { fromPage: 'notifications', requestId: notification.requestId, action: notification.action },
        });
      }
      if (notification.flagType === 'UNIT') {
        navigate(`/dashboard/ownership-requests/details/${notification.flagId}`, {
          state: { fromPage: 'notifications', requestId: notification.requestId, action: notification.action },
        });
      }
    }
    // Real State Requests
    if (notification.type === 'ASK_REQUEST') {
      if (notification.flagType === 'ASK_REQUEST') {
        navigate(`/dashboard/real-state-requests/${notification.flagId}`);
      }
    }
  };
  function getIconColor(type) {
    switch (type) {
      case 'APPROVED':
      case 'COMPLETED':
        return {
          color: '#0CAF60',
          backgroundColor: '#E7F7EF',
        };
      case 'REJECTED':
        return {
          color: '#E03137',
          backgroundColor: '#FFEDEC',
        };
      case 'PENDING':
      case 'ASSIGN':
        return {
          color: '#e6bb20',
          backgroundColor: '#fff6d3',
        };
      default:
        return {
          color: '#00a884',
          backgroundColor: '#dbf9ea',
        };
    }
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          maxHeight: 400,
          minWidth: '350px',
          direction: isRtl ? 'rtl' : 'ltr', // Adjust direction based on language
        },
      }}
    >
      <List sx={{ m: 1 }}>
        {notifications.length === 0 ? (
          <MenuItem>
            <Typography variant="body2" color="textSecondary">
              {t('notification.noNotifications')}
            </Typography>
          </MenuItem>
        ) : (
          notifications.map((notification, index) => {
            const [date, time] = notification?.creationDateString
              ? notification?.creationDateString?.split(', ')
              : ['', ''];

            return (
              <ListItem
                key={index}
                button
                onClick={() => handleItemClick(notification)}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  gap: 1,
                  padding: 1,
                  borderBottom: '1px solid #E9EAEC',
                  borderRadius: '15px',
                  margin: '3px 0',
                  width: '100%',
                  // backgroundColor: getBackgroundColor(notification),
                  '&:hover': {
                    backgroundColor: '#e0e0e0',
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    backgroundColor: getIconColor(notification?.action).backgroundColor,
                    color: getIconColor(notification?.action).color,
                  }}
                >
                  <DescriptionTwoToneIcon />
                </Box>
                <Box sx={{ display: 'flex', width: '95%', textAlign: isRtl ? 'right' : 'left' }}>
                  <Box display="flex" flex={2} flexDirection={'column'} gap={1}>
                    <Typography
                      component="span"
                      variant="body2"
                      color="#343537"
                      style={{ fontWeight: notification?.seen === '0' ? 'normal' : '400', fontSize: '14px' }}
                    >
                      {t('notification.requestNumber')} {notification?.id || ''}
                    </Typography>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textSecondary"
                      sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '12px' }}
                    >
                      {isRtl ? notification?.bodyAr : notification?.bodyEn || ''}
                    </Typography>
                  </Box>
                  <Box display="flex" flex={1} flexDirection={'column'} gap={1}>
                    <Typography component="span" variant="body2" color="textSecondary" fontSize={'14px'}>
                      {date || ''}
                    </Typography>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textSecondary"
                      sx={{ textOverflow: 'ellipsis', overflow: 'hidden', textWrap: 'nowrap', fontSize: '12px' }}
                    >
                      {time || ''} {notification?.duration || ''}
                    </Typography>
                  </Box>
                </Box>
              </ListItem>
            );
          })
        )}
        {loading && (
          <Box padding={2} display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{ width: '100%' }}>
            <CircularProgress style={{ width: '28px', height: '30px' }} />
          </Box>
        )}
      </List>

      {notifications.length > 0 && loading === false && notifications.length < numbersOfNotifications && (
        <Button onClick={handleSeeMore} sx={{ width: '100%' }}>
          {t('notification.seeMore')}
        </Button>
      )}
    </Menu>
  );
};

NotificationList.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default NotificationList;
