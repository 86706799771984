import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

// @mui
import { Stack, Button, Typography, Skeleton, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { hasPermission } from '../utils/permissions';
import { CityReqTable } from '../sections/@dashboard/Building';
import Layout from '../components/Layout/Layout';
import SearchComponet from '../components/search/Search';

import { getBuildingsList, deleteBuilding } from '../network/apis';
import DeleteFileModal from '../components/deleteFileModal/DeleteFileModal';

export default function Admins() {
  const [deleteData, setDeleteData] = useState({
    open: false,
    id: '',
  });
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const isRtl = i18n.language === 'ar';

  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [clientReqList, setClientReqList] = useState([]);
  const [search, setSearch] = useState('');

  const clientRequests = (page, search) => {
    setLoading(true);
    getBuildingsList(page, search)
      .then((res) => {
        setClientReqList(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching admin list:', error);
        // Display a toast message with a generic error message
        toast.error(error.response.data.MESSAGE);
        setLoading(false);
      });
  };

  useEffect(() => {
    clientRequests(page, search);
  }, [page, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleDelete = (id) => {
    deleteBuilding(id)
      .then((res) => {
        if (res.data.STATUS === 'FAILED' || res.data.STATUS === '0') {
          toast.error(res.data.MESSAGE);
        } else toast.success(res.data.MESSAGE);

        clientRequests(page, search);
      })
      .catch((error) => {
        console.log('Error deleting building:', error);
        toast.error(error.response.data.MESSAGE);
      });
  };

  const handleNavigate = () => {
    navigate('/dashboard/building/add');
  };

  const sideMenu = useSelector((state) => state.login?.sideMenu || []);
  const hasSearchPermission = hasPermission(sideMenu, '/dashboard/buildings', 'Search Building');
  const hasAddPermission = hasPermission(sideMenu, '/dashboard/buildings', 'Add Building');
  const hasEditPermission = hasPermission(sideMenu, '/dashboard/buildings', 'Update Building');
  const hasDeletePermission = hasPermission(sideMenu, '/dashboard/buildings', 'Delete Building');
  return (
    <>
      <Helmet>
        <title> {t('Building.manageBuilding')} </title>
      </Helmet>

      <Layout>
        <Grid marginBottom={1} display="flex" alignItems={'center'} justifyContent="space-between" md={6} xs={12}>
          <Typography sx={{ fontSize: '24px', fontWeight: '700', lineHeight: '32px', color: '#111827' }}>
            {t('Building.manageBuilding')}
          </Typography>
          {hasAddPermission && (
            <Button
              onClick={handleNavigate}
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '10px',
                padding: '21px 24px',
                width: '164px',
                height: '56px',
              }}
            >
              {' '}
              <Typography sx={{ display: 'flex', gap: 1, flexDirection: isRtl ? 'row-reverse' : 'row' }}>
                <span> + </span> <span>{t('Building.addBuilding')}</span>
              </Typography>
            </Button>
          )}
        </Grid>
        <Grid mb={2} rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {hasSearchPermission && (
            <Grid item xs={2} sm={4} md={4}>
              <SearchComponet label={t('Building.searchBuilding')} onSearch={setSearch} style={{ width: '100%' }} />
            </Grid>
          )}
        </Grid>
        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <CityReqTable
              handleChangePage={handleChangePage}
              page={page}
              requests={clientReqList}
              onDelete={(id) => setDeleteData({ open: true, id })}
              hasEditPermission={hasEditPermission}
              hasDeletePermission={hasDeletePermission}
            />
          </>
        )}
        <DeleteFileModal
          open={deleteData.open}
          handelClose={() => setDeleteData({ ...deleteData, open: false })}
          onDelete={() => {
            handleDelete(deleteData?.id);
          }}
        />
      </Layout>
    </>
  );
}
