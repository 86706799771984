import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// @mui
import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { ReactComponent as DropdownIcon } from '../theme/images/deopdownIcon.svg';

import { getUsers } from '../network/apis';
import SearchComponet from '../components/search/Search';

import UsersListTable from '../sections/@dashboard/userList/UsersListTable';

import Iconify from '../components/iconify';
import ShowUser from '../sections/@dashboard/userList/ShowUser';

// ----------------------------------------------------------------------

export default function Users() {
  const navigate = useNavigate();
  const [openShow, setOpenShow] = useState({
    open: false,
    userId: '',
  });
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [page, setPage] = useState(0);

  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const clientRequests = (page, search, status) => {
    setLoading(true);
    getUsers(page, search, status)
      .then((res) => {
        setUsersList(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    clientRequests(page, search, status);
  }, [page, search, status]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  const handleChange = (e) => {
    setStatus(e.target.value);
  };
  console.log(usersList?.content?.find((item) => item?.userId?.toString() === openShow?.userId?.toString()));
  return (
    <>
      <Helmet>
        <title>  {t('users.users')}</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack gap={2}>
          <Typography variant="h4" gutterBottom>
            {t('users.users')}
          </Typography>
          <Grid mb={2} rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={4} md={4}>
              <SearchComponet onSearch={setSearch} style={{ width: '100%' }} />
            </Grid>

            <Grid item xs={2} sm={4} md={4}>
              <Grid item xs={2} sm={4} md={6}>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    value={status}
                    sx={{
                      paddingX: '10px',
                      borderRadius: '10px',
                      flexDirection: 'row',
                      justifyContent: 'start',
                    }}
                    renderValue={(selected) => {
                      if (selected === '') {
                        return <span> {t('General.select')} </span>;
                      }
                      if (selected === '1') return t('users.verfied');
                      if (selected === '0') return t('users.unverfied');
                      return <span style={{ color: '#9e9e9e' }}>{t('General.AllStatus')}</span>;
                    }}
                    IconComponent={() => <DropdownIcon style={{ width: '15px', height: '15px' }} />}
                    labelId="Status"
                    id="status"
                    onChange={handleChange}
                  >
                    <MenuItem value="">{t('General.AllStatus')}</MenuItem>
                    <MenuItem value="1">{t('users.verfied')}</MenuItem>
                    <MenuItem value="0">{t('users.unverfied')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Stack>

        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <Card>
            <UsersListTable
              handleChangePage={handleChangePage}
              page={page}
              usersList={usersList}
              setShow={setOpenShow}
            />
          </Card>
        )}
        <ShowUser
          open={openShow.open}
          handleClose={() => setOpenShow((prev) => ({ ...prev, open: false }))}
          data={usersList?.content?.find((item) => item?.userId?.toString() === openShow?.userId?.toString())}
        />
      </Container>
    </>
  );
}
