import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
// @mui
import { useTranslation } from "react-i18next";
import { Stack, Button, Skeleton, Grid } from "@mui/material";
import CityReqTable from "../sections/@dashboard/askRequests/DataTable";
import SearchComponet from "../components/search/Search";

import { getAskRequestsList, deleteAskRequest } from "../network/apis";
import AddOrEditModal from "../sections/@dashboard/askRequests/Modal";
import ConfirmationModal from "../components/confirmationModal/ConfirmationModal";

export default function ResponseAndLimites() {
  const { t } = useTranslation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [clientReqList, setClientReqList] = useState([]);
  const [search, setSearch] = useState("");
  const [editData, setEditData] = useState({});
  const [mode, setMode] = useState("add");

  const clientRequests = (page, search) => {
    setLoading(true);
    getAskRequestsList(page, search)
      .then((res) => {
        setClientReqList(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    clientRequests(page, search);
  }, [page, search]);

  const handleChangePage = (__, newPage) => {
    setPage(newPage);
  };

  const handleDelete = (id) => {
    deleteAskRequest(id)
      .then((res) => {
        if (res.data.STATUS === "FAILED") toast.error(res.data.MESSAGE);
        else {
          clientRequests(page, search);
          setOpenDeleteModal(false);
          toast.success(res.data.MESSAGE);
        }
      })
      .catch((err) => toast.error(err.res.data.MESSAGE));
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    clientRequests(page, search);
  };
  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid justifyContent="start" display="flex" item xs={2} sm={4} md={6}>
          <SearchComponet onSearch={setSearch} />
        </Grid>
        <Grid justifyContent="end" display="flex" item xs={2} sm={4} md={6}>
          <Button
            onClick={() => {
              setMode("add");
              setModalOpen(true);
            }}
            style={{
              backgroundColor: "black",
              color: "white",
              borderRadius: "10px",
              padding: "21px 24px",
              width: "fit-content",
              height: "56px",
              marginBottom: "15px",
            }}
          >
            {" "}
            + {t("response_templates.add_ask_requests")}
          </Button>
        </Grid>
      </Grid>
      {loading ? (
        <Stack>
          <Skeleton height={100} />
          <Skeleton height={100} />
          <Skeleton height={100} />
          <Skeleton height={100} />
          <Skeleton height={100} />
          <Skeleton height={100} />
        </Stack>
      ) : (
        <CityReqTable
          handleChangePage={handleChangePage}
          page={page}
          requests={clientReqList}
          onDelete={(id) => {
            setDeletedId(id);
            setOpenDeleteModal(true);
          }}
          refreshApi={clientRequests}
          onEdit={(e) => {
            setMode("edit");
            setModalOpen(true);
            setEditData(e);
          }}
        />
      )}
      {isModalOpen && (
        <AddOrEditModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          data={editData}
          refreshApi={clientRequests}
          mode={mode}
        />
      )}
      {openDeleteModal && (
        <ConfirmationModal
          handelClose={() => setOpenDeleteModal(false)}
          onUpdate={() => handleDelete(deletedId)}
          open={openDeleteModal}
        />
      )}
    </>
  );
}
