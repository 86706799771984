import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LoadingOverlay from '../components/loading-overlay/LoadingOverlay';
import { addLand, deleteAttachments, deletePictures, deleteSketch, editLand, getLandsDetials } from '../network/apis';

const PlanFormContext = createContext();

export const usePlanFormContext = () => useContext(PlanFormContext);

export const PlanFromProvider = ({ children }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isClone = location.state?.isClone;

  const currentPath = location.pathname;
  const [editData, setEditData] = useState({});
  const [currentTab, setCurrentTab] = useState('1');
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);
  const [uploadedFilesUploaded, setUploadedFilesUploaded] = useState({
    landSketch: [],
    primaryPhoto: [],
    virtualTour: [],
    attachments: [],
    landImages: [],
  });
  const formDetails = useSelector((state) => state?.land?.formData);

  const isEditMode = currentPath.includes('edit');
  const isViewMode = currentPath.includes('view');
  const fromPage = location.state?.from;

  const fetchUnitDetails = async () => {
    try {
      const { data } = await getLandsDetials(id);
      setEditData(data);
    } catch (error) {
      console.error('Failed to fetch land details:', error);
      toast.error('Failed to fetch land details');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      fetchUnitDetails();
    } else {
      setLoading(false);
    }
  }, [id]);

  const handleDelete = async (fileId, fieldName) => {
    let response;
    if (fieldName === 'attachment') {
      response = await deleteAttachments(fileId);
    } else if (fieldName === 'sketch') {
      response = await deleteSketch(fileId);
    } else {
      response = await deletePictures(fileId);
    }
    if (response?.data?.STATUS === 'SUCCESS') {
      toast.success(response?.data?.MESSAGE);
    } else {
      toast.error(response?.data?.MESSAGE);
    }
  };

  // Add Item FunctionCopy code
  function addItem(fieldName, items, acceptMultiple = true) {
    // Initialize the field if it doesn't exist
    if (!uploadedFilesUploaded[fieldName]) {
      uploadedFilesUploaded[fieldName] = [];
    }

    if (acceptMultiple) {
      // If multiple items are allowed, add items if they don't exist
      items.forEach((item) => {
        if (!uploadedFilesUploaded[fieldName].includes(item)) {
          uploadedFilesUploaded[fieldName].push(item);
        }
      });
    } else {
      // If multiple items are not allowed, replace the existing item
      uploadedFilesUploaded[fieldName] = [items[0]];
    }

    return uploadedFilesUploaded;
  }

  // Delete Item Function
  function deleteItem(fieldName, item) {
    if (uploadedFilesUploaded[fieldName]) {
      setUploadedFilesUploaded((prevUploadedFilesUploaded) => {
        const newUploadedFilesUploaded = { ...prevUploadedFilesUploaded };
        newUploadedFilesUploaded[fieldName] = newUploadedFilesUploaded[fieldName].filter((i) => i?.id !== item);
        return newUploadedFilesUploaded;
      });
    }
    return uploadedFilesUploaded;
  }

  const { requestId, userId, managerId, isManager } = location.state || '';

  const handleChange = (newValue) => {
    if (isEditMode || isViewMode) setCurrentTab(newValue);
  };

  const handleNext = () => {
    setCurrentTab((prevValue) => {
      const nextValue = parseInt(prevValue, 10) + 1;
      return nextValue > 3 ? '1' : nextValue.toString();
    });
  };

  const handlePrev = () => {
    setCurrentTab((prevValue) => {
      const prevValueInt = parseInt(prevValue, 10) - 1;
      return prevValueInt < 1 ? '2' : prevValueInt.toString();
    });
  };
  const handleSubmit = (event) => {
    const addedLand = {
      nameEn: formDetails?.nameEn,
      nameAr: formDetails?.nameAr,
      type: formDetails?.type,
      area: formDetails?.area,
      facade: formDetails?.facade || '',
      northernBoardEn: formDetails?.northernBoardEn || '',
      northernBoardAr: formDetails?.northernBoardAr || '',
      southernBoardEn: formDetails?.southernBoardEn || '',
      southernBoardAr: formDetails?.southernBoardAr || '',
      westernBoardEn: formDetails?.westernBoardEn || '',
      westernBoardAr: formDetails?.westernBoardAr || '',
      easternBoardEn: formDetails?.easternBoardEn || '',
      easternBoardAr: formDetails?.easternBoardAr || '',
      purpose: formDetails?.purpose,
      states: formDetails?.states,
      piecePrice: formDetails?.piecePrice,
      realEstateTax: formDetails?.realEstateTax,
      commissionFees: formDetails?.commissionFees,
      commissionFeesVat: formDetails?.commissionFeesVat,
      ownershipFees: formDetails?.ownershipFees,
      meterPrice: formDetails?.meterPrice,
      totalPrice: formDetails?.totalPrice,
      whatsappNumber: formDetails?.whatsappNumber,
      email: formDetails?.email,
      mobile: formDetails?.mobile,
      pieceDataStatus: formDetails?.pieceDataStatus,
      pieceStatus: formDetails?.pieceStatus,
      reservationStatus: formDetails?.reservationStatus,
      blockNumber: formDetails?.blockNumber,
      planId: formDetails?.planDetails?.id || formDetails?.plan?.id,
      totalPriceNoVat: formDetails?.totalPriceNoVat,
      virtualType: formDetails.virtualType ? 'IMAGE' : 'LINK',
      virtualLink: formDetails?.virtualLink || '',
      landSketch: uploadedFilesUploaded?.landSketch?.map((item) => item.id),
      primaryPhoto: uploadedFilesUploaded?.primaryPhoto?.map((item) => item.id),
      virtualTour: uploadedFilesUploaded?.virtualTour?.map((item) => item.id),
      attachments: uploadedFilesUploaded?.attachments?.map((item) => item.id),
      pictures: uploadedFilesUploaded?.landImages?.map((item) => item.id),
      isClone: isClone ? 1 : 0,
    };
    setLoading(true);

    if (isEditMode) {
      editLand({ ...addedLand, id, requestId: requestId || '' })
        .then((response) => {
          setLoading(false);
          if (response.data.STATUS === 'SUCCESS') {
            toast.success(response.data.MESSAGE || 'The Land is successfully updated');

            if (fromPage === 'review-products') {
              navigate('/dashboard/review-products');
            } else {
              navigate(`/dashboard/lands`);
            }
          } else if (response.data.STATUS === 'FAILED') {
            toast.error(response.data.MESSAGE);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.MESSAGE);
        });
    } else {
      addLand(addedLand)
        .then((response) => {
          setLoading(false);
          if (response.data.STATUS === 'SUCCESS') {
            toast.success(response.data.MESSAGE);
            if (fromPage === 'review-products') {
              navigate('/dashboard/review-products');
            } else {
              navigate(id ? `/dashboard/lands/edit/${id}` : `/dashboard/lands`);
            }
          } else if (response.data.STATUS === 'FAILED') {
            toast.error(response.data.MESSAGE);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error?.response) toast.error(error?.response?.data?.MESSAGE);
          else {
            toast.error('Please check your network');
          }
        });
    }
  };

  return (
    <PlanFormContext.Provider
      value={{
        currentTab,
        setCurrentTab,
        handleChange,
        handleNext,
        handlePrev,
        handleSubmit,
        handleDelete,
        uploadedFilesUploaded,
        editData,
        loading,
        setEditData,
        fromPage,
        requestId,
        userId,
        managerId,
        isManager,
        addItem,
        deleteItem,
        refetch,
        setRefetch,
        isEditMode,
        isViewMode,
      }}
    >
      {loading ? <p>{<LoadingOverlay />}</p> : children}
    </PlanFormContext.Provider>
  );
};
