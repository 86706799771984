import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import { Formik } from "formik";

import { toast } from "react-toastify";

// @mui
import {
    Stack,
    Typography,
    Grid,
    Button,
    TextField,

} from "@mui/material";

import { useTranslation } from "react-i18next";
import Layout from "../components/Layout/Layout";

import {
    getRequestsConfigrationList,
    updateRequestsConfigration
} from "../network/apis";


export default function ManageRealStateRequests() {
    const { t } = useTranslation();
    const [requestsConfig, setRequestsConfig] = useState([]);

    const getRequestsConfigrationListApi = () => {
        getRequestsConfigrationList()
            .then((res) => {
                console.log(res.data)
                setRequestsConfig(res.data?.REQUEST_CONFIG);
            })
    };
    useEffect(() => {
        getRequestsConfigrationListApi(0, '');
    }, []);
    const initialValues = requestsConfig?.reduce((acc, config) => {
        acc[config.code] = config.value;
        return acc;
    }, {});

    return (
        <>
            <Helmet>
                <title> {t("requestsAndSupport.requestsAndSupport")} </title>
            </Helmet>

            <Layout>
                {requestsConfig.length > 0 && (<Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        console.log(values)
                        updateRequestsConfigration(values).then((res) => {
                            toast.success(res.data.MESSAGE)
                            getRequestsConfigrationListApi(0, "")
                        });
                    }}
                >
                    {({ values, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                {requestsConfig.map((config) => (
                                    <Grid item xs={12} sm={6} key={config.id}>
                                        <Typography variant="body1" gutterBottom>
                                            {config.label}
                                        </Typography>
                                        <TextField
                                            name={config.code}
                                            value={values[config.code]}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                ))}

                                {/* Submit Button */}
                                <Grid item xs={12}>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Button variant="contained" color="primary" type="submit">
                                            {t("RquestsAndConfigratrions.update")}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>
                    )}

                </Formik>
                )}

            </Layout>
        </>
    );
}
