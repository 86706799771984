import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableRow,
  TableBody,
  Button,
  Typography,
  TableContainer,
  TableCell,
  Paper,
  Pagination,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import Scrollbar from "../../../components/scrollbar";
import ClientReqHead from "../../../components/clientHeadTable/ClientHeadTable";

import { ReactComponent as EditIcon } from "../../../theme/images/assignIcon.svg";

export default function ClientReqTable({ requests, handleChangePage, page }) {
  const [rowsPerPage] = useState(1);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";

  const baseTableHead = [
    {
      id: "employeeName",
      label: t("Employees.employeeName"),
      alignRight: isRtl && true,
    },

    {
      id: "userNumber",
      label: t("Employees.nationalId"),
      alignRight: isRtl && true,
    },
    { id: "birth", label: t("Employees.dateBirth"), alignRight: isRtl && true },
    { id: "phone", label: t("Employees.phone"), alignRight: isRtl && true },
    { id: "action", label: t("General.Action"), alignRight: isRtl && true },
  ];

  const TABLE_HEAD = [...baseTableHead];
  return (
    <>
      <Scrollbar>
        <TableContainer component={Paper} sx={{ minWidth: 800 }}>
          <Table sx={{ direction: isRtl ? "rtl" : "ltr" }}>
            <ClientReqHead headLabel={TABLE_HEAD} />
            <TableBody>
              {requests?.map((row) => {
                const {
                  phone,
                  id,
                  lastNameEn,
                  firstNameEn,
                  firstNameAr,
                  lastNameAr,
                  birthDate,
                  nationalId,
                } = row;
                console.log(row);
                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {isRtl
                          ? `${firstNameAr} ${lastNameAr}`
                          : `${firstNameEn} ${lastNameEn}`}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {nationalId}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {birthDate || ""}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {phone || ""}
                      </Typography>
                    </TableCell>

                    <TableCell align="left">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "2px",
                        }}
                      >
                        <Button
                          onClick={() =>
                            navigate(
                              id && `/dashboard/manage-candidates/assign/${id}`,
                              {
                                state: { editData: row },
                              }
                            )
                          }
                        >
                          <EditIcon />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {requests?.totalPages > 0 && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            marginTop: "5rem",
          }}
        >
          <Pagination
            count={requests.totalPages}
            page={page + 1}
            onChange={handleChangePage}
            color="primary"
          />
          <Box sx={{ display: "block" }}>
            {t("General.showing")} {page * rowsPerPage + 1} {t("General.of")}{" "}
            {Math.min((page + 1) * rowsPerPage, requests.totalElements)}{" "}
            {t("General.to")} {requests.totalElements} {t("General.entries")}
          </Box>
        </Box>
      )}
    </>
  );
}
