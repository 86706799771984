import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Grid, Box, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

import { addInterfacesRequest } from '../../../network/apis';

export default function RegionForm({ handleClose }) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const validationSchema = yup.object({
    nameEn: yup
      .string()
      .required(t('Validation.requiredField'))
      .test('is-english', t('Validation.englishReq'), (value) => {
        // Regular expression to match English characters
        const englishRegex = /^[A-Za-z0-9_\s]*$/;
        return englishRegex.test(value);
      }),

    nameAr: yup
      .string()
      .required(t('Validation.requiredField'))
      .test('is-arabic', t('Validation.arabicReq'), (value) => {
        // Regular expression to match Arabic characters
        const arabicRegex = /[\u0600-\u06FF]/;
        // Regular expression to match English characters
        const englishRegex = /[A-Za-z0-9]/;
        // Check if the value contains both Arabic and English characters
        return arabicRegex.test(value) && !englishRegex.test(value);
      }),
  });

  return (
    <Formik
      initialValues={{
        nameEn: '',
        nameAr: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setLoading(true);

        addInterfacesRequest(values)
          .then((response) => {
            setLoading(false);
            if (response.data.STATUS === 'FAILED' || response.data.STATUS === '0') {
              toast.error(response.data.MESSAGE);
            } else {
              toast.success(response.data.MESSAGE);
              handleClose(); // Only close the modal if the status is not '0'
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err.response.data.MESSAGE);
          });
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm }) => (
        <form onSubmit={handleSubmit}>
          <Grid rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('General.nameEn')}
              </Typography>
              <TextField
                name="nameEn"
                required
                fullWidth
                value={values.nameEn}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.nameEn)}
                helperText={touched.nameEn && errors.nameEn}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('General.nameAr')}
              </Typography>
              <TextField
                name="nameAr"
                required
                fullWidth
                value={values.nameAr}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.nameAr && Boolean(errors.nameAr)}
                helperText={
                  touched.nameAr &&
                  errors.nameAr && <span dangerouslySetInnerHTML={{ __html: errors.nameAr.replace('\n', '<br />') }} />
                }
              />
            </Grid>
            <></>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingX: '20px',
              }}
            >
              {' '}
              <Button
                sx={{ mt: 4, border: '1px solid #111827' }}
                size="large"
                onClick={handleClose}
                type="button"
                variant=""
              >
                {t('General.cancel')}
              </Button>
              <LoadingButton sx={{ mt: 4 }} loading={loading} size="large" type="submit" variant="contained">
                {t('General.save')}
              </LoadingButton>
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
