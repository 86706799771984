import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useUnitFormContext } from '../context/unitFormContext';
import StyledTabs from '../components/StyledTabs/StyledTabs';
import { createApproveWFRequest, createRejectWFRequest, getUnitsDetials } from '../network/apis';
import Layout from '../components/Layout/Layout';
import FinancialDetailsFormTab from '../sections/@dashboard/unitFormTabs/financialDetailsFormTab';
import UnitDataFormTab from '../sections/@dashboard/unitFormTabs/unitDataFormTab';

const ManageUnits = () => {
  const {
    handleSubmit,
    uploadedFiles,
    handleUpload,
    detailsData,
    isEditMode,
    setUploadedFiles,
    handleDelete,
    handleDeleteAttachments,
    viewMode,
    requestId,
    userId,
    managerId,
    isManager,
    loading_submit,
    addItem,
    deleteItem,
    editItem,
    refetch,
    setRefetch,
    uploadedFilesUploaded,
  } = useUnitFormContext();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState('1');
  const location = useLocation();
  const fromPage = location.state?.from;

  const handleChange = (newValue) => {
    if (isEditMode) setCurrentTab(newValue);
  };
  const handleNextTab = () => {
    setCurrentTab((prevValue) => {
      const nextValue = parseInt(prevValue, 10) + 1;
      return nextValue > 3 ? '1' : nextValue.toString();
    });
  };

  const handlePrevTab = () => {
    setCurrentTab((prevValue) => {
      const prevValueInt = parseInt(prevValue, 10) - 1;
      return prevValueInt < 1 ? '3' : prevValueInt.toString();
    });
  };

  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const handleApprove = async (row) => {
    const requestData = {
      REQUEST_ID: requestId,
      COMMENT: 'APPROVED',
    };

    try {
      const response = await createApproveWFRequest(requestData);
      if (response.data.STATUS === 'FAILED' || response.data.STATUS === '0') toast.error(response.data.MESSAGE);
      else toast.success(response.data.MESSAGE);
      navigate('/dashboard/review-products');
    } catch (error) {
      toast.error(error?.response.data.MESSAGE);
    }
  };

  const handleOpenRejectDialog = (row) => {
    // setSelectedRow(row);
    setOpenRejectDialog(true);
  };

  const handleCloseRejectDialog = () => {
    setOpenRejectDialog(false);
    navigate('/dashboard/review-products');
    setRejectReason('');
  };

  const handleReject = async () => {
    const requestData = {
      REQUEST_ID: requestId,
      COMMENT: rejectReason,
    };

    try {
      const response = await createRejectWFRequest(requestData);
      if (response.data.STATUS === 'FAILED' || response.data.STATUS === '0') toast.error(response.data.MESSAGE);
      else toast.success(response.data.MESSAGE);
      navigate('/dashboard/review-products');
      handleCloseRejectDialog();
    } catch (error) {
      toast.error(error?.response.data.MESSAGE);
      handleCloseRejectDialog();
    }
  };

  return (
    <Layout>
      <Typography variant="h4" gutterBottom>
        {t('Units.newUnit')}
      </Typography>
      <TabContext value={currentTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
          }}
        >
          <StyledTabs
            value={currentTab}
            onChange={handleChange}
            tabs={[
              {
                label: t('Units.unitsData'),
                value: '1',
              },
              { label: t('Units.financialData'), value: '2' },
            ]}
          />
        </Box>

        <TabPanel value="1">
          <UnitDataFormTab
            loading_submit={loading_submit}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            handleDelete={handleDelete}
            handleDeleteAttachments={handleDeleteAttachments}
            editData={detailsData && detailsData.data}
            onUpload={handleUpload}
            handleNext={handleNextTab}
            isEditMode={isEditMode}
            viewMode={viewMode}
            fromPage={fromPage}
            addItem={addItem}
            editItem={editItem}
            deleteItem={deleteItem}
            refetch={refetch}
            uploadedFilesUploaded={uploadedFilesUploaded}
            setRefetch={setRefetch}
          />
        </TabPanel>
        <TabPanel value="2">
          <FinancialDetailsFormTab
            t={t}
            handleNext={handleNextTab}
            submitFormValues={handleSubmit}
            handlePrev={handlePrevTab}
            viewMode={viewMode}
            userId={userId}
            isManager={isManager}
            managerId={managerId}
            fromPage={fromPage}
            handleApprove={handleApprove}
            loading_submit={loading_submit}
            open={openRejectDialog}
            onClose={handleCloseRejectDialog}
            onReject={handleReject}
            rejectReason={rejectReason}
            setRejectReason={setRejectReason}
            handleOpenRejectDialog={handleOpenRejectDialog}
          />
        </TabPanel>
      </TabContext>
    </Layout>
  );
};

export default ManageUnits;
