import * as React from "react";
import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import {
  Grid,
  Typography,
  Button,
  Stack,
  CircularProgress,
  MenuItem,
  FormControl,
  Select,
  Box,
} from "@mui/material";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/Modal";
import {
  updateRealStateRequest,
  getAskTemplateList,
} from "../../../network/apis";
import { ReactComponent as DropdownIcon } from "../../../theme/images/deopdownIcon.svg";

export default function ReplyModal({
  open,
  handleClose,
  id,
  realStateRequestApi,
}) {
  const [list, setList] = useState([
    {
      id: 1,
      labelEn: "Email",
      labelAr: "البريد الإلكتروني",
      value: "EMAIL",
    },
    {
      id: 2,
      labelEn: "Phone",
      labelAr: "الهاتف",
      value: "PHONE",
    },
  ]);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getList = () => {
      getAskTemplateList().then((res) => {
        const newList = res?.data?.content?.map((data) => ({
          id: data.id,
          labelEn: data.nameEn,
          labelAr: data.nameAr,
          value: data.id,
        }));

        // Add an additional object
        const additionalObject = {
          id: "-1",
          labelEn: "Other",
          labelAr: "اخري",
          value: "-1",
        };

        // Update the state with the combined list
        setList([...newList, additionalObject]);
      });
    };
    getList();
  }, []);
  const handleReply = async (values) => {
    setLoading(true);
    const res = await updateRealStateRequest({
      id,
      status: "COMPLETED",
      comment: values.comment,
      contactedBy: values.method,
    });
    if (res?.data?.STATUS === "SUCCESS") {
      toast.success(res?.data?.MESSAGE);
      realStateRequestApi(id);
      navigate(-1);
      handleClose();
      setLoading(false);
    } else {
      toast.error(res?.data?.MESSAGE);
      setLoading(false);
    }
  };
  return (
    <Modal
      handleClose={handleClose}
      open={open}
      title={t("realStateRequests.reply")}
      width={"40%"}
    >
      <Formik
        initialValues={{
          method: "",
          comment: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleReply(values);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          setFieldValue,
        }) => {
          return (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Grid
                  padding={"20px"}
                  container
                  spacing={{ xs: 2, md: 2 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{
                    height: "100%",
                    padding: "20px",
                    borderBottom: "1px solid #E5E5E5",
                  }}
                >
                  <Grid item xs={2} sm={4} md={12}>
                    <Typography variant="body1" gutterBottom>
                      {t("realStateRequests.method_of_reply")}
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        value={values.value}
                        sx={{
                          paddingX: "10px",
                          borderRadius: "10px",
                          flexDirection: "row",
                          justifyContent: "start",
                        }}
                        renderValue={(selected) => {
                          const item = list?.find(
                            (item) => item.value === selected
                          );
                          return (
                            <Box display={"flex"} alignItems={"center"} gap={2}>
                              <Typography
                                margin={"0"}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                {isRtl
                                  ? item?.labelAr ||
                                    t("realStateRequests.choose")
                                  : item?.labelEn ||
                                    t("realStateRequests.choose")}
                              </Typography>
                            </Box>
                          );
                        }}
                        IconComponent={() => (
                          <DropdownIcon
                            style={{ width: "15px", height: "15px" }}
                          />
                        )}
                        labelId="Status"
                        id="status"
                        onChange={(e) =>
                          setFieldValue("method", e.target.value)
                        }
                      >
                        {list?.map((item, index) => (
                          <MenuItem key={index} value={item?.value}>
                            <Typography
                              margin={"0"}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              {isRtl ? item?.labelAr : item?.labelEn}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>{" "}
                  {values.method === "-1" && (
                    <Grid item xs={2} sm={4} md={12}>
                      <Typography variant="body1" gutterBottom>
                        {t("realStateRequests.comment")}
                      </Typography>
                      <TextField
                        name="comment"
                        multiline
                        rows={2}
                        required
                        fullWidth
                        value={values.comment}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.comment && Boolean(errors.comment)}
                        helperText={touched.comment && errors.comment}
                      />
                    </Grid>
                  )}
                </Grid>
                <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  justifyContent="end"
                  alignItems="end"
                  gap={2}
                >
                  <Button
                    sx={{ mt: 4, border: "1px solid #111827" }}
                    size="large"
                    type="button"
                    onClick={handleClose}
                  >
                    {" "}
                    {t("General.cancel")}
                  </Button>
                  <Button
                    sx={{ mt: 4, border: "1px solid #111827" }}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {" "}
                    {loading ? <CircularProgress /> : t("General.submit")}
                    {}
                  </Button>
                </Stack>
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
}
