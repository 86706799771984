import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
// @mui
import { Stack, Button, Typography, Skeleton, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout/Layout";
import SearchComponet from "../components/search/Search";
import { hasPermission } from "../utils/permissions";
import { getOurOffersList, deleteOurOffer } from "../network/apis";
import OurOffersTable from "../components/ourOffersTable/OurOffersTable";
import AddOurOffersModal from "../components/addAndEditOurOffers/AddOurOffers";
import EditOurOfferModal from "../components/addAndEditOurOffers/EditOurOffers";

export default function OurServices() {
  const [editOrView, setEdit] = useState({
    id: "",
    open: false,
    viewMode: false,
  });
  const { t, i18n } = useTranslation();
  const [openAddModal, setAddOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [ourOffersList, setOurOffersList] = useState([]);

  const ourOffersListApi = (page, search) => {
    setLoading(true);
    getOurOffersList(page, search)
      .then((res) => {
        setOurOffersList(res.data.content);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    ourOffersListApi(page, search);
  }, [page, search]);

  const handleChangePage = (__, newPage) => {
    setPage(newPage - 1);
  };

  const handleDelete = (id) => {
    deleteOurOffer(id)
      .then((res) => {
        if (res.data.STATUS === "FAILED" || res.data.STATUS === "0") {
          toast.error(res.data.MESSAGE);
        } else toast.success(res.data.MESSAGE);
        // Reload the cities list
        ourOffersListApi(page, search);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data.MESSAGE);
      });
  };

  const handleOpenModal = () => {
    setAddOpenModal(true);
  };

  const sideMenu = useSelector((state) => state.login?.sideMenu || []);
  //   const hasSearchPermission = hasPermission(
  //     sideMenu,
  //     "/dashboard/plans",
  //     "Search For our services"
  //   );
  //   const hasAddPermission = hasPermission(
  //     sideMenu,
  //     "/dashboard/plans",
  //     "Add our services"
  //   );
  //   const hasEditPermission = hasPermission(
  //     sideMenu,
  //     "/dashboard/plans",
  //     "edit our services"
  //   );
  const hasSearchPermission = true;
  const hasAddPermission = true;
  const hasEditPermission = true;
  const hasDeletePermission = true;
  return (
    <>
      <Helmet>
        <title> {t("ourOffers.ourOffers")} </title>
      </Helmet>

      <Layout>
        <Grid
          display="flex"
          alignItems={"center"}
          marginBottom={1}
          justifyContent="space-between"
          md={6}
          xs={12}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "32px",
              color: "#111827",
            }}
          >
            {t("ourOffers.ourOffers")}
          </Typography>
          {hasAddPermission && (
            <Button
              onClick={handleOpenModal}
              style={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "10px",
                padding: "21px 24px",
                width: "164px",
                height: "56px",
              }}
            >
              <Typography sx={{ display: "flex", gap: 1 }}>
                <span> + </span> <span>{t("ourOffers.addOurOffers")}</span>
              </Typography>
            </Button>
          )}
        </Grid>
        <Grid
          mb={2}
          rowSpacing={1}
          container
          spacing={{ xs: 2, md: 1 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {hasSearchPermission && (
            <Grid item xs={2} sm={4} md={4}>
              <SearchComponet onSearch={setSearch} style={{ width: "100%" }} />
            </Grid>
          )}
        </Grid>
        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <>
              <OurOffersTable
                handleChangePage={handleChangePage}
                page={page}
                requests={ourOffersList}
                onDelete={handleDelete}
                hasEditPermission={hasEditPermission}
                hasDeletePermission={hasDeletePermission}
                setEdit={setEdit}
              />
            </>
          </>
        )}
        <AddOurOffersModal
          open={openAddModal}
          handleClose={() => setAddOpenModal(false)}
          ourOffersListApi={ourOffersListApi}
        />{" "}
        <EditOurOfferModal
          open={editOrView.open}
          isViewMode={editOrView.viewMode}
          handleClose={() => setEdit((prev) => ({ ...prev, open: false }))}
          ourOffersListApi={ourOffersListApi}
          data={ourOffersList.find(
            (item) => item.id.toString() === editOrView.id.toString()
          )}
        />
        <ToastContainer />
      </Layout>
    </>
  );
}
