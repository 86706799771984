import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
// @mui
import {
  Stack,
  Typography,
  Skeleton,
  Grid,
  Select,
  MenuItem,
  FormControl,
  SvgIcon,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CityReqTable from "../sections/@dashboard/candidiates/ClientReqTable";
import Layout from "../components/Layout/Layout";
import SearchComponet from "../components/search/Search";

import { getCandidatesList } from "../network/apis";
import { hasPermission } from "../utils/permissions";

export default function Admins() {
  const { i18n, t } = useTranslation();
  const isRtl = i18n.language === "ar";

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [clientReqList, setClientReqList] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setChangeStatus] = useState("");

  const handleChange = (e) => {
    setChangeStatus(e.target.value);
  };

  const clientRequests = (page, search, status) => {
    setLoading(true);
    getCandidatesList(page, search, status)
      .then((res) => {
        setClientReqList(res?.data?.content);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching admin list:", error);
        // Display a toast message with a generic error message
        toast.error(error?.response?.data?.MESSAGE);
        setLoading(false);
      });
  };

  useEffect(() => {
    clientRequests(page, search, status);
  }, [page, search, status]);

  const handleChangePage = (__, newPage) => {
    setPage(newPage - 1);
  };

  const CustomArrowIcon = ({ isRtl }) => (
    <SvgIcon style={{ transform: "rotate(-360deg)", color: "#637381" }}>
      <path d="M7 10l5 5 5-5z" />
    </SvgIcon>
  );

  const sideMenu = useSelector((state) => state.login?.sideMenu || []);
  const hasSearchAdmintPermission = hasPermission(
    sideMenu,
    "/dashboard/manage-employees",
    "Search For Admin"
  );
  return (
    <>
      <Helmet>
        <title> {t("admins.admins")}</title>
      </Helmet>

      <Layout>
        <Grid
          marginY={2}
          rowSpacing={2}
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {hasSearchAdmintPermission && (
            <Grid item xs={2} sm={4} md={4}>
              <SearchComponet onSearch={setSearch} style={{ width: "100%" }} />
            </Grid>
          )}
        </Grid>
        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <CityReqTable
              handleChangePage={handleChangePage}
              page={page}
              requests={clientReqList}
            />
          </>
        )}
      </Layout>
    </>
  );
}
