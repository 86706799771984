import React, { useState, useEffect } from "react";
import { toast } from "react-toastify"; // Assuming toast is from react-toastify

import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams, Navigate } from "react-router-dom";
import { axiosInstance } from "./network/config";
import { setLoggedIn, setSideMenu } from "./Store/features/Login/loginSlice";

const InitSession = () => {
  const [getParams] = useSearchParams();
  getParams.get("token");
  console.log(getParams.get("token"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Manage loading state

  const createSession = async () => {
    localStorage.setItem("userToken", JSON.stringify(getParams.get("token")));

    try {
      const res = await axiosInstance.post("/authenticate/initSession");
      const { TOKEN, SIDE_MENU, USER } = res.data;
      console.log(res);

      if (TOKEN && SIDE_MENU) {
        // Store session data in localStorage
        localStorage.setItem("userToken", JSON.stringify(TOKEN));
        localStorage.setItem("userId", JSON.stringify(USER.id));
        localStorage.setItem("user", JSON.stringify(USER));
        localStorage.setItem("isManager", JSON.stringify(USER.isManager || ""));
        localStorage.setItem("dashboardSideMenu", JSON.stringify(SIDE_MENU));

        // Dispatch to Redux store
        dispatch(setLoggedIn(true));
        dispatch(setSideMenu(SIDE_MENU));

        navigate("/dashboard", { replace: true });
      } else {
        throw new Error("Invalid response data");
      }
    } catch (err) {
      console.error("Login error", err);
      toast.error(err?.response?.data?.MESSAGE || "Login failed");
    } finally {
      setLoading(false); // Stop loading after attempt
    }
  };

  useEffect(() => {
    if (getParams.get("token")) createSession(); // Trigger session creation on component mount
  }, []); // Empty dependency array to run only once

  if (loading) {
    return <div>Loading...</div>; // Optional: Add a loading indicator
  }

  return <Navigate to="/dashboard" replace />;
};

export default InitSession;
