import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import { ProtectedRoute } from './ProtectedRoute';
import LoginPage from './pages/LoginPage';
import ResetPassword from './pages/ResetPassword';
import Page404 from './pages/Page404';
import AddProject from './pages/ManageProjects';
import Projects from './pages/Projects';
import AddNewRequest from './pages/AddNewRequest';
import Requests from './pages/Requests';
import RequestTechnologies from './pages/RequestTechnologies';
import RequestSummary from './pages/RequestSummary';
import RequestApproval from './pages/RequestApproval';
import ProjectDetails from './pages/RequestDetails';

import ManageApplication from './pages/ManageApplication';
import ManageAdmins from './pages/ManageAdmins';
import ManageEmployees from './pages/ManageEmployes';

import CandidatesForm from './sections/@dashboard/candidiates/AssignFrom';

import ManageCandidates from './pages/ManageCandidates';

import ManageUnitsWithProvider from './pages/ManageUnitsProvider';
import ManageProjectWithProvider from './pages/ManageProjectWithProvider';

import Units from './pages/Units';
import ManagePlansWithProvider from './pages/ManagePlansProvider';
import Plans from './pages/Plans';
import Lands from './pages/Lands';
import ManageLandsWithProvider from './pages/ManageLandsProvider';
import AdminForm from './sections/@dashboard/admins/AdminForm';
import JobTitleForm from './sections/@dashboard/jobTitles/JobTitleForm';
import ManageJobs from './pages/ManageJobTitles';
import AddBuilding from './sections/@dashboard/Building/buildingForm';
import ManageBuildings from './pages/ManageBuildings';
import ManageReservationRequests from './pages/ManageReservationRequests';
import ManageOwners from './pages/ManageOwners';
import AddOwner from './sections/@dashboard/owners/OwnerForm';
import ManageRoles from './pages/ManageRoles';
import RolesList from './sections/@dashboard/RolesList/RolesList';
import ManageBuildingsWithProjectId from './pages/ManageBuildingsWithProjectId';
import DashboardChartsLayout from './pages/DashboardChartsLayout';
import ReviewProducts from './pages/ReviewProducts';
import TermsAndCondition from './pages/TermsAndCondition';
import PrivacyTerms from './pages/PrivacyTerms';
import TaxExemption from './pages/TaxExemption';
import SuccessPage from './pages/SuccessPage';
import FAQs from './pages/FAQs';
import Profile from './sections/@dashboard/profile/ProfileForm';
import OwnerShipRequests from './pages/OwnershipRequests';
import MainContent from './sections/@dashboard/DetailsContent/DetailsMainContent';
import LandMainContent from './sections/@dashboard/DetailsContent/DetailsLandMainContent';
import Reports from './pages/Reports';
import ActivityLog from './pages/ActivityLog';
import OurServices from './pages/OurServices';
import OurOffers from './pages/OurOffers';
import TechnicalSupport from './pages/TechnicalSupport';
import Contacts from './pages/Contacts';
import Users from './pages/Users';
import ViewPrivacyPolicy from './pages/ViewPrivacyPolicy';
import RealStateRequests from './pages/ManageRealStateRequests';
import ViewRealStateRequests from './pages/ViewRealStateRequests';
import InitSession from './InitSession';
import RequestsAndSupport from './pages/RequestsAndSupport';
import RequestsAndConfig from './pages/RequestsAndConfigration';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <InitSession />,
    },
    {
      path: '/dashboard',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '/dashboard',
          element: (
            <ProtectedRoute>
              <DashboardChartsLayout />
            </ProtectedRoute>
          ),
        },
        {
          path: 'manage-application',
          element: (
            <ProtectedRoute>
              <ManageApplication />
            </ProtectedRoute>
          ),
        },
        {
          path: 'manage-employees',
          element: (
            <ProtectedRoute>
              <ManageEmployees />
            </ProtectedRoute>
          ),
        },
        {
          path: 'manage-candidates',
          element: (
            <ProtectedRoute>
              <ManageCandidates />
            </ProtectedRoute>
          ),
        },
        {
          path: 'manage-candidates/assign/:id',
          element: (
            <ProtectedRoute>
              <CandidatesForm />
            </ProtectedRoute>
          ),
        },
        {
          path: 'projects',
          element: (
            <ProtectedRoute>
              <Projects />
            </ProtectedRoute>
          ),
        },
        {
          path: 'project/add',
          element: (
            <ProtectedRoute>
              <ManageProjectWithProvider />
            </ProtectedRoute>
          ),
        },
        {
          path: 'project/edit/:id',
          element: (
            <ProtectedRoute>
              <ManageProjectWithProvider />
            </ProtectedRoute>
          ),
        },
        {
          path: 'project/view/:id',
          element: (
            <ProtectedRoute>
              <ManageProjectWithProvider />
            </ProtectedRoute>
          ),
        },
        {
          path: 'units',
          element: (
            <ProtectedRoute>
              <Units />
            </ProtectedRoute>
          ),
        },
        {
          path: 'units/add',
          element: (
            <ProtectedRoute>
              <ManageUnitsWithProvider />
            </ProtectedRoute>
          ),
        },
        {
          path: 'units/add/:id',
          element: (
            <ProtectedRoute>
              <ManageUnitsWithProvider />
            </ProtectedRoute>
          ),
        },

        {
          path: 'units/edit/:id',
          element: (
            <ProtectedRoute>
              <ManageUnitsWithProvider />
            </ProtectedRoute>
          ),
        },
        {
          path: 'units/view/:id',
          element: (
            <ProtectedRoute>
              <ManageUnitsWithProvider />
            </ProtectedRoute>
          ),
        },
        {
          path: 'plans',
          element: (
            <ProtectedRoute>
              <Plans />
            </ProtectedRoute>
          ),
        },
        {
          path: 'plans/add',
          element: (
            <ProtectedRoute>
              <ManagePlansWithProvider />
            </ProtectedRoute>
          ),
        },
        {
          path: 'plans/edit/:id',
          element: (
            <ProtectedRoute>
              <ManagePlansWithProvider />
            </ProtectedRoute>
          ),
        },
        {
          path: 'plans/view/:id',
          element: (
            <ProtectedRoute>
              <ManagePlansWithProvider />
            </ProtectedRoute>
          ),
        },
        {
          path: 'lands',
          element: (
            <ProtectedRoute>
              <Lands />
            </ProtectedRoute>
          ),
        },
        {
          path: 'land/add',
          element: (
            <ProtectedRoute>
              <ManageLandsWithProvider />
            </ProtectedRoute>
          ),
        },
        {
          path: 'land/edit/:id',
          element: (
            <ProtectedRoute>
              <ManageLandsWithProvider />
            </ProtectedRoute>
          ),
        },
        {
          path: 'land/view/:id',
          element: (
            <ProtectedRoute>
              <ManageLandsWithProvider />
            </ProtectedRoute>
          ),
        },
        {
          path: 'admin/add',
          element: (
            <ProtectedRoute>
              <AdminForm />
            </ProtectedRoute>
          ),
        },
        {
          path: 'admin/edit/:id',
          element: (
            <ProtectedRoute>
              <AdminForm />
            </ProtectedRoute>
          ),
        },
        {
          path: 'job-titles',
          element: (
            <ProtectedRoute>
              <ManageJobs />
            </ProtectedRoute>
          ),
        },
        {
          path: 'add-job-title',
          element: (
            <ProtectedRoute>
              <JobTitleForm />
            </ProtectedRoute>
          ),
        },
        {
          path: 'edit-job-title/:id',
          element: (
            <ProtectedRoute>
              <JobTitleForm />
            </ProtectedRoute>
          ),
        },
        {
          path: 'buildings',
          element: (
            <ProtectedRoute>
              <ManageBuildings />
            </ProtectedRoute>
          ),
        },
        {
          path: 'building/add',
          element: (
            <ProtectedRoute>
              <AddBuilding />
            </ProtectedRoute>
          ),
        },
        {
          path: 'building/add/:id',
          element: (
            <ProtectedRoute>
              <AddBuilding />
            </ProtectedRoute>
          ),
        },
        {
          path: 'building/edit/:id',
          element: (
            <ProtectedRoute>
              <AddBuilding />
            </ProtectedRoute>
          ),
        },
        {
          path: 'building/view/:id',
          element: (
            <ProtectedRoute>
              <AddBuilding />
            </ProtectedRoute>
          ),
        },
        {
          path: 'resrvation-request',
          element: (
            <ProtectedRoute>
              <ManageReservationRequests />
            </ProtectedRoute>
          ),
        },
        {
          path: 'manage-owners',
          element: (
            <ProtectedRoute>
              <ManageOwners />
            </ProtectedRoute>
          ),
        },
        {
          path: 'manage-owner/add',
          element: (
            <ProtectedRoute>
              <AddOwner />
            </ProtectedRoute>
          ),
        },
        {
          path: 'manage-owner/edit/:id',
          element: (
            <ProtectedRoute>
              <AddOwner />
            </ProtectedRoute>
          ),
        },
        {
          path: 'request-technologies/:request_id',
          element: (
            <ProtectedRoute>
              <RequestTechnologies />
            </ProtectedRoute>
          ),
        },
        {
          path: 'request-summary/:request_id',
          element: (
            <ProtectedRoute>
              <RequestSummary />
            </ProtectedRoute>
          ),
        },
        {
          path: 'request-approval/:request_id',
          element: (
            <ProtectedRoute>
              <RequestApproval />
            </ProtectedRoute>
          ),
        },
        {
          path: 'project-details/edit/:id',
          element: (
            <ProtectedRoute>
              <ProjectDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: 'project-details/view/:id',
          element: (
            <ProtectedRoute>
              <ProjectDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: 'buildings/:id',
          element: (
            <ProtectedRoute>
              <ManageBuildingsWithProjectId />
            </ProtectedRoute>
          ),
        },

        {
          path: 'manage-owner/add',
          element: (
            <ProtectedRoute>
              <AddOwner />
            </ProtectedRoute>
          ),
        },
        {
          path: 'project-details',
          element: (
            <ProtectedRoute>
              <AddOwner />
            </ProtectedRoute>
          ),
        },
        {
          path: 'roles',
          element: (
            <ProtectedRoute>
              <RolesList />
            </ProtectedRoute>
          ),
        },

        {
          path: 'add-role',
          element: (
            <ProtectedRoute>
              <ManageRoles />
            </ProtectedRoute>
          ),
        },

        {
          path: 'role/edit/:roleId',
          element: (
            <ProtectedRoute>
              <ManageRoles />
            </ProtectedRoute>
          ),
        },

        {
          path: 'review-products',
          element: (
            <ProtectedRoute>
              <ReviewProducts />
            </ProtectedRoute>
          ),
        },
        {
          path: 'ownership-requests',
          element: (
            <ProtectedRoute>
              <OwnerShipRequests />
            </ProtectedRoute>
          ),
        },

        {
          path: 'terms-condition',
          element: (
            <ProtectedRoute>
              <TermsAndCondition />
            </ProtectedRoute>
          ),
        },
        {
          path: 'privacy-terms',
          element: (
            <ProtectedRoute>
              <PrivacyTerms />
            </ProtectedRoute>
          ),
        },
        {
          path: 'tax-exemption',
          element: (
            <ProtectedRoute>
              <TaxExemption />
            </ProtectedRoute>
          ),
        },
        {
          path: 'activity-log',
          element: (
            <ProtectedRoute>
              <ActivityLog />
            </ProtectedRoute>
          ),
        },

        {
          path: 'FAQs',
          element: (
            <ProtectedRoute>
              <FAQs />
            </ProtectedRoute>
          ),
        },

        {
          path: 'profile',
          element: (
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          ),
        },
        {
          path: 'ownership-requests/details/:id',
          element: (
            <ProtectedRoute>
              <MainContent />
            </ProtectedRoute>
          ),
        },
        {
          path: 'ownership-requests/details/land/:id',
          element: (
            <ProtectedRoute>
              <LandMainContent />
            </ProtectedRoute>
          ),
        },
        {
          path: 'reports',
          element: (
            <ProtectedRoute>
              <Reports />
            </ProtectedRoute>
          ),
        },
        {
          path: 'ourService',
          element: (
            <ProtectedRoute>
              <OurServices />
            </ProtectedRoute>
          ),
        },
        {
          path: 'ourOffers',
          element: (
            <ProtectedRoute>
              <OurOffers />
            </ProtectedRoute>
          ),
        },
        {
          path: 'technical-support',
          element: (
            <ProtectedRoute>
              <TechnicalSupport />
            </ProtectedRoute>
          ),
        },
        {
          path: 'contacts',
          element: (
            <ProtectedRoute>
              <Contacts />
            </ProtectedRoute>
          ),
        },
        {
          path: 'users',
          element: (
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          ),
        },
        {
          path: 'real-state-requests',
          element: (
            <ProtectedRoute>
              <RealStateRequests />
            </ProtectedRoute>
          ),
        },
        {
          path: 'real-state-requests/:id',
          element: (
            <ProtectedRoute>
              <ViewRealStateRequests />
            </ProtectedRoute>
          ),
        },
        {
          path: 'requests-support',
          element: (
            <ProtectedRoute>
              <RequestsAndSupport />
            </ProtectedRoute>
          ),
        },
        {
          path: 'requests-configration',
          element: (
            <ProtectedRoute>
              <RequestsAndConfig />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        // { element: <Navigate to="/dashboard/manage-application" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'privacy-policy',
      element: <ViewPrivacyPolicy />,
    },
    {
      path: 'reset-password',
      element: <ResetPassword />,
    },
    {
      path: 'success-link',
      element: <SuccessPage />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
