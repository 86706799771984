import React, { createContext, useContext, useEffect, useState } from 'react';

// routes import

import { useLocation, useParams, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// api calls

import {
  addProject,
  editProject,
  deleteAttachments,
  deletePictures,
  deleteSketch,
  getProjectRequestDetails,
} from '../network/apis';
import LoadingOverlay from '../components/loading-overlay/LoadingOverlay';

// create context to manage data in form

const FormContext = createContext();

export const useProjectFormContext = () => useContext(FormContext);

export const FormProvider = ({ children }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const fromPage = location.state?.from;

  const [isViewMode, setIsViewMode] = useState(false);
  const [detailsData, setDetails] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [uploadedFilesUploaded, setUploadedFilesUploaded] = useState({
    projectBannar: [],
    projectPictures: [],
    projectAttacments: [],
    virtualTour: [],
    primaryPhoto: [],
  });
  const [loading_submit, setSubmitLoading] = useState(true);
  const formDetails = useSelector((state) => state.form.formData);
  // multi select states
  const [selectedFacilities, setSelectedFacilities] = useState(
    detailsData.availableFacilities ? detailsData?.availableFacilities?.map((value) => value.id) : []
  );
  const [selectedServices, setSelectedServices] = useState(
    detailsData.availableServices ? detailsData.availableServices?.map((value) => value.id) : []
  );
  const [selectedAfterSaleServices, setSelectedPostSaleServices] = useState(
    detailsData.afterSaleServices ? detailsData.afterSaleServices?.map((value) => value.id) : []
  );

  const isEditMode = detailsData && Object.keys(detailsData).length > 0;
  useEffect(() => {
    if (currentPath.includes('view')) {
      setIsViewMode(true);
    } else {
      setIsViewMode(false); // Ensure to reset the view mode if path does not include 'view'
    }
  }, [currentPath]);
  const fetchProjectDetails = async () => {
    try {
      const { data } = await getProjectRequestDetails(id);
      setDetails(data.data);
      setSelectedFacilities(data.data?.availableFacilities?.map((value) => value.id));
      setSelectedServices(data.data?.availableServices?.map((value) => value.id));
      setSelectedPostSaleServices(data.data?.afterSaleServices?.map((value) => value.id));
    } catch (error) {
      console.error('Failed to fetch unit details:', error);
      toast.error(error.response.data.MESSAGE);
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setSubmitLoading(true);
      fetchProjectDetails();
    } else {
      setSubmitLoading(false);
    }
  }, [id, refetch]);

  const handleDelete = async (fileId, fieldName) => {
    let response;
    if (fieldName === 'attachment') {
      response = await deleteAttachments(fileId);
    } else if (fieldName === 'sketch') {
      response = await deleteSketch(fileId);
    } else {
      response = await deletePictures(fileId);
    }
    if (response?.data?.status === '1') {
      toast.success(response?.data?.MESSAGE);
    } else {
      toast.error(response?.data?.MESSAGE);
    }
  };

  // Add Item FunctionCopy code
  function addItem(fieldName, items, acceptMultiple = true) {
    // Initialize the field if it doesn't exist
    if (!uploadedFilesUploaded[fieldName]) {
      uploadedFilesUploaded[fieldName] = [];
    }

    if (acceptMultiple) {
      // If multiple items are allowed, add items if they don't exist
      items.forEach((item) => {
        if (!uploadedFilesUploaded[fieldName].includes(item)) {
          uploadedFilesUploaded[fieldName].push(item);
        }
      });
    } else {
      // If multiple items are not allowed, replace the existing item
      uploadedFilesUploaded[fieldName] = [items[0]];
    }

    return uploadedFilesUploaded;
  }

  // Delete Item Function
  function deleteItem(fieldName, item) {
    if (uploadedFilesUploaded[fieldName]) {
      setUploadedFilesUploaded((prevUploadedFilesUploaded) => {
        const newUploadedFilesUploaded = { ...prevUploadedFilesUploaded };
        newUploadedFilesUploaded[fieldName] = newUploadedFilesUploaded[fieldName].filter((i) => i?.id !== item);
        return newUploadedFilesUploaded;
      });
    }
    return uploadedFilesUploaded;
  }

  const handleSubmit = (event) => {
    const mutatedProject = {
      nameEn: formDetails?.nameEn,
      nameAr: formDetails?.nameAr,
      ownerLogo: [],
      owner: formDetails?.owner,
      regionId: formDetails?.regionId,
      cityId: formDetails?.cityId,
      licenceId: formDetails?.licenceId,
      totalUnits: formDetails?.totalUnits,
      unitOffered: formDetails?.unitOffered,
      facilities: formDetails?.facilities,
      services: formDetails?.services,
      afterSaleServicesIds: formDetails?.afterSaleServices,
      endDate: formDetails?.endDate,
      addressEn: formDetails?.addressEn,
      addressAr: formDetails?.addressAr,
      location: formDetails?.location,
      totalArea: formDetails?.totalArea,
      detailsEn: formDetails?.detailsEn,
      detailsAr: formDetails?.detailsAr,
      reservationStatus: formDetails?.reservationStatus,
      projectStatus: formDetails?.projectStatus,
      unitType: formDetails?.unitType,
      recentProject: formDetails?.recentProject,
      consultant: formDetails?.consultant,
      projectDataStatus: formDetails?.projectDataStatus,
      displayConfig: formDetails?.displayConfig,
      virtualLink: formDetails?.virtualLink ? formDetails?.virtualLink : 0,
      virtualType: formDetails?.virtualType ? 'IMAGE' : 'LINK',
      totalBuilding: formDetails?.totalBuilding,
      landmarks: formDetails?.nearestLandmarks?.map((landmark) => {
        return {
          ...landmark,
          distance: landmark.distance.toString(),
        };
      }),
      pictures: uploadedFilesUploaded?.projectPictures?.map((item) => item.id),
      projectBanner: uploadedFilesUploaded?.projectBannar?.map((item) => item.id),
      primaryPhoto: uploadedFilesUploaded?.primaryPhoto?.map((item) => item.id),
      virtualTour: uploadedFilesUploaded?.virtualTour?.map((item) => item.id),
      attachments: uploadedFilesUploaded?.projectAttacments?.map((item) => item.id),
      states: 'ACTIVE',
    };
    setSubmitLoading(true);

    if (isEditMode) {
      editProject({ ...mutatedProject, id })
        .then((response) => {
          setSubmitLoading(false);
          if (response.data.STATUS === 'SUCCESS') {
            toast.success(response?.data?.MESSAGE);
            navigate('/dashboard/projects');
          } else if (response.data.STATUS === 'FAILED') {
            toast.error(response.data.MESSAGE);
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          toast.error(error.response.data.MESSAGE);
        });
    } else {
      addProject(mutatedProject)
        .then((response) => {
          setSubmitLoading(false);
          if (response.data.STATUS === 'SUCCESS') {
            toast.success(response.data.MESSAGE);
            navigate('/dashboard/projects');
          } else if (response.data.STATUS === 'FAILED') {
            toast.error(response.data.MESSAGE);
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          if (error?.response) toast.error(error?.response?.data?.MESSAGE);
          else {
            toast.error('Please check your network');
          }
        });
    }
  };
  return (
    <FormContext.Provider
      value={{
        formDetails,
        detailsData,
        uploadedFilesUploaded,
        refetch,
        isViewMode,
        isEditMode,
        setSelectedFacilities,
        setSelectedServices,
        setSelectedPostSaleServices,
        selectedFacilities,
        selectedServices,
        selectedAfterSaleServices,
        setRefetch,
        addItem,
        deleteItem,
        handleDelete,
        handleSubmit,
        fromPage,
      }}
    >
      {loading_submit ? <p>{<LoadingOverlay />}</p> : children}
    </FormContext.Provider>
  );
};
