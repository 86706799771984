/* eslint-disable react/prop-types */
import { useState } from "react";
// @mui
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import {
  Table,
  TableRow,
  TableBody,
  Button,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { Box } from "@mui/system";

// components
import Scrollbar from "../../../components/scrollbar";

// sections
import ClientReqHead from "../../../components/clientHeadTable/ClientHeadTable";

// import icon

import { ReactComponent as DeleteIcon } from "../../../theme/images/deleteTableIcon.svg";
import { ReactComponent as EditIcon } from "../../../theme/images/editTableIcon.svg";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ClientReqTable({
  requests,
  handleChangePage,
  page,
  onDelete,
  onEdit,
}) {
  const [rowsPerPage] = useState(1);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";

  const TABLE_HEAD = [
    {
      id: "category",
      label: t("response_templates.ask_requests_type"),
      alignRight: isRtl && true,
    },
    { id: "nameEn", label: t("General.nameEn"), alignRight: isRtl && true },
    { id: "nameAr", label: t("General.nameAr"), alignRight: isRtl && true },
    {
      id: "creation_date",
      label: t("General.creationDate"),
      alignRight: isRtl && true,
    },
    { id: "action", label: t("General.Action"), alignRight: isRtl && true },
  ];
  const list = [
    {
      id: 1,
      labelEn: "Project",
      labelAr: "مشروع",
      value: "PROJECT",
    },
    {
      id: 1,
      labelEn: "Plan",
      labelAr: "مخطط",
      value: "PLAN",
    },
  ];
  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ClientReqHead headLabel={TABLE_HEAD} />
            <TableBody>
              {requests?.content?.map((row) => {
                const { id, nameAr, nameEn, category, creationDate } = row;
                const displayDate = creationDate
                  ? new Date(creationDate).toLocaleDateString()
                  : new Date().toLocaleDateString();

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell component="th" scope="row">
                      <Typography
                        sx={{
                          textAlign: isRtl ? "right" : "left",
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {" "}
                        {isRtl
                          ? list.find((item) => item.value === category)
                              ?.labelAr
                          : list.find((item) => item.value === category)
                              ?.labelEn}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography
                        sx={{
                          textAlign: isRtl ? "right" : "left",
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {" "}
                        {nameEn}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography
                        sx={{
                          textAlign: isRtl ? "right" : "left",

                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {" "}
                        {nameAr}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography
                        sx={{
                          textAlign: isRtl ? "right" : "left",

                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {" "}
                        {displayDate || ""}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Box display={"flex"} alignItems={"center"} gap={2}>
                        <Button onClick={() => onDelete(id)}>
                          <DeleteIcon />
                        </Button>
                        <Button onClick={() => onEdit({ id, nameEn, nameAr })}>
                          <EditIcon />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {requests?.totalElements === 0 && (
        <Box textAlign="center">
          <h5>{t("General.noDataFound")}</h5>
        </Box>
      )}

      {requests?.totalPages > 0 && (
        <TablePagination
          rowsPerPageOptions={[9]}
          component="div"
          count={requests.totalPages}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      )}
    </>
  );
}
