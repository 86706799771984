import * as React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box, useTheme } from '@mui/material';

import { styled } from '@mui/system';

const StyledTabs = styled(Tabs)({
  overflow: 'auto',
  marginBottom: 1,
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 80,
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.85)',
  position: 'relative',
  padding: '8px 12px',
  '&.Mui-selected': {
    backgroundColor: `${theme.palette.mode === 'dark' ? 'black' : '#E0FFE1'}`,
    borderRadius: '8px 8px 0 0',
    color: '#30A14E',
    fontWeight: 'bold',
  },
  '&.Mui-selected::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '25%',
    width: '50%',
    borderBottom: `3px solid ${theme.palette.mode === 'dark' ? 'black' : '#30A14E'}`, // Add centered bottom border for the active tab
  },
  '&:hover': {
    backgroundColor: '#E0FFE1',
    borderRadius: '8px 8px 0 0',
    color: `${theme.palette.mode === 'dark' ? 'black' : '#30A14E'}`,
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ tabs }) {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 4 }}>
        <StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabs.map((tab, index) => (
            <StyledTab key={index} label={tab.label} {...a11yProps(index)} />
          ))}
        </StyledTabs>
      </Box>
      {tabs.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
}

BasicTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
};
