import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

// @mui
import { Card, Stack, Button, Container, Typography, Skeleton, Grid } from '@mui/material';
import { CityReqTable } from '../sections/@dashboard/city';
import SearchComponet from '../components/search/Search';
import ModalComponent from '../components/modal/modal';
import CityForm from '../sections/@dashboard/city/CitiyForm';
import { getCitiesList, deleteCity } from '../network/apis';
import DeleteFileModal from '../components/deleteFileModal/DeleteFileModal';

export default function Cities() {
  const { t } = useTranslation();
  const [cityId, setCityId] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [clientReqList, setClientReqList] = useState([]);
  const [search, setSearch] = useState('');

  const clientRequests = (page, search) => {
    setLoading(true);
    getCitiesList(page, search)
      .then((res) => {
        setClientReqList(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    clientRequests(page, search);
  }, [page, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDeleteCity = (cityId) => {
    deleteCity(cityId)
      .then((res) => {
        if (res.data.STATUS === 'FAILED' || res.data.STATUS === '0') {
          toast.error(res.data.MESSAGE);
        } else {
          toast.success(res.data.MESSAGE);
          setOpenDelete(false);
        }
        clientRequests(page, search);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.MESSAGE);
      });
  };
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    clientRequests(page, search);
  };
  return (
    <>
      <Helmet>
        <title> {t('city.cities')} </title>
      </Helmet>
      <ModalComponent
        style={{ position: 'relative' }}
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        title={t('city.addCity')}
        content=""
      >
        <CityForm handleClose={handleCloseModal} />
      </ModalComponent>
      <>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} sm={6} display="flex" justifyContent="start">
            <SearchComponet onSearch={setSearch} />
          </Grid>

          <Grid item xs={6} sm={6} display="flex" justifyContent="end">
            <Button
              onClick={handleOpenModal}
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '10px',
                padding: '21px 24px',
                width: '164px',
                height: '56px',
                marginBottom: '15px',
              }}
            >
              + {t('city.addCity')}
            </Button>
          </Grid>
        </Grid>

        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <CityReqTable
              handleChangePage={handleChangePage}
              page={page}
              requests={clientReqList}
              onDelete={(id) => {
                setOpenDelete(true);
                setCityId(id);
              }}
            />
            <DeleteFileModal
              handelClose={() => {
                setOpenDelete(false);
              }}
              open={openDelete}
              onDelete={() => handleDeleteCity(cityId)}
            />
          </>
        )}
      </>
    </>
  );
}
