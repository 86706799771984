import React, { Component, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Grid,
  Modal,
  Box,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getPublicQuestions, addPublicQuestion, updatePublicQuestion } from '../network/apis';
import FormModal from '../components/formModal/FormModal';

function FAQs() {
  const { t, i18n } = useTranslation();

  const [page, setPage] = useState(0);
  const [action, setAction] = useState('');
  const [faqList, setFaqList] = useState([]);

  const [rowObject, setRowObject] = useState({
    titleEn: '',
    titleAr: '',
    bodyEn: '',
    bodyAr: '',
    sequence: '',
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getAllPublicQuestion = async () => {
    try {
      const response = await getPublicQuestions(page);
      setFaqList(response?.data.content);
    } catch (error) {
      console.log('error', error);
    }
  };

  const saveData = () => {
    if (
      rowObject.titleEn === '' ||
      rowObject.titleAr === '' ||
      rowObject.bodyEn === '' ||
      rowObject.bodyAr === '' ||
      rowObject.sequence === ''
    ) {
      toast.error(t('General.fillRequired'));
      return;
    }

    if (action === 'I') {
      const response = addPublicQuestion(rowObject).then((res) => {
        if (response.data.STATUS === 'SUCCESS') {
          getAllPublicQuestion();
          toast.success(response.data.MESSAGE);
          handleClose();
        } else {
          toast.error(response.data.MESSAGE);
        }
      });
    } else {
      const response = updatePublicQuestion(rowObject).then((res) => {
        if (response.data.STATUS === 'SUCCESS') {
          getAllPublicQuestion();
          handleClose();
          toast.success(response.data.MESSAGE);
        } else {
          toast.error(res.data.MESSAGE);
        }
      });
    }
  };

  useEffect(() => {
    getAllPublicQuestion();
  }, []);

  const handleClickAccordionDetails = (id) => {
    const rowObj = faqList.find((item) => item.id === id);
    setRowObject(rowObj);
    setAction('U');
    handleOpen();
  };

  const addQuestion = () => {
    setRowObject({
      titleEn: '',
      titleAr: '',
      bodyEn: '',
      bodyAr: '',
      sequence: '',
    });
    setAction('I');
    handleOpen();
  };

  const FaqAccordion = ({ titleEn, titleAr, bodyEn, bodyAr, sequence, onClick }) => (
    <Accordion
      sx={{
        marginBottom: '10px',
        padding: '10px',
        border: 'none',
        '& .MuiAccordionSummary-root': {
          padding: '0',
        },
        '& .MuiAccordionDetails-root': {
          padding: '0',
        },
        '&::before': {
          display: 'none',
        },
        boxShadow: '0px 0px 13px #e9e9e9',
        borderRadius: '10px',
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
        '&:hover .overlay': {
          display: 'block',
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Grid>
          <Typography item xs={2} sm={4} md={6} variant="h6">
            {' '}
            {t('General.que')} {sequence}
          </Typography>
        </Grid>
      </AccordionSummary>
      <Grid
        className="overlay"
        jus
        sx={{
          display: 'none',
          width: '100%',
          backgroundColor: '#fafafab8',
          top: '65px;',
          right: '0',
          bottom: '0',
          position: 'absolute',
          borderRadius: '10px',
        }}
      >
        <Grid justifyContent={'center'} display={'flex'} height={'100%'} alignItems={'center'}>
          <EditIcon onClick={onClick} sx={{ margin: '10px', color: '#0CAF60', cursor: 'pointer' }} />
          {/* <DeleteIcon sx ={{margin:"10px" ,color:"#AF0C0C"}} /> */}
        </Grid>
      </Grid>

      <AccordionDetails>
        <Typography variant="h6" sx={{ textAlign: 'left' }}>
          {titleEn}
        </Typography>
        <Typography variant="body2" sx={{ mt: 2, textAlign: 'left' }}>
          {bodyEn}
        </Typography>

        <Typography variant="h6" sx={{ textAlign: 'right' }}>
          {titleAr}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1, textAlign: 'right' }}>
          {bodyAr}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <div>
      <Helmet>
        <title> {t('termsCondition.manageFaq')}</title>
      </Helmet>

      <div className="App">
        <FormModal
          open={open}
          handleClose={handleClose}
          values={rowObject}
          setValues={setRowObject}
          saveData={saveData}
        />

        <Container>
          <Grid mb={2} mt={2} rowSpacing={2} justifyContent={'end'} container spacing={{ xs: 2, md: 3 }}>
            <Button variant="contained" onClick={addQuestion}>
              + {t('General.addNew')}
            </Button>
          </Grid>

          {faqList.map((faq) => (
            <FaqAccordion
              onClick={() => handleClickAccordionDetails(faq.id)}
              key={faq.id}
              titleEn={faq.titleEn}
              titleAr={faq.titleAr}
              bodyEn={faq.bodyEn}
              bodyAr={faq.bodyAr}
              sequence={faq.sequence}
            />
          ))}
        </Container>
      </div>
    </div>
  );
}
export default FAQs;
