import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';

import { addUserRequest, getVatList } from '../../../network/apis';

export default function FinancialDataForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [vatList, setVatList] = useState([]);
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';

  const [formData, setFormData] = useState({
    DOWN_PAYMENT_AMOUNT: 'FIXED',
    AMOUNT_OF_VALUE: '',
    DOWN_PAYMENT_COMMISSION: 'FIXED',
    COMMISSION_VALUE: '',
    REAL_ESTATE_TAX: '',
  });
  const [isFormDataLoaded, setIsFormDataLoaded] = useState(false); // New state variable

  const label = { inputProps: { 'aria-label': 'Bank Financing feature at the start of Ownership' } };
  // Load form data from local storage when the component mounts
  const saveFormDataToLocalStorage = (data) => {
    localStorage.setItem('financialData', JSON.stringify(data));
    sessionStorage.setItem('financialData', JSON.stringify(data));
  };
  useEffect(() => {
    const savedData = localStorage.getItem('financialData') || sessionStorage.getItem('financialData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
      setIsFormDataLoaded(true); // Set to true after formData is updated
    } else {
      setIsFormDataLoaded(true); // Set to true if there's no saved data
    }
  }, []);

  useEffect(() => {
    saveFormDataToLocalStorage(formData);
  }, [formData]);

  useEffect(() => {
    getVatList()
      .then((res) => {
        setVatList(res.data); // Assuming the data is in res.data, adjust as necessary
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  if (!isFormDataLoaded) {
    return <div>Loading...</div>; // Or a loading spinner
  }
  const validationSchema = yup.object({
    DOWN_PAYMENT_AMOUNT: yup
      .string()
      .required(t('Validation.requiredField'))
      .oneOf(['FIXED', 'PERCENTAGE'], 'Invalid option'),
    AMOUNT_OF_VALUE: yup
      .string()
      .required(t('Validation.requiredField'))
      .when('DOWN_PAYMENT_AMOUNT', (DOWN_PAYMENT_AMOUNT, schema) => {
        // Adjust the regex based on the value of DOWN_PAYMENT_COMMISSION
        const regex = DOWN_PAYMENT_AMOUNT === 'PERCENTAGE' ? /^(\d+(\.\d{1,2})?%)$/ : /^(\d+(\.\d{1,2})?)$/;
        // Generate the error message based on DOWN_PAYMENT_COMMISSION
        const errorMessage =
          DOWN_PAYMENT_AMOUNT === 'PERCENTAGE'
            ? t('manageApplication.mustBePercentage')
            : t('manageApplication.mustBeFixedNumber');
        return schema.test('is-percentage-or-number', errorMessage, (value) => {
          // Ensure the value matches the regex and is not '0%' or '0'
          return regex.test(value) && value !== '0%' && value !== '0';
        });
      }),
    DOWN_PAYMENT_COMMISSION: yup
      .string()
      .required(t('Validation.requiredField'))
      .oneOf(['FIXED', 'PERCENTAGE'], 'Invalid option'),
    COMMISSION_VALUE: yup
      .string()
      .required(t('Validation.requiredField'))
      .when('DOWN_PAYMENT_COMMISSION', (DOWN_PAYMENT_COMMISSION, schema) => {
        // Adjust the regex based on the value of DOWN_PAYMENT_COMMISSION
        const regex = DOWN_PAYMENT_COMMISSION === 'PERCENTAGE' ? /^(\d+(\.\d{1,2})?%)$/ : /^(\d+(\.\d{1,2})?)$/;
        // Generate the error message based on DOWN_PAYMENT_COMMISSION
        const errorMessage =
          DOWN_PAYMENT_COMMISSION === 'PERCENTAGE'
            ? t('manageApplication.mustBePercentage')
            : t('manageApplication.mustBeFixedNumber');
        return schema.test('is-percentage-or-number', errorMessage, (value) => {
          // Ensure the value matches the regex and is not '0%' or '0'
          return regex.test(value) && value !== '0%' && value !== '0';
        });
      }),
    REAL_ESTATE_TAX: yup
      .string()
      .required(t('Validation.requiredField')) // Ensure the field is entered
      .test('is-percentage-or-number', t('manageApplication.greaterThanZero'), (value) => {
        // Regular expression to match a percentage with a '%' symbol or a number greater than 0
        const regex = /^(\d+(\.\d{1,2})?%|(\d+(\.\d{1,2})?))$/;
        // Check that the value is not 0% or 0
        return regex.test(value) && value !== '0%' && value !== '0';
      }),
  });

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setLoading(true);
        addUserRequest(values)
          .then((response) => {
            if (response.data.STATUS === 'FAILED' || response.data.STATUS === '0') toast.success(response.data.MESSAGE);
            else toast.error(response.data.MESSAGE);
            setLoading(false);
            setFormData(values);
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err.response.data.MESSAGE);
          });
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm }) => (
        <form style={{ height: '100%' }} onSubmit={handleSubmit}>
          <Grid rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={4} md={6}>
              <Stack>
                <InputLabel id="DOWN_PAYMENT_AMOUNT"> {t('manageApplication.downPaymentAmount')} </InputLabel>
                <Select
                  sx={{ mt: 1 }}
                  labelId="DOWN_PAYMENT_AMOUNT"
                  id="DOWN_PAYMENT_AMOUNT"
                  value={values.DOWN_PAYMENT_AMOUNT}
                  onChange={(event) => {
                    handleChange({
                      target: {
                        name: 'DOWN_PAYMENT_AMOUNT',
                        value: event.target.value,
                      },
                    });
                  }}
                >
                  <MenuItem value="FIXED"> {t('manageApplication.specificAmount')} </MenuItem>
                  <MenuItem value="PERCENTAGE"> {t('manageApplication.percentage')} </MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('manageApplication.amountValue')}
              </Typography>
              <TextField
                name="AMOUNT_OF_VALUE"
                required
                fullWidth
                value={values.AMOUNT_OF_VALUE}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.AMOUNT_OF_VALUE && Boolean(errors.AMOUNT_OF_VALUE)}
                helperText={touched.AMOUNT_OF_VALUE && errors.AMOUNT_OF_VALUE}
              />
            </Grid>
            <>
              <Grid item xs={2} sm={4} md={6}>
                <Stack>
                  <InputLabel id="DOWN_PAYMENT_COMMISSION">{t('manageApplication.commissionValueAmount')} </InputLabel>
                  <Select
                    sx={{ mt: 1 }}
                    labelId="DOWN_PAYMENT_COMMISSION"
                    id="DOWN_PAYMENT_COMMISSION"
                    value={values.DOWN_PAYMENT_COMMISSION}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          name: 'DOWN_PAYMENT_COMMISSION',
                          value: event.target.value,
                        },
                      });
                    }}
                  >
                    <MenuItem value="FIXED"> {t('manageApplication.specificAmount')} </MenuItem>
                    <MenuItem value="PERCENTAGE"> {t('manageApplication.percentage')} </MenuItem>
                  </Select>
                </Stack>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('manageApplication.commissionValue')}
                </Typography>
                <TextField
                  name="COMMISSION_VALUE"
                  required
                  fullWidth
                  value={values.COMMISSION_VALUE}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.COMMISSION_VALUE && Boolean(errors.COMMISSION_VALUE)}
                  helperText={touched.COMMISSION_VALUE && errors.COMMISSION_VALUE}
                />
              </Grid>

              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('manageApplication.realEstateTax')}
                </Typography>
                <TextField
                  name="REAL_ESTATE_TAX"
                  required
                  fullWidth
                  value={values.REAL_ESTATE_TAX}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.REAL_ESTATE_TAX && Boolean(errors.REAL_ESTATE_TAX)}
                  helperText={touched.REAL_ESTATE_TAX && errors.REAL_ESTATE_TAX}
                />
              </Grid>
              <Grid style={{ marginTop: '5px' }} item xs={2} sm={12} md={12}>
                <Switch {...label} disabled defaultChecked />
                <span> {t('manageApplication.bankFeature')} </span>
              </Grid>
            </>

            <Box sx={{ width: '100%', display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button
                sx={{ mt: 4, border: '1px solid #111827' }}
                size="large"
                onClick={() => resetForm()}
                type="button"
                variant=""
              >
                {t('General.cancel')}
              </Button>
              <LoadingButton sx={{ mt: 4 }} loading={loading} size="large" type="submit" variant="contained">
                {t('General.save')}
              </LoadingButton>
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
