import * as React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button, Stack, CircularProgress, Box, Select, MenuItem, FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';

import Modal from '../Modal';

import { updateContact } from '../../network/apis';
import { ReactComponent as DropdownIcon } from '../../theme/images/deopdownIcon.svg';

export default function AddOurOffersModal({ open, handleClose, list, edit }) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const [loading, setLoading] = React.useState(false);

  const handleAddOurService = async (values) => {
    setLoading(true);
    try {
      const res = await updateContact({
        [values.code]: values.value,
      });
      if (res.data.MESSAGE === 'SUCCESS') toast.success(res.data.MESSAGE);
      else toast.error(res.data.MESSAGE);
      handleClose();
    } catch (error) {
      console.log(error);
      toast.error('Failed to add Offer. Please try again');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal handleClose={handleClose} open={open} title={t('contacts.add_contact')}>
      <Formik
        initialValues={{
          code: edit.code,
          value: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleAddOurService(values);
          handleClose();
        }}
      >
        {({ values, errors, handleChange, handleSubmit, handleBlur, touched, setFieldValue }) => {
          return (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Grid
                  padding={'20px'}
                  container
                  spacing={{ xs: 2, md: 2 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{
                    height: '100%',
                    padding: '20px',
                    borderBottom: '1px solid #E5E5E5',
                  }}
                >
                  <Grid item xs={2} sm={4} md={12}>
                    <Typography variant="body1" gutterBottom>
                      {t('contacts.contacts_type')}
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        disabled={edit.edit}
                        value={values.code}
                        sx={{
                          paddingX: '10px',
                          borderRadius: '10px',
                          flexDirection: 'row',
                          justifyContent: 'start',
                        }}
                        renderValue={(selected) => {
                          const item = list?.find((item) => item.code === selected);
                          return (
                            <Box display={'flex'} alignItems={'center'} gap={2}>
                              <img
                                style={{ width: '45px', height: '45px', objectFit: 'cover', borderRadius: '50%' }}
                                src={item?.icon || ''}
                                alt={''}
                              />
                              <Typography margin={'0'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                {isRtl ? item?.labelAr || t('General.select') : item?.labelEn || t('General.select')}
                              </Typography>
                            </Box>
                          );
                        }}
                        IconComponent={() => <DropdownIcon style={{ width: '15px', height: '15px' }} />}
                        labelId="Status"
                        id="status"
                        onChange={(e) => setFieldValue('code', e.target.value)}
                      >
                        {list?.map((item, index) => (
                          <MenuItem key={index} value={item?.code}>
                            {' '}
                            <Box display={'flex'} alignItems={'start'} gap={2}>
                              {' '}
                              <img
                                style={{ width: '45px', height: '45px', objectFit: 'cover', borderRadius: '50%' }}
                                src={item?.icon}
                                alt={''}
                              />
                              <Typography margin={'0'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                {isRtl ? item?.labelAr : item?.labelEn}
                              </Typography>
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} sm={4} md={12}>
                    <Typography variant="body1" gutterBottom>
                      {t('contacts.contacts_value')}
                    </Typography>
                    <TextField
                      name="value"
                      required
                      multiline
                      rows={3}
                      fullWidth
                      value={values.value}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.value && Boolean(errors.value)}
                      helperText={touched.value && errors.value}
                    />
                  </Grid>
                </Grid>
                <Stack padding={2} sx={{ width: '100%' }} direction="row" justifyContent="end" alignItems="end" gap={2}>
                  <Button sx={{ mt: 4, border: '1px solid #111827' }} size="large" type="button" onClick={handleClose}>
                    {' '}
                    {t('General.cancel')}
                  </Button>
                  <Button sx={{ mt: 4, border: '1px solid #111827' }} size="large" type="submit" variant="contained">
                    {' '}
                    {loading ? <CircularProgress /> : t('General.submit')}
                    {}
                  </Button>
                </Stack>
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
}
