import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Box, Grid, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

import { addServicesRequest } from '../../../network/apis';

export default function RegionForm({ handleClose }) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    nameEn: yup
      .string()
      .required(t('Validation.requiredField'))
      .test('is-english', t('Validation.englishReq'), (value) => {
        // Regular expression to match English characters
        const englishRegex = /^[A-Za-z0-9_\s]*$/;
        return englishRegex.test(value);
      }),
    detailsEn: yup
      .string()
      .required(t('Validation.requiredField'))
      .test('is-english', t('Validation.englishReq'), (value) => {
        // Regular expression to match English characters
        const englishRegex = /^[A-Za-z0-9_\s]*$/;
        return englishRegex.test(value);
      }),
    nameAr: yup
      .string()
      .required(t('Validation.requiredField'))
      .test('is-arabic', t('Validation.arabicReq'), (value) => {
        // Regular expression to match Arabic characters
        const arabicRegex = /[\u0600-\u06FF]/;
        // Regular expression to match English characters
        const englishRegex = /[A-Za-z0-9]/;
        // Check if the value contains both Arabic and English characters
        return arabicRegex.test(value) && !englishRegex.test(value);
      }),
    detailsAr: yup
      .string()
      .required(t('Validation.requiredField'))
      .test('is-arabic', t('Validation.arabicReq'), (value) => {
        // Regular expression to match Arabic characters
        const arabicRegex = /[\u0600-\u06FF]/;
        // Regular expression to match English characters
        const englishRegex = /[A-Za-z0-9]/;
        // Check if the value contains both Arabic and English characters
        return arabicRegex.test(value) && !englishRegex.test(value);
      }),
  });

  return (
    <Formik
      initialValues={{
        nameEn: '',
        nameAr: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setLoading(true);
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });
        addServicesRequest(formData)
          .then((res) => {
            setLoading(false);
            if (res.data.STATUS === 'FAILED' || res.data.STATUS === '0') {
              toast.error(res.data.MESSAGE);
            } else {
              toast.success(res.data.MESSAGE);
              handleClose(); // Only close the modal if the status is not '0'
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err.response.data.MESSAGE);
          });
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('General.nameEn')}
              </Typography>
              <TextField
                name="nameEn"
                required
                fullWidth
                value={values.nameEn}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.nameEn && Boolean(errors.nameEn)}
                helperText={touched.nameEn && errors.nameEn}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('General.nameAr')}
              </Typography>
              <TextField
                name="nameAr"
                required
                fullWidth
                value={values.nameAr}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.nameAr && Boolean(errors.nameAr)}
                helperText={
                  touched.nameAr &&
                  errors.nameAr && <span dangerouslySetInnerHTML={{ __html: errors.nameAr.replace('\n', '<br />') }} />
                }
              />
            </Grid>
            <>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('General.detailsEn')}
                </Typography>
                <TextField
                  name="detailsEn"
                  required
                  fullWidth
                  value={values.detailsEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.detailsEn && Boolean(errors.detailsEn)}
                  helperText={touched.detailsEn && errors.detailsEn}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('General.detailsAr')}
                </Typography>
                <TextField
                  name="detailsAr"
                  required
                  fullWidth
                  value={values.detailsAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.detailsAr && Boolean(errors.detailsAr)}
                  helperText={
                    touched.detailsAr &&
                    errors.detailsAr && (
                      <span dangerouslySetInnerHTML={{ __html: errors.detailsAr.replace('\n', '<br />') }} />
                    )
                  }
                />
              </Grid>
            </>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingX: '20px',
              }}
            >
              {' '}
              <Button
                sx={{ mt: 4, border: '1px solid #111827' }}
                size="large"
                onClick={handleClose}
                type="button"
                variant=""
              >
                {t('General.cancel')}
              </Button>
              <LoadingButton sx={{ mt: 4 }} loading={loading} size="large" type="submit" variant="contained">
                {t('General.save')}
              </LoadingButton>
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
