import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';

import { Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

import Layout from '../../../components/Layout/Layout';

import { addJobTitle, getJobs, editJobTitle } from '../../../network/apis';

export default function AdminForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const editData = location.state;
  const handleReset = (resetForm) => {
    resetForm({
      values: {
        nameEn: '',
        nameAr: '',
      },
    });
    // navigate(`/dashboard/job-titles`);
  };
  const validationSchema = yup.object({
    nameEn: yup
      .string()
      .required('This field is required please enter english text')
      .max(30, 'Maximum Characters (30)')
      .test('is-english', 'Please enter English text', (value) => {
        // Regular expression to match English characters
        const englishRegex = /^[A-Za-z0-9_-\s]*$/;
        return englishRegex.test(value);
      }),

    nameAr: yup
      .string()
      .max(30, 'Maximum Characters (30)')
      .required('This field is required please enter arabic text')
      .test('is-arabic', 'Please enter Arabic text\nيرجي ادخال نص عربي', (value) => {
        // Regular expression to match Arabic characters
        const arabicRegex = /^[\u0600-\u06FF0-9_-\s]*$/;
        // Regular expression to match English characters
        const englishRegex = /[A-Za-z]/;
        // Check if the value contains both Arabic and English characters
        return arabicRegex.test(value) && !englishRegex.test(value);
      }),
  });

  return (
    <Layout>
      <h3>{t('jobsTitles.addJobTitle')}</h3>

      <Formik
        initialValues={{
          nameEn: editData ? editData.editData.nameEn : '',
          nameAr: editData ? editData.editData.nameAr : '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setLoading(true);
          const actionPromise = editData
            ? editJobTitle({ id: editData.editData.id, ...values }) // If editing
            : addJobTitle(values); // If adding
          actionPromise
            .then((response) => {
              setLoading(false);
              if (response.data.STATUS === 'FAILED' || response.data.STATUS === '0') {
                toast.error(response.data.MESSAGE);
              } else {
                toast.success(response.data.MESSAGE);
                navigate(`/dashboard/job-titles`);
              }
            })
            .catch((error) => {
              setLoading(false);
              const errorMessages = {
                500: 'Server Error',
                400: 'Bad Request',
                401: 'Unauthorized',
                403: 'Forbidden',
                404: 'Not Found',
                // Add more status codes as needed
              };
              if (axios.isAxiosError(error)) {
                const statusCode = error.response?.status;
                const errorMessage = errorMessages[statusCode] || 'An error occurred';
                toast.error(errorMessage);
              } else {
                toast.error('An error occurred');
              }
            })
            .finally(() => {
              setLoading(false); // Ensure loading is false in case of error or success
            });
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm }) => (
          <form onSubmit={handleSubmit}>
            <Grid rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('jobsTitles.jobTitleNameEn')}
                </Typography>
                <TextField
                  name="nameEn"
                  required
                  fullWidth
                  value={values.nameEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nameEn && Boolean(errors.nameEn)}
                  helperText={touched.nameEn && errors.nameEn}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('jobsTitles.jobTitleNameAr')}
                </Typography>
                <TextField
                  name="nameAr"
                  required
                  fullWidth
                  value={values.nameAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nameAr && Boolean(errors.nameAr)}
                  helperText={touched.nameAr && errors.nameAr}
                />
              </Grid>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingX: 2,
                  gap: 2,
                }}
              >
                <Button
                  sx={{ mt: 4, border: '1px solid #111827' }}
                  size="large"
                  onClick={() => navigate(-1)}
                  type="button"
                  variant=""
                >
                  {t('profile.cancel')}
                </Button>
                <LoadingButton sx={{ mt: 4 }} loading={loading} size="large" type="submit" variant="contained">
                  {t('profile.save')}
                </LoadingButton>
              </Box>{' '}
            </Grid>
          </form>
        )}
      </Formik>
    </Layout>
  );
}
