/* eslint-disable react/prop-types */
import React, { useState } from "react";

// localiztion

import { useTranslation } from "react-i18next";

// @mui
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableRow,
  TableBody,
  Button,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Pagination,
} from "@mui/material";
// icons imports

// import { ReactComponent as EditIcon } from "../../theme/images/editTableIcon.svg";
// import { ReactComponent as ShowIcon } from "../../../theme/images/showTableIcon.svg";

// components
import Scrollbar from "../scrollbar/Scrollbar";

// sections
import ClientHeadTable from "../clientHeadTable/ClientHeadTable";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function RealStateTable({ requests, handleChangePage, page }) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  const TABLE_HEAD = [
    {
      id: "fullName",
      label: t("requestsAndSupport.full_name"),
      alignRight: isRtl && true,
    },
    {
      id: "phone",
      label: t("requestsAndSupport.phone"),
      alignRight: isRtl && true,
    },
    {
      id: "nationalId",
      label: t("requestsAndSupport.nationalId"),
      alignRight: isRtl && true,
    },

    {
      id: "count",
      label: t("requestsAndSupport.count"),
      alignRight: isRtl && true,
    },
  ];
  const [rowsPerPage] = useState(1);
  const navigate = useNavigate();
  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ClientHeadTable headLabel={TABLE_HEAD} />
            <TableBody>
              {requests.length === 0 && (
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2" noWrap>
                    No Data
                  </Typography>
                </TableCell>
              )}
              {requests?.content?.map((row) => {
                const { id, user, count } = row;

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          maxWidth: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {" "}
                        {user?.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          maxWidth: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {" "}
                        {user?.phone}
                      </Typography>
                    </TableCell>{" "}
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          maxWidth: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {" "}
                        {user?.nationalId}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {" "}
                        {count}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {requests?.totalElements === 0 && (
        <Box textAlign="center">
          <h5>No data found</h5>
        </Box>
      )}
      {requests?.totalPages > 0 && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            marginTop: "5rem",
          }}
        >
          <Pagination
            count={requests.totalPages}
            page={page + 1}
            onChange={handleChangePage}
            color="primary"
          />
          <Box sx={{ display: "block" }}>
            {t("General.showing")} {page * rowsPerPage + 1} {t("General.of")}{" "}
            {Math.min((page + 1) * rowsPerPage, requests.totalElements)}{" "}
            {t("General.to")} {requests.totalElements} {t("General.entries")}
          </Box>
        </Box>
      )}
    </>
  );
}
