import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

import Layout from '../../../components/Layout/Layout';
import ReusableFileUpload from '../../../components/uploadFile/UploadFile';
import { addOwner, updateOwner } from '../../../network/apis';
import PreviewFile from '../../../components/previewFile/PreviewFile';

export default function OwnersForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const location = useLocation();
  const editData = location.state;

  const handleUpload = (files, fieldName) => {
    const filesWithFieldName = files.map((file) => ({
      file,
      fieldName,
    }));

    setUploadedFiles((prevFiles) => [
      ...prevFiles.filter((file) => file.fieldName !== fieldName),
      ...filesWithFieldName,
    ]);
  };

  const validationSchema = yup.object({
    nameEn: yup
      .string()
      .required('This field is required please enter english text')
      .max(30, 'Maximum Characters (30)')
      .test('is-english', 'Please enter English text', (value) => {
        const englishRegex = /^[A-Za-z0-9_\s]*$/;
        return englishRegex.test(value);
      }),

    nameAr: yup
      .string()
      .max(30, 'Maximum Characters (30)')
      .required('This field is required please enter arabic text')
      .test('is-arabic', 'Please enter Arabic text\nيرجي ادخال نص عربي', (value) => {
        const arabicRegex = /[\u0600-\u06FF]/;
        const englishRegex = /[A-Za-z0-9]/;
        return arabicRegex.test(value) && !englishRegex.test(value);
      }),

    cr: yup
      .string()
      .required('this field is required')
      .test('is-numeric', 'This field is required Please enter Integer Number', (value) => {
        return !Number.isNaN(Number(value));
      }),

    address: yup.string().required('this field is required'),

    website: yup.string().url('Please enter a valid URL'),

    phone: yup
      .string()
      .required('Phone number is required')
      .matches(/^\d{9}$/, 'Phone number must be 9 digits'),

    licenceId: yup
      .number()
      .typeError('This field is required and should be a number')
      .positive('Please enter a positive number')
      .integer('Please enter an integer'),

    email: yup.string().email('Please enter a valid email address'),
  });

  return (
    <Layout>
      <h3> {t('add_owner.new_owner')} </h3>
      <Formik
        initialValues={{
          id: editData ? editData.editData.id : '',
          nameEn: editData ? editData.editData.nameEn : '',
          nameAr: editData ? editData.editData.nameAr : '',
          detailsEn: editData ? editData.editData.detailsEn : '',
          detailsAr: editData ? editData.editData.detailsAr : '',
          phone: editData ? editData.editData.phone : '',
          cr: editData ? editData.editData.cr : '',
          website: editData ? editData.editData.website : '',
          licenceId: editData ? editData.editData.licenceId : '',
          address: editData ? editData.editData.address : '',
          email: editData ? editData.editData.email : '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const formData = new FormData();

          Object.keys(values).forEach((key) => {
            formData.append(key, values[key]);
          });

          uploadedFiles.forEach((fileObj) => {
            if (fileObj.fieldName === 'ownerLogo') {
              formData.append('ownerLogo', fileObj.file.file);
            }
          });

          setLoading(true);
          const actionPromise = editData ? updateOwner(formData) : addOwner(formData);

          actionPromise
            .then((response) => {
              setLoading(false);
              if (response.data.STATUS === 'FAILED' || response.data.STATUS === '0') {
                toast.error(response.data.MESSAGE);
              } else {
                toast.success(response.data.MESSAGE);
                navigate(`/dashboard/manage-owners`);
              }
            })
            .catch((error) => {
              setLoading(false);
              const errorMessages = {
                500: 'Server Error',
                400: 'Bad Request',
                401: 'Unauthorized',
                403: 'Forbidden',
                404: 'Not Found',
              };
              if (axios.isAxiosError(error)) {
                const statusCode = error.response?.status;
                const errorMessage = errorMessages[statusCode] || 'An error occurred';
                toast.error(errorMessage);
              } else {
                toast.error('An error occurred');
              }
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('add_owner.ownerNameEn')}
                </Typography>
                <TextField
                  name="nameEn"
                  required
                  fullWidth
                  value={values.nameEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nameEn && Boolean(errors.nameEn)}
                  helperText={touched.nameEn && errors.nameEn}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('add_owner.ownerNameAr')}
                </Typography>
                <TextField
                  name="nameAr"
                  required
                  fullWidth
                  value={values.nameAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nameAr && Boolean(errors.nameAr)}
                  helperText={touched.nameAr && errors.nameAr}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('add_owner.owner_cr')}
                </Typography>
                <TextField
                  name="cr"
                  required
                  fullWidth
                  value={values.cr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.cr && Boolean(errors.cr)}
                  helperText={touched.cr && errors.cr}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('add_owner.owner_website')}
                </Typography>
                <TextField
                  name="website"
                  required
                  fullWidth
                  value={values.website}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.website && Boolean(errors.website)}
                  helperText={touched.website && errors.website}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('add_owner.owner_address')}
                </Typography>
                <TextField
                  name="address"
                  required
                  fullWidth
                  value={values.address}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.address && Boolean(errors.address)}
                  helperText={touched.address && errors.address}
                />
              </Grid>

              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('add_owner.owner_phone')}
                </Typography>
                <TextField
                  name="phone"
                  required
                  fullWidth
                  value={values.phone}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>

              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('add_owner.owner_emial')}
                </Typography>
                <TextField
                  name="email"
                  required
                  fullWidth
                  value={values.email}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('add_owner.owner_licence_id')}
                  </Typography>
                  <TextField
                    name="licenceId"
                    fullWidth
                    value={values.licenceId}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.licenceId && Boolean(errors.licenceId)}
                    helperText={touched.licenceId && errors.licenceId}
                  />
                </Grid>
              </>
              <>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('add_owner.owner_detailsEn')}
                  </Typography>
                  <TextField
                    name="detailsEn"
                    rows={5}
                    multiline
                    fullWidth
                    value={values.detailsEn}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.detailsEn && Boolean(errors.detailsEn)}
                    helperText={touched.detailsEn && errors.detailsEn}
                  />
                </Grid>
              </>

              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('add_owner.owner_detailsAr')}
                </Typography>
                <TextField
                  name="detailsAr"
                  fullWidth
                  rows={5}
                  multiline
                  value={values.detailsAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.detailsAr && Boolean(errors.detailsAr)}
                  helperText={touched.detailsAr && errors.detailsAr}
                />
              </Grid>

              <Grid item xs={2} sm={4} md={6}>
                <p style={{ margin: '0 0rem 0.5rem 0rem' }}> {t('add_owner.owner_logo')}</p>
                <ReusableFileUpload
                  label="Upload Logo"
                  onUpload={(files) => handleUpload(files, 'ownerLogo')}
                  setUploadedFiles={setUploadedFiles}
                  multiple={false}
                  uploadedFiles={uploadedFiles.filter((file) => file.fieldName === 'ownerLogo')}
                />

                <Box>
                  {editData?.editData?.logo && uploadedFiles?.length === 0 ? (
                    <PreviewFile
                      file={{
                        path: editData?.editData?.logo,
                        displayName: editData?.editData?.logo,
                        id: uploadedFiles?.ownerLogo?.length,
                      }}
                    />
                  ) : (
                    ''
                  )}
                </Box>
              </Grid>
              <Stack style={{ width: '100%' }} direction="row" justifyContent="end" alignItems="end" gap={2}>
                <Button
                  onClick={() => navigate('/dashboard/manage-owners')}
                  sx={{ mt: 4, border: '1px solid #111827' }}
                  size="large"
                  type="button"
                  variant=""
                >
                  {t('General.cancel')}
                </Button>
                <LoadingButton sx={{ mt: 4 }} loading={loading} size="large" type="submit" variant="contained">
                  {t('General.save')}
                </LoadingButton>
              </Stack>
            </Grid>
          </form>
        )}
      </Formik>
    </Layout>
  );
}
