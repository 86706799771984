import React from "react";
import { useTranslation } from "react-i18next";
import BasicTabs from "../components/tab/tab";

import ManageAdmins from "./ManageAdmins";
import ManageCandidates from "./ManageCandidates";

import Layout from "../components/Layout/Layout";

const ManageApplicationt = () => {
  const { t } = useTranslation();

  const tabs = [
    { label: t("employess.candidates_tab"), content: <ManageCandidates /> },

    {
      label: t("employess.employee_tab"),
      content: <ManageAdmins />,
    },
  ];

  return (
    <Layout>
      <h3> {t("employess.employees")} </h3>
      <BasicTabs tabs={tabs} />
    </Layout>
  );
};

export default ManageApplicationt;
