import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { Card, Stack, Button, Container, Typography, Skeleton, Grid } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { getPrivacyTerms, savePrivacyTerms } from '../network/apis';
import Editor from '../components/editor/Editor';

function PrivacyTerms() {
  const { t, i18n } = useTranslation();

  const [editorLoaded, setEditorLoaded] = useState(false);
  const [dataEn, setDataEn] = useState();
  const [dataAr, setDataAr] = useState();

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const getData = async () => {
    try {
      const response = await getPrivacyTerms();
      setDataEn(response?.data.EN);
      setDataAr(response?.data.AR);
    } catch (error) {
      console.log('error', error);
    }
  };

  const saveData = () => {
    const obj = { AR: dataAr, EN: dataEn };
    const response = savePrivacyTerms(obj)
      .then((res) => {
        if (res.data.STATUS === 'FAILED' || res.data.STATUS === '0') toast.error(res.data.MESSAGE);
        else toast.success(res.data.MESSAGE);
      })
      .catch((error) => {
        toast.error(error.response.data.MESSAGE);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <Helmet>
        <title> {t('termsCondition.managePrivacy')} </title>
      </Helmet>

      <div className="App">
        <Grid mb={2} rowSpacing={2} container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={2} sm={4} md={6}>
            <h4> {t('termsCondition.arabicContent')} </h4>

            <Editor
              name="description"
              onChange={(dataAr) => {
                setDataAr(dataAr);
              }}
              value={dataAr}
              editorLoaded={editorLoaded}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <h4> {t('termsCondition.englishContent')} </h4>

            <Editor
              name="description"
              onChange={(dataEn) => {
                setDataEn(dataEn);
              }}
              value={dataEn}
              editorLoaded={editorLoaded}
            />
          </Grid>
        </Grid>
        <Grid mb={2} rowSpacing={2} justifyContent={'end'} container spacing={{ xs: 2, md: 3 }}>
          <Button variant="contained" onClick={saveData} endIcon={<SaveIcon />}>
            {t('General.save')}
          </Button>
        </Grid>
      </div>
    </div>
  );
}
export default PrivacyTerms;
