import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import ModalData from '../reviewProducts/Modal';
import { updateFormData, nextStep, setFileInfo } from '../../../Store/features/Units/unitsSlice';
import { addUnitFinancialData } from '../../../network/apis';

export default function OwnerDataForm({
  handlePrev,
  t,
  viewMode,
  submitFormValues,
  setSubmitting,
  fromPage,
  open,
  onClose,
  onReject,
  handleOpenRejectDialog,
  rejectReason,
  setRejectReason,
  userId,
  managerId,
  isManager,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.unitform.formData);
  // Define formik using the useFormik hook
  const formik = useFormik({
    initialValues: {
      ...formData,
      PIECE_AREA: formData.area || '',
      PIECE_PRICE: formData.piecePrice || '',
    },
    validationSchema: yup.object({
      // Your validation schema
    }),
    onSubmit: (values) => {
      dispatch(updateFormData(values));
      submitFormValues(values);

      setSubmitting(false);
      // dispatch(handleNext());
    },
  });

  const [loading, setLoading] = useState(false);
  const [inputChanged, setInputChanged] = useState(false);

  const handleFieldChange = (event) => {
    const { name, value } = event.target;

    // formik.handleChange(event);
    if (name === 'PIECE_AREA' || name === 'PIECE_PRICE') {
      formik.setFieldValue(name, value);

      if (formik.values.PIECE_AREA && formik.values.PIECE_PRICE) {
        setInputChanged(true);
      }
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (inputChanged) {
      const timeoutId = setTimeout(() => {
        // Check if both PIECE_AREA and PIECE_PRICE have values
        if (formik.values.PIECE_AREA && formik.values.PIECE_PRICE) {
          setLoading(true);
          addUnitFinancialData({
            PIECE_PRICE: formik.values.PIECE_PRICE,
            PIECE_AREA: formik.values.PIECE_AREA,
          })
            .then((response) => {
              dispatch(updateFormData(response.data));
              formik.setValues({
                ...formik.values,
                piecePrice: formik.values.PIECE_PRICE,
                pieceArea: formik.values.PIECE_AREA,
                ...response.data,
              });
              setLoading(false);
            })
            .catch((error) => {
              console.error('Error adding financial data', error);
              setLoading(false);
            });
        }
        setInputChanged(false); // Reset the input change flag
      }, 500); // 500ms delay

      return () => clearTimeout(timeoutId);
    }
  }, [inputChanged, formik.values.PIECE_AREA, formik.values.PIECE_PRICE]);

  return (
    <Formik
      initialValues={formik.initialValues}
      validationSchema={formik.validationSchema}
      onSubmit={formik.handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setValues }) => (
        <form onSubmit={handleSubmit}>
          <Grid rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('Units.piecePrice')}
              </Typography>
              <TextField
                name="PIECE_PRICE"
                required
                disabled={viewMode}
                fullWidth
                value={formik.values.PIECE_PRICE}
                onChange={(event) => handleFieldChange(event, values, handleChange, setValues)}
                variant="outlined"
                onBlur={handleBlur}
                error={touched.PIECE_PRICE && Boolean(errors.PIECE_PRICE)}
                helperText={touched.PIECE_PRICE && errors.PIECE_PRICE}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('Units.unitArea')}
              </Typography>
              <TextField
                name="PIECE_AREA"
                required
                disabled={viewMode}
                fullWidth
                value={formik.values.PIECE_AREA}
                variant="outlined"
                onChange={(event) => handleFieldChange(event, values, handleChange, setValues)}
                onBlur={handleBlur}
                error={touched.PIECE_AREA && Boolean(errors.PIECE_AREA)}
                helperText={touched.PIECE_AREA && errors.PIECE_AREA}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('Units.realEstateTax')}
              </Typography>
              <TextField
                name="realEstateTax"
                disabled
                fullWidth
                onChange={handleChange}
                value={formik.values.realEstateTax}
                variant="outlined"
                onBlur={handleBlur}
                error={touched.realEstateTax && Boolean(errors.realEstateTax)}
                helperText={touched.realEstateTax && errors.realEstateTax}
              />
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('Units.commissionFees')}
              </Typography>
              <TextField
                name="commissionFees"
                disabled
                fullWidth
                value={formik.values.commissionFees}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.commissionFees && Boolean(errors.commissionFees)}
                helperText={touched.commissionFees && errors.commissionFees}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('Units.commissionFeesVat')}
              </Typography>
              <TextField
                name="commissionFeesVat"
                disabled
                fullWidth
                value={formik.values.commissionFeesVat}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.commissionFees && Boolean(errors.commissionFees)}
                helperText={touched.commissionFees && errors.commissionFees}
              />
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('Units.ownershipFees')}
              </Typography>
              <TextField
                name="ownershipFees"
                fullWidth
                disabled={viewMode}
                value={formik.values.ownershipFees}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={handleBlur}
                error={touched.ownershipFees && Boolean(errors.ownershipFees)}
                helperText={touched.ownershipFees && errors.ownershipFees}
              />
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('Units.meterPrice')}
              </Typography>
              <TextField
                name="meterPrice"
                disabled
                fullWidth
                value={formik.values.meterPrice}
                onChange={handleChange}
                variant="outlined"
                onBlur={handleBlur}
                error={touched.meterPrice && Boolean(errors.meterPrice)}
                helperText={touched.meterPrice && errors.meterPrice}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('Units.totalPrice')}
              </Typography>
              <TextField
                name="totalPrice"
                disabled
                fullWidth
                value={formik.values.totalPrice}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.totalPrice && Boolean(errors.totalPrice)}
                helperText={touched.totalPrice && errors.totalPrice}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('Units.totalPriceNoVat')}
              </Typography>
              <TextField
                name="totalPriceNoVat"
                disabled
                fullWidth
                value={formik.values.totalPriceNoVat}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.totalPriceNoVat && Boolean(errors.totalPriceNoVat)}
                helperText={touched.totalPriceNoVat && errors.totalPriceNoVat}
              />
            </Grid>
            {loading && <CircularProgress />}

            <Grid container spacing={2} sx={{ mt: 4 }} justifyContent="space-between">
              {!viewMode && fromPage === 'review-products' && (userId === managerId || isManager === '1') && (
                <>
                  <Grid item>
                    <Button sx={{ border: '1px solid #111827', ml: 3 }} size="large" type="button" onClick={handlePrev}>
                      {t('General.back')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2} justifyContent="flex-end">
                      <Grid item>
                        <Button
                          sx={{ border: '1px solid #111827' }}
                          size="large"
                          type="button"
                          onClick={() => handleOpenRejectDialog()}
                        >
                          {t('Units.reject')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button size="large" type="submit" variant="contained">
                          {t('Units.approve')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid container spacing={2} justifyContent="flex-end">
                {viewMode && (
                  <LoadingButton
                    style={{ padding: '0.8rem' }}
                    variant="contained"
                    onClick={() => {
                      if (fromPage !== 'review-products') navigate('/dashboard/units');
                      else navigate('/dashboard/review-products');
                    }}
                  >
                    {t('Units.ok')}
                  </LoadingButton>
                )}
              </Grid>
              {!viewMode && (fromPage !== 'review-products' || (userId !== managerId && isManager !== '1')) && (
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button sx={{ border: '1px solid #111827' }} size="large" type="button" onClick={handlePrev}>
                      {t('Units.back')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button size="large" type="submit" variant="contained">
                      {t('Units.submit')}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {open && (
            <ModalData
              open={open}
              onClose={onClose}
              onReject={onReject}
              rejectReason={rejectReason}
              setRejectReason={setRejectReason}
            />
          )}
        </form>
      )}
    </Formik>
  );
}
