import * as React from "react";
import * as Yup from "yup";

import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import {
  Grid,
  Typography,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Modal from "../Modal";
import UploadFileOnChange from "../UploadFileOnChange.jsx/UploadFileOnChange";
import { editOurOffer } from "../../network/apis";
import PreviewFile from "../previewFile/PreviewFile";

export default function EditOurOfferModal({
  open,
  handleClose,
  data,
  ourOffersListApi,
  isViewMode = false,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = React.useState({
    ourOfferImage: [],
  });
  // Add Item Function
  const addItem = (fieldName, items) => {
    if (!uploadedFiles[fieldName]) {
      uploadedFiles[fieldName] = [];
    }
    items.forEach((item) => {
      if (!uploadedFiles[fieldName].includes(item)) {
        uploadedFiles[fieldName].push(item);
      }
    });
  };

  // Delete Item Function
  const deleteItem = (fieldName, item) => {
    if (uploadedFiles[fieldName]) {
      uploadedFiles[fieldName] = uploadedFiles[fieldName].filter(
        (i) => i !== item
      );
    }
  };
  const addItemCallback = React.useCallback((fieldName, items) => {
    addItem(fieldName, items);
  }, []);

  const deleteItemCallback = React.useCallback((fieldName, item) => {
    deleteItem(fieldName, item);
  }, []);

  // Handle Add Our Service

  const handleAddOurService = async (values) => {
    setLoading(true);
    const res = await editOurOffer({
      id: data?.id,
      nameEn: values.nameEn,
      nameAr: values.nameAr,
      descriptionEn: values.descriptionEn,
      descriptionAr: values.descriptionAr,
      image: uploadedFiles?.ourOfferImage?.map((item) => item.id),
    });
    if (res?.data?.STATUS === "SUCCESS") {
      toast.success(res?.data?.MESSAGE);
      handleClose();
      ourOffersListApi(0, "");

      setLoading(false);
    } else {
      toast.error(res?.data?.MESSAGE);
      setLoading(false);
    }
  };
  // Regular expressions for validation
  const arabicRegex = /^[\u0600-\u06FF\s]+$/; // Matches Arabic characters
  const englishRegex = /^[A-Za-z\s]+$/; // Matches English characters
  const validationSchema = Yup.object().shape({
    nameEn: Yup.string()
      .required(t("General.required_field"))
      .matches(englishRegex, t("General.english_validation")),
    nameAr: Yup.string()
      .required(t("General.required_field"))
      .matches(arabicRegex, t("General.arabic_validation")),
    descriptionEn: Yup.string()
      .required(t("General.required_field"))
      .matches(englishRegex, t("General.english_validation")),
    descriptionAr: Yup.string()
      .required(t("General.required_field"))
      .matches(arabicRegex, t("General.arabic_validation")),
  });
  return (
    <Modal
      handleClose={handleClose}
      open={open}
      title={t("addOurOffers.addOurOffers")}
    >
      <Formik
        initialValues={{
          nameEn: data?.nameEn || "",
          nameAr: data?.nameAr || "",
          descriptionEn: data?.descriptionEn || "",
          descriptionAr: data?.descriptionAr || "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleAddOurService(values);
          handleClose();
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          setFieldValue,
        }) => {
          return (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Grid
                  padding={"20px"}
                  container
                  spacing={{ xs: 2, md: 2 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{
                    height: "100%",
                    padding: "20px",
                    borderBottom: "1px solid #E5E5E5",
                  }}
                >
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t("addOurOffers.nameEn")}
                    </Typography>
                    <TextField
                      name="nameEn"
                      disabled={isViewMode}
                      required
                      fullWidth
                      value={values.nameEn}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.nameEn && Boolean(errors.nameEn)}
                      helperText={touched.nameEn && errors.nameEn}
                    />
                  </Grid>{" "}
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t("addOurOffers.nameAr")}
                    </Typography>
                    <TextField
                      name="nameAr"
                      disabled={isViewMode}
                      required
                      fullWidth
                      value={values.nameAr}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.nameAr && Boolean(errors.nameAr)}
                      helperText={touched.nameAr && errors.nameAr}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={12}>
                    <Typography variant="body1" gutterBottom>
                      {t("addOurOffers.image")}
                    </Typography>
                    <UploadFileOnChange
                      setFieldValue={setFieldValue}
                      viewMode={isViewMode}
                      type="PHOTO"
                      category="PROJECT"
                      multiple={false}
                      label={t("addOurServices.image")}
                      fieldName="ourOfferImage"
                      maxSize={5000000}
                      acceptedTypes="image/*"
                      uploadedFilesUploaded={uploadedFiles}
                      addItem={addItemCallback}
                      deleteItem={deleteItemCallback}
                    />
                    {data.image && uploadedFiles?.ourOfferImage?.length === 0 && (
                      <PreviewFile
                        file={{
                          path: data.image,
                          displayName: data?.image,
                          id: data?.image?.length,
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={12}>
                    <Typography variant="body1" gutterBottom>
                      {t("addOurOffers.descriptionEn")}
                    </Typography>
                    <TextField
                      name="descriptionEn"
                      disabled={isViewMode}
                      required
                      multiline
                      rows={3}
                      fullWidth
                      value={values.descriptionEn}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.descriptionEn && Boolean(errors.descriptionEn)
                      }
                      helperText={touched.descriptionEn && errors.descriptionEn}
                    />
                  </Grid>{" "}
                  <Grid item xs={2} sm={4} md={12}>
                    <Typography variant="body1" gutterBottom>
                      {t("addOurOffers.descriptionAr")}
                    </Typography>
                    <TextField
                      name="descriptionAr"
                      disabled={isViewMode}
                      required
                      multiline
                      rows={3}
                      fullWidth
                      value={values.descriptionAr}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.descriptionAr && Boolean(errors.descriptionAr)
                      }
                      helperText={touched.descriptionAr && errors.descriptionAr}
                    />
                    <input type="text" hidden name="updateUi" />
                  </Grid>
                </Grid>
                {!isViewMode && (
                  <Stack
                    padding={2}
                    sx={{ width: "100%" }}
                    direction="row"
                    justifyContent="end"
                    alignItems="end"
                    gap={2}
                  >
                    <Button
                      sx={{ mt: 4, border: "1px solid #111827" }}
                      size="large"
                      type="button"
                      onClick={handleClose}
                    >
                      {" "}
                      {t("General.cancel")}
                    </Button>
                    <Button
                      sx={{ mt: 4, border: "1px solid #111827" }}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {" "}
                      {loading ? <CircularProgress /> : t("General.submit")}
                      {}
                    </Button>
                  </Stack>
                )}
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
}
