// src/components/StepOneForm.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';

import * as yup from 'yup';

import { omit } from 'lodash';

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
  InputLabel,
  SvgIcon,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';

import { useTranslation } from 'react-i18next';

import Select from '@mui/material/Select';
import { updateFormData } from '../../../Store/features/Lands/landsSlice';

import { getAskRequestsList, getListPlanSummaryDetails, getLookupsList } from '../../../network/apis';
import PreviewFile from '../../../components/previewFile/PreviewFile';
import UploadFileOnChange from '../../../components/UploadFileOnChange.jsx/UploadFileOnChange';
import DeleteFileModal from '../../../components/deleteFileModal/DeleteFileModal';

const CustomSwitch = ({ field, form, viewMode, t, isRtl }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  const handleChange = (event) => {
    setFieldValue(name, event.target.checked ? 'ACTIVE' : 'IN_ACTIVE');
  };

  return (
    <FormControlLabel
      disabled={viewMode}
      control={<Switch checked={value === 'ACTIVE'} onChange={handleChange} />}
      label={t('General.Status')}
    />
  );
};

const LandPieceDataFormTab = ({
  editData = {},
  isEditMode,
  handleNext,
  handleDelete,
  viewMode,
  addItem,
  deleteItem,
  uploadedFilesUploaded,
  setRefetch,
  refetch,
}) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const formData = useSelector((state) => state?.land?.formData);
  const [changeVirtualTour, setChangeVirtualTour] = useState(editData?.virtualType !== 'LINK');
  const [deleteFileData, setDeleteFileData] = useState({ open: false, type: 'land', id: '', name: '' });
  const [loading, setLoading] = useState(false);
  // Categories Types
  const [categoryOptions, setCategoryOptions] = useState([]);
  useEffect(() => {
    const getTypes = () => {
      getAskRequestsList(0, '', 'PLAN').then((res) => {
        setCategoryOptions(
          res?.data?.content?.map((item) => {
            return {
              value: item?.id,
              label: item?.name,
            };
          })
        );
      });
    };
    getTypes();
  }, []);

  // faciilities
  const [facadeOptions, setFacade] = useState([]);
  const [facadedropdownOpened, setFacadeDropdownOpened] = useState(false);
  const getFacadeRequest = () => {
    setLoading(true);
    getLookupsList('FACADE_TYPE')
      .then((res) => {
        setFacade(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    if (!facadedropdownOpened) {
      getFacadeRequest();
      setFacadeDropdownOpened(true);
    }
  }, [editData, facadedropdownOpened]);

  // project options
  const [projectNameOptions, setProjectNameOptions] = useState([]);
  const getLandListOptions = () => {
    setLoading(true);
    getListPlanSummaryDetails()
      .then((res) => {
        setProjectNameOptions(res.data);

        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    getLandListOptions();
  }, []);
  const CustomArrowIcon = ({ isRtl }) => (
    <SvgIcon style={{ transform: 'rotate(-360deg)', color: '#637381' }}>
      <path d="M7 10l5 5 5-5z" />
    </SvgIcon>
  );

  if ((isEditMode || viewMode) && editData?.length === 0) {
    return null;
  }
  const uniqueKey = Date.now();
  const ProjectDataValidationSchema = yup.object().shape({
    // planDetails: yup.string().required('Required field'),

    nameEn: yup
      .string()
      .required(t('General.required_field'))
      .test(
        'is-english',
        (t('General.english_validation'),
        (value) => {
          // Regular expression to match English characters
          const englishRegex = /^[A-Za-z0-9_-\s]*$/;
          return englishRegex.test(value);
        })
      ),
    nameAr: yup
      .string()
      .required(t('General.required_field'))
      .test(
        'is-arabic',
        (t('General.arabic_validation'),
        (value) => {
          // Regular expression to match Arabic characters
          const arabicRegex = /[\u0600-\u06FF\u0660-\u0669]/;

          // Regular expression to match English characters
          const englishRegex = /[A-Za]/;
          // Check if the value contains both Arabic and English characters
          return arabicRegex.test(value) && !englishRegex.test(value);
        })
      ),

    // Other fields validation

    type: yup.string().required(t('General.required_field')),
    area: yup
      .string()
      .required(t('General.required_field'))
      .test('is-valid-number', t('General.number_validation'), (value) => {
        // Allow numbers with commas and dots
        const numberRegex = /^[0-9.,]+$/;
        if (!numberRegex.test(value)) {
          return false;
        }
        // Ensure it is a valid number when commas are replaced with dots
        const normalizedValue = value.replace(/,/g, '.');
        return !Number.isNaN(parseFloat(normalizedValue)) && parseFloat(normalizedValue) > 0;
      }),

    purpose: yup
      .string()
      .required(t('General.required_field'))
      .oneOf(['RESIDENTIAL', 'COMMERCIAL'], 'Invalid purpose selected'),
  });
  return (
    <Formik
      initialValues={
        isEditMode || viewMode
          ? {
              ...editData,
              planDetails: formData.planDetails || editData.plan,
            }
          : {
              ...formData,
              states: 'ACTIVE',
            }
      }
      validationSchema={ProjectDataValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(updateFormData({ ...values, virtualType: changeVirtualTour }));
        dispatch(handleNext());
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldError }) => {
        return (
          <form style={{ height: '100%' }} onSubmit={handleSubmit}>
            <Grid
              style={{ marginTop: '10px' }}
              rowSpacing={2}
              container
              spacing={{ xs: 2, md: 3 }}
              sx={{ direction: isRtl ? 'rtl' : 'ltr' }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('LANDS.planName')} <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  name="planDetails"
                  disabled={viewMode}
                  value={values?.planDetails}
                  onChange={(event, newValue) => {
                    const updatedValue = omit(newValue, ['nameAr', 'label', 'unitType']);
                    dispatch(
                      updateFormData({
                        planDetails: {
                          ...updatedValue,
                          planId: newValue.id,
                          optionNameEn: newValue.nameEn,
                          optionNameAr: newValue.nameAr,
                        },
                      })
                    );
                    // Handle the change for Formik
                    handleChange({
                      target: {
                        name: 'planDetails',
                        value: newValue,
                      },
                    });
                  }}
                  options={
                    projectNameOptions?.map((option) => ({
                      ...option,
                      id: option.id || '',
                      nameEn: option.nameEn,
                      nameAr: option.nameAr,
                    })) || []
                  }
                  error={touched.planDetails || Boolean(errors.planDetails)}
                  helperText={touched.planDetails || errors.planDetails}
                  getOptionLabel={(option) => {
                    return isRtl ? option.nameAr : option.nameEn || option.nameEn;
                  }}
                  sx={{ width: '100%' }}
                  renderInput={(params) => <TextField {...params} />}
                  IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                />
                {errors.planDetails && <span style={{ color: 'red' }}>This field is required</span>}
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('LANDS.landNameEn')}
                </Typography>
                <TextField
                  name="nameEn"
                  required
                  disabled={viewMode}
                  fullWidth
                  value={values.nameEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nameEn && Boolean(errors.nameEn)}
                  helperText={
                    touched.nameEn &&
                    errors.nameEn && (
                      <span dangerouslySetInnerHTML={{ __html: errors.nameEn.replace('\n', '<br />') }} />
                    )
                  }
                />
              </Grid>
              <>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('LANDS.landNameAR')}
                  </Typography>
                  <TextField
                    name="nameAr"
                    required
                    fullWidth
                    disabled={viewMode}
                    value={values.nameAr}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.nameAr && Boolean(errors.nameAr)}
                    helperText={
                      touched.nameAr &&
                      errors.nameAr && (
                        <span dangerouslySetInnerHTML={{ __html: errors.nameAr.replace('\n', '<br />') }} />
                      )
                    }
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Stack>
                    <InputLabel id="type"> {t('LANDS.landType')}</InputLabel>
                    <Field
                      as={Select}
                      sx={{ mt: 1, direction: isRtl ? 'rtl' : 'ltr' }}
                      labelId="type"
                      disabled={viewMode}
                      IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                      id="type"
                      value={values.type}
                      onChange={(event) => {
                        handleChange({
                          target: {
                            name: 'type',
                            value: event.target.value,
                          },
                        });
                      }}
                      error={touched.type && Boolean(errors.type)}
                      helperText={touched.type && errors.type}
                    >
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="RESIDENTIAL">
                        {t('LANDS.resedential')}
                      </MenuItem>
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="COMMERICAL">
                        {' '}
                        {t('LANDS.COMMERICAL')}
                      </MenuItem>
                    </Field>
                  </Stack>
                </Grid>
                <Grid
                  sx={{ marginLeft: '10px', marginTop: '3px', marginBottom: '3px' }}
                  container
                  direction="row"
                  spacing={2}
                >
                  <Grid item xs={2} sm={4} md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t('Units.totalArea')}
                    </Typography>
                    <TextField
                      name="area"
                      disabled={viewMode}
                      required
                      fullWidth
                      value={values.area}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.area && Boolean(errors.area)}
                      helperText={touched.area && errors.area}
                    />
                  </Grid>

                  <Grid item xs={2} sm={6} md={6}>
                    <Stack>
                      <InputLabel id="facade">{t('Units.facade')}</InputLabel>

                      <Select
                        sx={{ mt: 1 }}
                        labelId="facade"
                        disabled={viewMode}
                        id="facade"
                        IconComponent={() => <CustomArrowIcon />}
                        value={values.facade}
                        onOpen={() => {
                          if (!facadedropdownOpened) {
                            getFacadeRequest();
                            setFacadeDropdownOpened(true);
                          }
                        }}
                        onChange={(event) => {
                          handleChange({
                            target: {
                              name: 'facade',
                              value: event.target.value,
                            },
                          });
                        }}
                      >
                        {loading ? (
                          <MenuItem>
                            <CircularProgress size={24} />
                          </MenuItem>
                        ) : (
                          facadeOptions?.FACADE_TYPE?.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('Units.northernBorderEn')}
                  </Typography>
                  <TextField
                    name="northernBoardEn"
                    fullWidth
                    disabled={viewMode}
                    value={values.northernBoardEn}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.northernBoardEn && Boolean(errors.northernBoardEn)}
                    helperText={touched.northernBoardEn && errors.northernBoardEn}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('Units.northernBorderAr')}
                  </Typography>
                  <TextField
                    name="northernBoardAr"
                    disabled={viewMode}
                    fullWidth
                    value={values.northernBoardAr}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.northernBoardAr && Boolean(errors.northernBoardAr)}
                    helperText={touched.northernBoardAr && errors.northernBoardAr}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('Units.southernBorderEn')}
                  </Typography>
                  <TextField
                    name="southernBoardEn"
                    disabled={viewMode}
                    fullWidth
                    value={values.southernBoardEn}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.southernBoardEn && Boolean(errors.southernBoardEn)}
                    helperText={touched.southernBoardEn && errors.southernBoardEn}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('Units.southernBorderAr')}
                  </Typography>
                  <TextField
                    name="southernBoardAr"
                    disabled={viewMode}
                    fullWidth
                    value={values.southernBoardAr}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.southernBoardAr && Boolean(errors.southernBoardAr)}
                    helperText={touched.southernBoardAr && errors.southernBoardAr}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('Units.westernBorderEn')}
                  </Typography>
                  <TextField
                    name="westernBoardEn"
                    disabled={viewMode}
                    fullWidth
                    value={values.westernBoardEn}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.westernBoardEn && Boolean(errors.westernBoardEn)}
                    helperText={touched.westernBoardEn && errors.westernBoardEn}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('Units.westernBorderAr')}
                  </Typography>
                  <TextField
                    name="westernBoardAr"
                    disabled={viewMode}
                    fullWidth
                    value={values.westernBoardAr}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.westernBoardAr && Boolean(errors.westernBoardAr)}
                    helperText={touched.westernBoardAr && errors.westernBoardAr}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('Units.easternBorderEn')}
                  </Typography>
                  <TextField
                    name="easternBoardEn"
                    disabled={viewMode}
                    fullWidth
                    value={values.easternBoardEn}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.easternBoardEn && Boolean(errors.easternBoardEn)}
                    helperText={touched.easternBoardEn && errors.easternBoardEn}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('Units.easternBorderAr')}
                  </Typography>
                  <TextField
                    name="easternBoardAr"
                    disabled={viewMode}
                    fullWidth
                    value={values.easternBoardAr}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.easternBoardAr && Boolean(errors.easternBoardAr)}
                    helperText={touched.easternBoardAr && errors.easternBoardAr}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Stack>
                    <InputLabel id="reservationStatus"> {t('General.reservationStatus')} </InputLabel>
                    <Field
                      as={Select}
                      sx={{ mt: 1 }}
                      labelId="reservationStatus"
                      IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                      disabled={viewMode}
                      id="reservationStatus"
                      value={values.reservationStatus}
                      onChange={(event) => {
                        handleChange({
                          target: {
                            name: 'reservationStatus',
                            value: event.target.value,
                          },
                        });
                        dispatch(updateFormData({ ...values, reservationStatus: event.target.value }));
                      }}
                      error={touched.reservationStatus && Boolean(errors.reservationStatus)}
                      helperText={touched.reservationStatus && errors.reservationStatus}
                    >
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="FREE">
                        {t('Units.free')}{' '}
                      </MenuItem>
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="RESERVED">
                        {t('Units.reserved')}
                      </MenuItem>
                    </Field>
                  </Stack>
                </Grid>{' '}
                <Grid item xs={2} sm={4} md={6}>
                  <Stack>
                    <InputLabel id="pieceStatus"> {t('Units.unitStatus')}</InputLabel>
                    <Field
                      as={Select}
                      sx={{ mt: 1 }}
                      labelId="pieceStatus"
                      disabled={viewMode}
                      IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                      id="pieceStatus"
                      value={values.pieceStatus}
                      onChange={(event) => {
                        handleChange({
                          target: {
                            name: 'pieceStatus',
                            value: event.target.value,
                          },
                        });
                        dispatch(updateFormData({ ...values, pieceStatus: event.target.value }));
                      }}
                      error={touched.pieceStatus && Boolean(errors.pieceStatus)}
                      helperText={touched.pieceStatus && errors.pieceStatus}
                    >
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} disabled={viewMode} value="TRUE">
                        {t('General.Active')}
                      </MenuItem>
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} disabled={viewMode} value="FALSE">
                        {t('General.InActive')}
                      </MenuItem>
                    </Field>
                  </Stack>
                </Grid>{' '}
                <Grid item xs={2} sm={4} md={6}>
                  <Stack>
                    <InputLabel id="pieceDataStatus">{t('Units.unitDataStatus')} </InputLabel>
                    <Field
                      as={Select}
                      IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                      sx={{ mt: 1 }}
                      labelId="pieceDataStatus"
                      id="pieceDataStatus"
                      disabled={viewMode}
                      value={values.pieceDataStatus}
                      onChange={(event) => {
                        handleChange({
                          target: {
                            name: 'pieceDataStatus',
                            value: event.target.value,
                          },
                        });
                        dispatch(updateFormData({ ...values, pieceDataStatus: event.target.value }));
                      }}
                      error={touched.pieceDataStatus && Boolean(errors.pieceDataStatus)}
                      helperText={touched.pieceDataStatus && errors.pieceDataStatus}
                    >
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="incomplete">
                        {t('Units.incomplete')}
                      </MenuItem>
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="complete">
                        {t('Units.complete')}
                      </MenuItem>
                    </Field>
                  </Stack>
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('Units.whatsappNumber')}
                  </Typography>
                  <TextField
                    name="whatsappNumber"
                    required
                    fullWidth
                    disabled={viewMode}
                    value={values.whatsappNumber}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.whatsappNumber && Boolean(errors.whatsappNumber)}
                    helperText={touched.whatsappNumber && errors.whatsappNumber}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('Units.email')}
                  </Typography>
                  <TextField
                    name="email"
                    required
                    fullWidth
                    disabled={viewMode}
                    value={values.email}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('Units.mobileNumber')}
                  </Typography>
                  <TextField
                    disabled={viewMode}
                    name="mobile"
                    required
                    fullWidth
                    value={values.mobile || formData?.mobile}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.mobile && Boolean(errors.mobile)}
                    helperText={touched.mobile && errors.mobile}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={12}>
                  <Stack>
                    <InputLabel id="type">{t('Units.purpose')}</InputLabel>
                    <Field
                      as={Select}
                      sx={{ mt: 1 }}
                      labelId="purpose"
                      disabled={viewMode}
                      id="purpose"
                      IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                      value={values.purpose}
                      onChange={(event) => {
                        handleChange({
                          target: {
                            name: 'purpose',
                            value: event.target.value,
                          },
                        });
                      }}
                      error={touched.purpose || Boolean(errors.purpose)}
                      helperText={touched.purpose && errors.purpose}
                      onBlur={() => {
                        if (!values.purpose) {
                          setFieldError('purpose', t('General.required_field'));
                        }
                      }}
                    >
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="RESIDENTIAL">
                        {t('Units.residential')}
                      </MenuItem>
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="COMMERCIAL">
                        {t('Units.commercial')}
                      </MenuItem>
                    </Field>
                  </Stack>
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('Units.blockNumber')}
                  </Typography>
                  <TextField
                    name="blockNumber"
                    required
                    disabled={viewMode}
                    fullWidth
                    value={values.blockNumber}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.blockNumber && Boolean(errors.blockNumber)}
                    helperText={touched.blockNumber && errors.blockNumber}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('LANDS.landAttachments')}
                  </Typography>
                  <UploadFileOnChange
                    uploadedFilesUploaded={uploadedFilesUploaded}
                    viewMode={viewMode}
                    multiple
                    type="ATTACHMENT"
                    category="LAND"
                    label={t('LANDS.landAttachments')}
                    fieldName="attachments"
                    maxSize={5000000}
                    acceptedTypes="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    addItem={addItem}
                    deleteItem={deleteItem}
                  />
                  <Box>
                    {editData?.attachments &&
                      editData?.attachments?.map((file, index) => (
                        <PreviewFile
                          key={index}
                          ViewMode={viewMode}
                          file={file}
                          onDelete={() =>
                            setDeleteFileData({
                              open: true,
                              id: file.id,
                              name: file.name,
                              type: 'attachment',
                            })
                          }
                        />
                      ))}
                  </Box>
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('LANDS.landPictures')}
                  </Typography>
                  <UploadFileOnChange
                    viewMode={viewMode}
                    uploadedFilesUploaded={uploadedFilesUploaded}
                    multiple
                    type="PICTURE"
                    category="LAND"
                    label={t('LANDS.landPictures')}
                    fieldName="landImages"
                    maxSize={5000000}
                    acceptedTypes="image/*"
                    addItem={addItem}
                    deleteItem={deleteItem}
                  />
                  <Box>
                    {editData?.pictures &&
                      editData?.pictures?.map((file, index) => (
                        <PreviewFile
                          key={index}
                          ViewMode={viewMode}
                          file={file}
                          onDelete={() =>
                            setDeleteFileData({
                              open: true,
                              id: file.id,
                              name: file.name,
                              type: 'picture',
                            })
                          }
                        />
                      ))}
                  </Box>
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('LANDS.landSketch')}
                  </Typography>
                  <UploadFileOnChange
                    viewMode={viewMode}
                    uploadedFilesUploaded={uploadedFilesUploaded}
                    multiple
                    type="SKETCH"
                    category="LAND"
                    label={t('LANDS.landSketch')}
                    fieldName="landSketch"
                    acceptedTypes="application/pdf"
                    maxSize={5000000}
                    addItem={addItem}
                    deleteItem={deleteItem}
                  />
                  <Box>
                    {editData?.landSketch &&
                      editData?.landSketch?.map((file, index) => (
                        <PreviewFile
                          key={index}
                          ViewMode={viewMode}
                          file={file}
                          onDelete={() =>
                            setDeleteFileData({
                              open: true,
                              id: file.id,
                              name: file.name,
                              type: 'sketch',
                            })
                          }
                        />
                      ))}
                  </Box>
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Typography variant="body1" gutterBottom>
                    {t('General.primaryPhoto')}
                  </Typography>
                  <UploadFileOnChange
                    uniqueKey={uniqueKey + 4}
                    uploadedFilesUploaded={uploadedFilesUploaded}
                    setFieldValue={setFieldValue}
                    viewMode={viewMode}
                    type="PHOTO"
                    category="LAND"
                    label={t('General.primaryPhoto')}
                    fieldName="primaryPhoto"
                    maxSize={5000000}
                    acceptedTypes="image/*"
                    addItem={addItem}
                    deleteItem={deleteItem}
                  />
                  <Box>
                    {editData?.primaryPhoto && uploadedFilesUploaded?.primaryPhoto?.length === 0 && (
                      <PreviewFile
                        file={{
                          path: editData?.primaryPhoto,
                          displayName: editData?.primaryPhoto,
                          id: uploadedFilesUploaded?.primaryPhoto?.length,
                        }}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Stack direction={'row'} alignItems={'center'}>
                    <Switch
                      checked={changeVirtualTour}
                      name="virtualType"
                      onChange={() => {
                        handleChange('virtualType');
                        setChangeVirtualTour(!changeVirtualTour);
                      }}
                    />
                    <Typography variant="body1">
                      {changeVirtualTour ? t('Units.virtualTour') : t('General.virtualTourURL')}
                    </Typography>
                  </Stack>
                  {changeVirtualTour ? (
                    <>
                      <UploadFileOnChange
                        uniqueKey={uniqueKey + 5}
                        uploadedFilesUploaded={uploadedFilesUploaded}
                        viewMode={viewMode}
                        multiple
                        type="VIRTUAL_TOUR"
                        category="LAND"
                        label={t('General.virtualTour')}
                        fieldName="virtualTour"
                        maxSize={5000000}
                        acceptedTypes="image/*"
                        addItem={addItem}
                        deleteItem={deleteItem}
                      />
                      {editData?.virtualTour && uploadedFilesUploaded?.virtualTour?.length === 0 && (
                        <PreviewFile
                          file={{
                            path: editData.virtualTour,
                            displayName: editData?.virtualTourName,
                            id: uploadedFilesUploaded?.primaryPhoto?.length,
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <TextField
                      sx={{ marginBottom: 2 }}
                      name="virtualLink"
                      placeholder={t('General.virtualTourURL')}
                      fullWidth
                      value={values.virtualLink}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  )}
                </Grid>
                <Grid style={{ marginTop: '5px' }} item xs={2} sm={12} md={12}>
                  <FormGroup>
                    <Field t={t} isViewMode={viewMode} name="states" component={CustomSwitch} />
                  </FormGroup>
                </Grid>
              </>
              <DeleteFileModal
                open={deleteFileData.open}
                setOpen={deleteFileData}
                handelClose={() => setDeleteFileData({ ...deleteFileData, open: false })}
                onDelete={() => {
                  handleDelete(deleteFileData?.id, deleteFileData?.type, deleteFileData?.name);
                  setDeleteFileData({ ...deleteFileData, open: false });
                  setRefetch(!refetch);
                }}
              />
            </Grid>
            <input type="hidden" name="updateUi" onChange={() => setFieldValue('updateUi', true)} />

            <Stack sx={{ width: '100%' }} direction="row" justifyContent="end" alignItems="end" spacing={2}>
              <Button
                sx={{ mt: 4, border: '1px solid #111827' }}
                size="large"
                // onClick={updateFormData(values)}
                // disabled={currentStep === totalSteps - 1}
                type="submit"
                variant="contained"
              >
                {t('General.next')}
              </Button>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default LandPieceDataFormTab;
