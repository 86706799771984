import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
// @mui
import { Stack, Typography, Skeleton, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout/Layout";
import SearchComponet from "../components/search/Search";
import { getRealStateRequestsList } from "../network/apis";
import RealStateTable from "../sections/@dashboard/realStateRequests/DataTable";

export default function ManageRealStateRequests() {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [realStateRequests, setRealStateRequests] = useState([]);

  const ourOffersListApi = (page, search) => {
    setLoading(true);
    getRealStateRequestsList(page, search)
      .then((res) => {
        setRealStateRequests(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    ourOffersListApi(page, search);
  }, [page, search]);

  const handleChangePage = (__, newPage) => {
    setPage(newPage - 1);
  };

  return (
    <>
      <Helmet>
        <title> {t("realStateRequests.realStateRequests")} </title>
      </Helmet>

      <Layout>
        <Grid
          display="flex"
          alignItems={"center"}
          marginBottom={1}
          justifyContent="space-between"
          md={6}
          xs={12}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "32px",
              color: "#111827",
            }}
          >
            {t("realStateRequests.realStateRequests")}
          </Typography>
        </Grid>
        <Grid
          mb={2}
          rowSpacing={1}
          container
          spacing={{ xs: 2, md: 1 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={2} sm={4} md={4}>
            <SearchComponet onSearch={setSearch} style={{ width: "100%" }} />
          </Grid>
        </Grid>
        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <RealStateTable
              handleChangePage={handleChangePage}
              page={page}
              requests={realStateRequests}
            />
          </>
        )}
      </Layout>
    </>
  );
}
