// ReusableSearch.js
import React, { useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import { Search as SearchIcon } from '@mui/icons-material';
import { ReactComponent as SearchIcon } from '../../theme/images/searchIcon.svg';

const SearchComponet = ({ onSearch, style, label }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchQuery(newValue);
    onSearch(newValue);
  };

  return (
    <div>
      <TextField
        style={style}
        // label={label || 'Search'}
        sx={{
          borderRadius: '10px',
          direction: isRtl ? 'rtl' : 'ltr',
          width: '100%',
          '& .MuiInputBase-root': {
            borderRadius: '10px',
          },
        }}
        variant="outlined"
        value={searchQuery}
        onChange={handleInputChange}
        size="large"
        placeholder={label || t('General.Search')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SearchComponet;
